import React from "react";
import { useParams } from "react-router-dom";
import { HorasPorClienteYFechaDto } from "../dto/horas-por-cliente-y-fecha.dto";
import { useToast } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Customer } from "../api";
import { ApiClient } from "common";
import { ReportCustomerListItem } from "../components/Reports/_partials/ReportCustomerListItem";
import constants from "../constants";

interface IReportHoursByCustomerDateResultProps {}

export const ReportHoursByCustomerDateResult: React.FC<
  IReportHoursByCustomerDateResultProps
> = () => {
  const params = useParams();
  const data = new URLSearchParams(params["*"]);

  const values: HorasPorClienteYFechaDto = {
    customers: data.getAll("customers").map((c) => Number(c)),
    endDate: data.get("endDate") || "",
    projectTagsIgnore: data.getAll("projectTagsIgnore").map((c) => Number(c)),
    projectTagsInclude: data.getAll("projectTagsInclude").map((c) => Number(c)),
    startDate: data.get("startDate") || "",
  };

  const toast = useToast();

  const { data: searchData, isLoading } = useQuery<Customer[]>(
    ["informes", values],
    () =>
      ApiClient.post<Customer[]>(
        "informes/horas-por-cliente-y-fecha",
        JSON.stringify(values)
      ),
    {
      onError: () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  return (
    <>
      {isLoading ? (
        <p>cargando</p>
      ) : searchData?.length === 0 ? (
        <p>No hay resultados.</p>
      ) : (
        <>
          {searchData?.map((c) => {
            return <ReportCustomerListItem item={c} key={`customer-${c.id}`} />;
          })}
        </>
      )}
    </>
  );
};
