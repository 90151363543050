import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Customer } from "../../api";
import { ApiClient } from "common";
import { Box, useToast } from "@chakra-ui/react";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import Select from "react-select";
import constants from "../../constants";

interface ICustomerSelectableDropDownProps {
  onChange: (item: selectItem | null) => void;
  value: number;
}

interface selectItem {
  value: number;
  label: string;
}

/**
 * @todo implementar Select Value
 * @constructor
 */
export const CustomerSelectableDropDown: React.FC<
  ICustomerSelectableDropDownProps
> = ({ onChange, value }) => {
  const toast = useToast();

  const request = RequestQueryBuilder.create();
  request.sortBy({
    field: "name",
    order: "ASC",
  });

  const [customers, setCustomers] = useState<selectItem[]>();

  const { data, isLoading } = useQuery<Customer[]>(
    ["clients", request],
    () => ApiClient.get<Customer[]>("customers", request),
    {
      onError: () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos de los clientes. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  useEffect(() => {
    if (!data) return;
    setCustomers(
      data?.map((c) => ({
        value: c.id,
        label: c.name,
      })) || []
    );
  }, [data]);

  if (isLoading) return <span>Cargando...</span>;

  return (
    <Box maxWidth="300px">
      <Select
        onChange={(a) => {
          console.log(a);
          onChange(a as unknown as selectItem);
        }}
        options={customers}
        value={customers?.filter((item) => item.value === value)}
        isClearable={true}
        placeholder="Seleccione..."
      />
    </Box>
  );
};
