import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import constants from "../../constants";
import useAuth from "../../context/auth/auth";
import { useQueryClient } from "@tanstack/react-query";
import { UploadTypeEnum } from "../../dto/types/UploadType.type";
import { Upload } from "../../api";
import { AiOutlineCloudUpload } from "react-icons/ai";

const uploadFile = (
  file: File,
  access_token: string,
  parentId: number,
  type: UploadTypeEnum,
  opPercentageUpdate: (v: number) => void
): Promise<Upload | File> =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", file);

    const data: any = {
      type,
      parentId,
    };

    Object.keys(data).forEach((key: any) => formData.append(key, data[key]));

    let request = new XMLHttpRequest();
    request.open("POST", constants.apiUrl + "uploads");
    request.setRequestHeader("Authorization", "Bearer " + access_token);

    request.upload.addEventListener("progress", (e) => {
      const percent_completed = (e.loaded / e.total) * 100;
      opPercentageUpdate(percent_completed);
    });

    request.addEventListener("load", () => {
      if (request.status === 201) resolve(JSON.parse(request.response));
      else reject(file);
    });

    request.send(formData);
  });

interface IMultipleUploaderProps {
  parentId: number;
  type: UploadTypeEnum;
}

export const MultipleUploader: React.FC<IMultipleUploaderProps> = ({
  parentId,
  type,
}) => {
  const [files, setFiles] = useState<File[]>();
  const [percentage, setPercentage] = useState<number>();
  const [currentFile, setCurrentFile] = useState<File>();
  const queryClient = useQueryClient();

  const { access_token } = useAuth();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: true,
    onDrop: async (droppedFiles: File[]) => {
      setFiles(droppedFiles);
      for await (const file of droppedFiles) {
        setCurrentFile(file);
        try {
          const res: Upload = (await uploadFile(
            file,
            access_token,
            parentId,
            type,
            (p) => setPercentage(p)
          )) as unknown as Upload;

          setFiles((files) => files?.filter((f) => f !== file));
          queryClient.setQueryData<Upload[]>(
            ["files", parentId, type],
            (oldData) => {
              if (!oldData || oldData.length === 0) return [res];
              return [...oldData, res];
            }
          );
        } catch (e) {
          console.error(e);
        }
      }
    },
  });

  const contentColor = useColorModeValue("gray.500", "gray.600");

  const baseStyle = {
    bgColor: useColorModeValue("white", "gray.600"),
    border: `2px dashed`,
    borderColor: contentColor,
    transition: "all ease-in .2s",
  };

  const dragActive = {
    borderColor: useColorModeValue("blue.400", "gray.600"),
  };

  const style = isDragActive ? { ...baseStyle, ...dragActive } : baseStyle;

  return (
    <div>
      <Flex
        position="relative"
        height="80px"
        alignItems="center"
        alignContent="center"
        justifyContent="center"
        borderRadius={8}
        _hover={{
          cursor: "copy",
        }}
        {...style}
        {...getRootProps()}
      >
        <HStack justifyContent="center">
          <Icon color={contentColor} w={6} h={6} as={AiOutlineCloudUpload} />
          <Text textColor={contentColor}>Subir ficheros</Text>
        </HStack>
        <input {...getInputProps()} />
      </Flex>

      {files && files.length > 0 && (
        <Box>
          <Heading>Subiendo ficheros</Heading>
          {files.map((f, i) => (
            <p key={i}>
              {f.name}
              {f === currentFile && percentage && (
                <span>
                  {percentage < 100 ? percentage.toFixed(2) : "Procesando"}
                </span>
              )}
            </p>
          ))}
        </Box>
      )}
    </div>
  );
};
