import React from "react";
import { DefaultOption } from "./CustomSelect";
import { FieldAttributes, useField } from "formik";
import { FormHelperText, FormLabel, Text } from "@chakra-ui/react";
import Select from "react-select";

interface ICustomMultiSelectProps extends FieldAttributes<any> {
  options: DefaultOption[];
  helperText?: string;
  label: string;
}

interface selectItem {
  value: string | number;
  label: string;
}

export const CustomMultiSelect: React.FC<ICustomMultiSelectProps> = ({
  label,
  options,
  helperText,
  ...props
}) => {
  const restProps: FieldAttributes<any> = { ...props };
  const [, meta, helpers] = useField(restProps);

  const isInvalid = meta.touched && meta.error !== undefined;

  return (
    <div>
      <FormLabel aria-required={true} htmlFor={restProps.name || restProps.id}>
        {label}
      </FormLabel>
      <Select
        onChange={(items: selectItem[]) =>
          helpers.setValue(items.map((item) => item.value))
        }
        options={options}
        isMulti={true}
        defaultValue={options.filter((option) =>
          meta.value.includes(option.value)
        )}
        components={{
          MultiValueRemove: () => <></>,
          NoOptionsMessage: () => (
            <Text textAlign="center" textColor="gray.600">
              No hay resultados
            </Text>
          ),
        }}
        {...restProps}
      />

      {!isInvalid && helperText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </div>
  );
};
