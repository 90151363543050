import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
} from "@chakra-ui/react";
import { Task } from "../../api";
import { formatSeconds } from "common/dist/util";

interface ITaskAlertDialogDeleteProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: (item: Task) => void;
  item: Task;
}

export const TaskAlertDialogDelete: React.FC<ITaskAlertDialogDeleteProps> = ({
  isOpen,
  onClose,
  onDelete,
  item,
}) => {
  const cancelRef = React.useRef(null);

  const hasTimes = item.timeTotal > 0;

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Borrar Tarea
          </AlertDialogHeader>

          <AlertDialogBody>
            {hasTimes ? (
              <Text>
                Vas a borrar la tarea {item.title}, que contiene contabilizado{" "}
                <strong>{formatSeconds(item.timeTotal)}</strong> . Si borras la
                tarea, <strong>borrarás también todos los tiempos</strong>.
                ¿Seguro que quieres continuar?
              </Text>
            ) : (
              <Text>
                ¿Deseas borrar la tarea {item.title}? Esta acción no se puede
                deshacer.
              </Text>
            )}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                onDelete(item);
                onClose();
              }}
              ml={3}
            >
              {hasTimes ? "Si, borrar tarea y todos los tiempos" : "Si, borrar"}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
