import React from "react";
import { FieldAttributes, useField } from "formik";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
} from "@chakra-ui/react";

interface CustomTextInputProps extends FieldAttributes<any> {
  helperText?: string;
}

export const CustomTextInput: React.FC<CustomTextInputProps> = ({
  label,
  helperText,
  ref,
  ...props
}) => {
  const restProps: FieldAttributes<any> = { ...props };
  const [field, meta] = useField(restProps);

  const isInvalid = meta.touched && meta.error !== undefined;

  return (
    <FormControl isRequired={restProps.required} isInvalid={isInvalid}>
      <FormLabel aria-required={true} htmlFor={restProps.id || restProps.name}>
        {label}
      </FormLabel>
      <Input
        id={restProps.id || restProps.name}
        {...field}
        value={field.value || ""}
        {...restProps}
        ref={ref}
      />

      <FormErrorMessage>{meta.error}</FormErrorMessage>
      {!isInvalid && helperText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
