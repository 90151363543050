import React from "react";
import { Customer } from "../../api";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { FiMoreHorizontal } from "react-icons/fi";
import { FaEdit, FaTrash } from "react-icons/fa";
import ListItemWrapper from "../Interface/ListItemWrapper";

interface CustomerListItemProps {
  item: Customer;
  onDelete: (item: Customer) => void;
}

export const CustomersListItem: React.FC<CustomerListItemProps> = ({
  item,
  onDelete,
}) => {
  const cancelRef = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const detailUrl = `/customers/${item.id}`;
  const navigate = useNavigate();

  return (
    <ListItemWrapper key={item.id}>
      <Box minWidth={10}></Box>
      <SimpleGrid columns={2} flexGrow={1} alignItems="center">
        <Box>
          <Text>
            <Link to={detailUrl}>{item.name}</Link>
          </Text>
        </Box>
        <Box>
          <Text>{item.address}</Text>
        </Box>
      </SimpleGrid>

      <Box width={14}>
        <Menu placement="bottom-end">
          <MenuButton
            as={IconButton}
            aria-label="Ordenar"
            size="xs"
            variant="ghost"
            icon={<FiMoreHorizontal />}
          />
          <MenuList alignItems="flex-end">
            <MenuItem icon={<FaEdit />} onClick={() => navigate(detailUrl)}>
              Modificar
            </MenuItem>

            <MenuItem icon={<FaTrash />} onClick={onOpen}>
              Borrar
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Borrar Cliente
            </AlertDialogHeader>

            <AlertDialogBody>
              ¿Deseas eliminar el cliente {item.name}? Esta acción no se puede deshacer.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onDelete(item);
                  onClose();
                }}
                ml={3}
              >
                Si, borrar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </ListItemWrapper>
  );
};
