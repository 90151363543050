import React, { useRef } from "react";
import { ProductLine } from "../../api";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent, AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box, Button,
  Flex,
  IconButton,
  Text, useDisclosure,
  useToast
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ApiClient } from "common";
import { ProductLineParentEnum } from "../../dto/types/product-line.parent.type";
import { Field, Form, Formik } from "formik";
import { EditableField } from "../EditableField";
import { createValidator } from "class-validator-formik";
import { ProductLineUpdateDto } from "../../dto/product-line-update.dto";
import { AiFillDelete } from "react-icons/ai";
import constants from "../../constants";
import {priceToHtml} from '../../util';

interface IProductsWidgetItemProps {
  item: ProductLine;
  type: ProductLineParentEnum;
  parentId: number;
}

export const ProductsWidgetItem: React.FC<IProductsWidgetItemProps> = ({
  item,
  type,
  parentId,
}) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const refetchParent = () => {
    let queryClientCacheKey;
    if (type === ProductLineParentEnum.project)
      queryClientCacheKey = ["projects", parentId.toString()];

    if (type === ProductLineParentEnum.task)
      // @todo revisar este cuando se implemente en la tarea
      queryClientCacheKey = ["tasks", parentId.toString()];

    queryClient.refetchQueries(queryClientCacheKey);
  };

  const deleteMutation = useMutation(
    (id: number) => ApiClient.delete(`products/unassign/${id}`),
    {
      onSuccess: async () => {
        refetchParent();
      },
      onError: () => {
        toast({
          title: "Error accediendo al servidor",
          description:
            "Ha habido un error accediendo al servidor y los datos no se han guardado. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  const priceRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const quantityRef = useRef<HTMLInputElement>(null);
  const cancelDeleteRef = React.useRef(null);

  const {
    isOpen: isOpenDeleted,
    onOpen: onOpenDeleted,
    onClose: onCloseDeleted,
  } = useDisclosure();

  const mutation = useMutation<ProductLine, unknown, ProductLineUpdateDto>(
    (d) =>
      ApiClient.patch<ProductLine>(
        `products/line/${item.id}`,
        JSON.stringify(d)
      ),
    {
      onError: () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onSuccess: async () => {
        refetchParent();
      },
    }
  );

  const submitForm = (v: ProductLineUpdateDto) => {
    mutation.mutate(v);
  };

  return (
    <Formik
      initialValues={{ ...item } as ProductLineUpdateDto}
      onSubmit={submitForm}
      validate={createValidator(ProductLineUpdateDto)}
    >
      {(props) => (
        <Form onBlur={props.handleSubmit}>
          <Flex mb={1} borderBottomWidth="1px" borderBottomColor="gray.200">
            <Box width="35%">
              <EditableField
                visibleValue={props.values.name}
                childRef={nameRef}
                error={props.errors.name}
              >
                <Field
                  type="text"
                  name="name"
                  placeholder="Agregar producto"
                  innerRef={nameRef}
                  style={{
                    border: `1px solid ${props.errors.name ? "red" : "black"}`,
                    paddingLeft: "5px",
                  }}
                />
              </EditableField>
            </Box>

            <Box width="15%">
              <EditableField
                visibleValue={props.values.price.toFixed(2).toString()}
                childRef={priceRef}
                error={props.errors.price}
              >
                <Field
                  type="number"
                  name="price"
                  innerRef={priceRef}
                  style={{
                    width: "50px",
                    border: `1px solid ${props.errors.price ? "red" : "black"}`,
                    paddingLeft: "5px",
                  }}
                />
              </EditableField>
            </Box>

            <Box width="15%">
              <EditableField
                visibleValue={props.values.quantity.toString()}
                childRef={quantityRef}
                error={props.errors.quantity}
              >
                <Field
                  type="number"
                  name="quantity"
                  innerRef={quantityRef}
                  style={{
                    width: "50px",
                    border: `1px solid ${
                      props.errors.quantity ? "red" : "black"
                    }`,
                    paddingLeft: "5px",
                  }}
                />
              </EditableField>
            </Box>

            <Box width="15%">
              <Text>{priceToHtml(item.price * item.quantity)}</Text>
            </Box>

            <Box ml="auto">
              <IconButton
                variant="ghost"
                aria-label="Eliminar Producto"
                icon={<AiFillDelete />}
                onClick={onOpenDeleted}
              />

              <AlertDialog
                isOpen={isOpenDeleted}
                leastDestructiveRef={cancelDeleteRef}
                onClose={onCloseDeleted}
              >
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                      Borrar producto asignado
                    </AlertDialogHeader>

                    <AlertDialogBody>
                      ¿Deseas eliminar el producto asignado {item.name} de {item.price}€? Esta
                      acción no se puede deshacer.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                      <Button ref={cancelDeleteRef} onClick={onCloseDeleted}>
                        Cancelar
                      </Button>
                      <Button
                        isLoading={deleteMutation.isLoading}
                        colorScheme="red"
                        onClick={() => deleteMutation.mutate(item.id)}
                        ml={3}
                      >
                        Si, borrar
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>
            </Box>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
