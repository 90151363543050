import { IsNotEmpty, IsNumber, IsString } from 'class-validator';

export class ProductLineUpdateDto {
  @IsString()
  @IsNotEmpty()
  
  name: string;

  @IsString()
  
  description?: string;

  @IsNumber()
  @IsNotEmpty()
  
  price: number;

  @IsNumber()
  @IsNotEmpty()
  
  quantity: number;
}
