import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { ApiClient } from "common";
import { Task, Time } from "../api";
import { useToast } from "@chakra-ui/react";
import constants from "../constants";

interface WorkingQueryResult {
  working: boolean;
  time: Time | boolean;
  task: Task | boolean;
}

export const useWorkingQuery = (): UseQueryResult<WorkingQueryResult> => {
  /**
   * refetchOnMount lo ponemos a false para que no vuelva a hacer la query cada vez que se monta el componente,
   * ya que lo vamos a usar en muchos sitios.
   *
   * Se pone un intervalo para que se refresque cada x tiempo e intentar mantener la sincronía con el estado
   * del servidor.
   *
   * El botón del timer que implementa esto, una vez cambia su estado (start stop), tiene que llamar a
   * workingQuery.refetch para actualizar el estado del servidor
   */
  const toast = useToast();

  const workingQuery = useQuery(
    ["working"],
    () => ApiClient.get<WorkingQueryResult>("users/working"),
    {
      refetchInterval: 60000,
      refetchOnMount: false,
      onError: () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  return workingQuery;
};
