"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataFetcher = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const services_1 = require("../../services");
const react_query_1 = require("@tanstack/react-query");
/**
 * Obtiene datos de un ep por get y los devuelve al componente hijo.
 *
 * @example
 * <DataFetcher
 *   ep={`partes/${id}`}
 *   render={(d: Parte) =>
 *     <div>
 *       <h2>Modificar parte {d.numero}</h2>
 *     <PartesForm
 *       initialValues={d}
 *       isNewRecord={false}
 *       onSubmit={onSubmit}
 *       dto={UpdateParteDto}
 *     />
 *     <span onClick={() => navigate(-1)}>o volver sin guardar</span>
 *     </div>
 *   }
 * />
 * @param props
 * @constructor
 */
const DataFetcher = (props) => {
    const request = (0, react_query_1.useQuery)(props.queryKey, () => services_1.ApiClient.get(props.ep, props.query), {
        useErrorBoundary: (error) => { var _a; return ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) >= 400; },
        onError: (error) => console.log(error)
    });
    if (request.isLoading)
        return (0, jsx_runtime_1.jsx)("p", { children: "Cargando" }, void 0);
    if (!request.isSuccess)
        return (0, jsx_runtime_1.jsx)("p", { children: "Error en la petici\u00F3n." }, void 0);
    if (request.isError)
        return (0, jsx_runtime_1.jsx)("p", { children: "Error en la petici\u00F3n." }, void 0);
    if (!request.data)
        return (0, jsx_runtime_1.jsx)("p", { children: "Sin datos" }, void 0);
    if (request.status !== 'success')
        return (0, jsx_runtime_1.jsx)("p", { children: "Ha habido un error" }, void 0);
    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: props.render(request.data) }, void 0);
};
exports.DataFetcher = DataFetcher;
