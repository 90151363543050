import { useEffect } from "react";
import { BreadCrumbLink } from "./BreadcrumbsHeader";
import { useAppContext } from "../../context/AppContext";

// @todo implementar algún método de caché si no se hacen las imágenes de los avatares públicas
const useSetBreadcrumbs = (items: BreadCrumbLink[]) => {
  const { breadCrumbLinks, setBreadCrumbLinks } = useAppContext();

  useEffect(() => {
    if (JSON.stringify(items) !== JSON.stringify(breadCrumbLinks))
      setBreadCrumbLinks(items);
  }, [items, setBreadCrumbLinks]);
};

export default useSetBreadcrumbs;
