import {IsEmail, IsNotEmpty, IsString, MinLength} from 'class-validator';

export class LoginDto {
  
  @IsEmail()
  @IsNotEmpty({ message: 'El email no puede estar vacío' })
  email: string;

  
  @IsString()
  @IsNotEmpty()
  @MinLength(6)
  password: string;
}
