"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rolToString = void 0;
const rolToString = (rol) => {
    switch (rol) {
        case 0:
            return 'Super Admin';
        case 1:
            return 'Admin';
        case 2:
            return 'Usuario';
        default:
            return '';
    }
};
exports.rolToString = rolToString;
