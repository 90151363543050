import { IsEnum, IsNotEmpty, IsNumber, IsString } from 'class-validator';
import { ProductLineParentEnum } from './types/product-line.parent.type';

export class ProductAssignDto {
  @IsString()
  @IsNotEmpty()
  
  name: string;

  @IsString()
  
  description: string;

  
  @IsNumber()
  productId: number;

  
  @IsNumber()
  quantity: number;

  
  @IsNumber()
  parentId: number;

  @IsNotEmpty()
  
  @IsEnum(ProductLineParentEnum)
  parent_type: ProductLineParentEnum;

  @IsNumber()
  @IsNotEmpty()
  
  price: number;
}
