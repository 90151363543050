import React, { useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { PaginatedResponse } from "../services/interfaces/ApiResponse";
import { ApiClient } from "common";
import { QuerySortOperator, RequestQueryBuilder } from "@nestjsx/crud-request";
import { InputFilterDebounced } from "../components/Interface/InputFilterDebounced";
import { BiFilter } from "react-icons/bi";
import LoadMoreButton from "../components/LoadMoreButton/LoadMoreButton";
import { Project } from "../api";
import { ProjectsListItem } from "../components/Projects";
import { Can } from "../context/can";
import { BsThreeDots } from "react-icons/bs";
import { CSVLink } from "react-csv";
import { getCurrentDateWithHours } from "../util";
import ContentBox from "../components/Layout/ContentBox";
import useSetBreadcrumbs from "../components/BreadCrumbsHeader/useSetBreadcrumbs";
import { Helmet } from "react-helmet";
import { useStatePersist } from "../hooks";
import { CustomerSelectableDropDown } from "../components/Customers/CustomerSelectableDropDown";
import useAuth from "../context/auth/auth";
import { IsRol } from "common/dist/security/IsRol";
import { RolUser } from "../dto/types/rol.type";
import constants from "../constants";

const breadCrumbs = [
  {
    to: "/",
    label: "Home",
  },
  {
    label: "Proyectos",
  },
];
export const ProjectsPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const loadMoreRef = useRef(null);
  const [search, setSearch] = useStatePersist("ProjectsPageSearch", "");
  const [searchCustomer, setSearchCustomer] = useStatePersist(
    "ProjectsPageSearchCustomer",
    ""
  );
  const [order, setOrder] = useState<QuerySortOperator>("ASC");
  const [orderBy, setOrderBy] = useState("");
  const [mostrarCerrados, setMostrarCerrados] = useState(false);
  const [mostrarAsignadosAMi, setMostrarAsignadosAMi] = useState(false);
  const { user } = useAuth();

  const toast = useToast();

  useSetBreadcrumbs(breadCrumbs);

  const queryKey = "infsc_projects";
  const requestQueryBuilder = new RequestQueryBuilder();
  requestQueryBuilder
    .setLimit(10)
    .setPage(1)
    .search({
      $and: [
        {
          closed: {
            $eq: mostrarCerrados,
          },
          "customer.id": {
            [Number.isInteger(searchCustomer) ? "$eq" : "$gte"]:
              Number.isInteger(searchCustomer) ? searchCustomer : 0,
          },
          [mostrarAsignadosAMi ? "assigned_users.id" : "id"]: {
            [mostrarAsignadosAMi ? "$in" : "$gte"]: mostrarAsignadosAMi
              ? [user?.sub]
              : "0",
          },
          $or: [
            {
              name: {
                $cont: search,
              },
            },
            {
              description: {
                $cont: search,
              },
            },
          ],
        },
      ],
    });

  if (orderBy.length) {
    requestQueryBuilder.sortBy({
      field: orderBy,
      order,
    });
  }

  const ep = `projects`;

  const {
    data,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<PaginatedResponse<Project[]>, Error>(
    [
      queryKey,
      search,
      order,
      orderBy,
      mostrarCerrados,
      searchCustomer,
      mostrarAsignadosAMi,
    ],
    async ({ pageParam = 1 }) => {
      requestQueryBuilder.setPage(pageParam);
      return await ApiClient.get<PaginatedResponse<Project[]>>(
        ep,
        requestQueryBuilder
      );
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage.page < lastPage.pageCount
          ? lastPage.page + 1
          : undefined;
      },
      onError: () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  const onCreate = (newItem: Project) => {
    queryClient.setQueriesData([queryKey], (oldData: any) => {
      const newData = { ...oldData };
      newData.pages[0].data = [newItem].concat(data?.pages[0].data || []);
      return newData;
    });
  };

  const onUpdate = (id: number, data: Project) => {
    queryClient.setQueriesData([queryKey], (oldData: any) => {
      const newPages = oldData.pages.map((group: any) => {
        return {
          ...group,
          data: group.data
            .map((item: any) => {
              if (item.id === id) return data;
              return item;
            })
            .filter((item: Project) =>
              mostrarCerrados ? item.closed : !item.closed
            ),
        };
      });

      return {
        pages: newPages,
        pageParams: oldData.pageParams,
      };
    });

    queryClient.setQueryData(["projects", data.id.toString()], () => data);
  };

  const onDelete = (id: number) => {
    queryClient.setQueriesData([queryKey], (oldData: any) => {
      const newPages = oldData.pages.map((group: any) => {
        return {
          ...group,
          data: group.data.filter((item: any) => item.id !== id),
        };
      });

      return {
        pages: newPages,
        pageParams: oldData.pageParams,
      };
    });
  };

  const deleteMutation = useMutation(
    (id: number) => ApiClient.delete(`projects/${id}`),
    {
      onSuccess: async (data, variables) => {
        onDelete(variables as unknown as number);
      },
      onError: () => {
        toast({
          title: "Error accediendo al servidor",
          description:
            "Ha habido un error accediendo al servidor y los datos no se han guardado. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  const handleDeleteItem = async (item: Project) => {
    deleteMutation.mutate(item.id);
  };

  const [csvData, setCsvData] = useState<any[]>([]);
  // useEffect(() => {
  //     if (!data) {
  //         setCsvData([]);
  //         return;
  //     }
  //
  //     const headers = ['id', 'nombre', 'descripción'];
  //     const newCSVData = data.pages.map(page =>
  //         page.data.map(item => ([
  //                 item.id,
  //                 item.name,
  //                 item.description
  //             ])
  //         )).flat();
  //     setCsvData([headers, ...newCSVData])
  // }, [data])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Project15 - Proyectos</title>
      </Helmet>

      <ContentBox>
        <Flex>
          <HStack flexShrink={0}>
            <Box>
              <InputFilterDebounced
                onChangeDebounced={(d) => setSearch(d)}
                value={search}
              />
            </Box>

            <Menu closeOnSelect={false}>
              <MenuButton
                as={IconButton}
                aria-label="Ordenar"
                variant="ghost"
                icon={<BiFilter />}
              />
              <MenuList minWidth="240px">
                <MenuOptionGroup
                  onChange={(v) => setOrder(v.toString() as QuerySortOperator)}
                  value={order}
                  title="Ordenar"
                  type="radio"
                >
                  <MenuItemOption command="sa" value="ASC">
                    Ascendente
                  </MenuItemOption>
                  <MenuItemOption command="sd" value="DESC">
                    Descendente
                  </MenuItemOption>
                </MenuOptionGroup>
                <MenuDivider />
                <MenuOptionGroup
                  onChange={(v) => setOrderBy(v.toString())}
                  value={orderBy}
                  title="Ordenar por"
                  type="radio"
                >
                  <MenuItemOption value="name">Nombre</MenuItemOption>
                  <MenuItemOption value="description">
                    Descripción
                  </MenuItemOption>
                </MenuOptionGroup>
              </MenuList>
            </Menu>

            <Text>Mostrar: </Text>
            <CheckboxGroup colorScheme="green">
              <Stack spacing={[1, 5]} direction={["column", "row"]}>
                <Checkbox
                  onChange={(e) => setMostrarCerrados(e.target.checked)}
                  isChecked={mostrarCerrados}
                >
                  Cerrados
                </Checkbox>
              </Stack>
            </CheckboxGroup>

            <IsRol user={user} rol={[RolUser.admin, RolUser.superadmin]}>
              <CheckboxGroup colorScheme="green">
                <Stack spacing={[1, 5]} direction={["column", "row"]}>
                  <Checkbox
                    onChange={(e) => setMostrarAsignadosAMi(e.target.checked)}
                    isChecked={mostrarAsignadosAMi}
                  >
                    Sólo asignados a mi
                  </Checkbox>
                </Stack>
              </CheckboxGroup>
            </IsRol>

            <Box>
              <HStack>
                <Text>Cliente: </Text>
                <CustomerSelectableDropDown
                  onChange={(customer) => {
                    setSearchCustomer(customer?.value || "");
                  }}
                  value={Number(searchCustomer)}
                />
              </HStack>
            </Box>
          </HStack>

          <Box ml="auto" flexShrink={0}>
            <Can I="create" a="Project">
              <Button
                size="sm"
                variant="ghost"
                onClick={() => navigate("create")}
              >
                Crear
              </Button>
            </Can>

            <Menu closeOnSelect={true}>
              <MenuButton
                as={IconButton}
                aria-label="Acciones"
                variant="ghost"
                icon={<BsThreeDots />}
              />
              <MenuList minWidth="240px">
                <MenuItemOption>
                  <CSVLink
                    filename={`Proyectos ${getCurrentDateWithHours()}`}
                    data={csvData}
                    target="_blank"
                  >
                    Exportar datos en CSV
                  </CSVLink>
                </MenuItemOption>
              </MenuList>
            </Menu>
          </Box>
        </Flex>
      </ContentBox>

      <ContentBox>
        <Box>
          {isError ? (
            <p>Ha habido un error cargando los datos</p>
          ) : isLoading ? (
            <p>cargando</p>
          ) : data?.pages[0].data.length === 0 ? (
            <p>No hay resultados.</p>
          ) : (
            <>
              {/*<ListItemHeader columns={['Nombre', 'Descripción', ' Usuarios', 'Cliente']}/>*/}

              {data?.pages.map((group, i) => (
                <React.Fragment key={i}>
                  {group.data.map((item) => (
                    <ProjectsListItem
                      item={item}
                      key={item.id}
                      queryKey={queryKey}
                      onDelete={handleDeleteItem}
                    />
                  ))}
                </React.Fragment>
              ))}
            </>
          )}

          <LoadMoreButton
            loadMoreRef={loadMoreRef}
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
          />

          <Outlet
            context={{
              onUpdate,
              onCreate,
            }}
          />
        </Box>
      </ContentBox>
    </>
  );
};
