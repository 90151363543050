import React from "react";
import { Box, Button } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import ContentBox from "../components/Layout/ContentBox";
import useSetBreadcrumbs from "../components/BreadCrumbsHeader/useSetBreadcrumbs";
import { useNavigate } from "react-router-dom";
import { Routes } from "../routes/Routes";

const breadCrumbs = [
  {
    to: "/",
    label: "Home",
  },
  {
    label: "Informes",
  },
];
export const ReportsPage: React.FC = () => {
  const navigate = useNavigate();

  useSetBreadcrumbs(breadCrumbs);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Project15 - Informes</title>
      </Helmet>

      <ContentBox>
        <Box>
          <Button
            onClick={() => navigate(Routes.REPORT_HOURS_BY_CUSTOMER_DATE)}
          >
            Horas por cliente y fecha
          </Button>
        </Box>
      </ContentBox>
    </>
  );
};
