import React, { RefObject, useEffect, useState } from "react";
import { HStack, Icon, Tooltip } from "@chakra-ui/react";
import { HiOutlineExclamationCircle } from "react-icons/hi";

const closeKeys = ["Escape", "Enter"];

interface IEditableFieldProps {
  childRef: RefObject<HTMLInputElement | HTMLTextAreaElement>;
  visibleValue: string | JSX.Element;
  error?: string;
}

export const EditableField: React.FC<IEditableFieldProps> = ({
  children,
  childRef,
  visibleValue,
  error,
}) => {
  const hasError = error && error?.length > 0;

  const [isEditing, setIsEditing] = useState(hasError);

  useEffect(() => {
    if (isEditing) {
      childRef?.current?.focus();
    }
  }, [childRef, isEditing]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!isEditing) return;

    if (closeKeys.indexOf(e.key) > -1) {
      childRef?.current?.blur();
    }
  };

  return (
    <div>
      {!isEditing && (
        <div className="value" onClick={() => setIsEditing(true)}>
          {visibleValue}
        </div>
      )}
      {isEditing && (
        <div
          onKeyDown={onKeyDown}
          onBlur={() => {
            if (!hasError) setIsEditing(false);
          }}
        >
          <HStack alignItems="center">
            {children}
            {hasError && (
              <Tooltip hasArrow label={error} bg="red.600">
                <div>
                  <Icon as={HiOutlineExclamationCircle} color="red.600" />
                </div>
              </Tooltip>
            )}
          </HStack>
        </div>
      )}
    </div>
  );
};
