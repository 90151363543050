import React from 'react';
import { Modal, ModalBody, ModalHeader } from '../Modal';
import { useNavigate, useParams } from 'react-router-dom';
import { Project, Tag, Task, UserEntity } from '../../api';
import {
  Box,
  Heading,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { ApiClient, DataFetcher } from 'common';
import { useQueryClient } from '@tanstack/react-query';
import { TasksList } from './TasksList';
import { TaskUpdateForm } from './TaskUpdateForm';
import { Comments } from '../Comments';
import { CommentParentEnum } from '../../dto/types/comment.parent.type';
import { StartStopButton } from '../StartStop';
import { ProductLineParentEnum } from '../../dto/types/product-line.parent.type';
import { ProductsWidget } from '../Products';
import { UsersAvatars } from '../Users';
import { Helmet } from 'react-helmet';
import { TagsSelector } from '../Tags/TagsSelector';
import { ModalColumn } from '../Modal/ModalColumn';
import { TimesList } from '../Times';
import { useWorkingQuery } from '../../hooks';

export const TaskDetail: React.FC = () => {
  const { taskId, id: projectId } = useParams();
  const backTo = `/projects/${projectId}?sr=false`;
  const navigate = useNavigate();
  const workingQuery = useWorkingQuery();

  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<Project>(['projects', projectId]);

  const onUsersSelected = (users: UserEntity[]) => {
    console.log('entro');
    console.log({ users });
    ApiClient.post(
      `tasks/assign-users/${taskId}`,
      JSON.stringify({ assigned_users: users.map((user) => user.id) }),
    );
  };

  const onTagsSelected = (tags: Tag[]) => {
    ApiClient.post(
      `tasks/assign-tags/${taskId}`,
      JSON.stringify({ assigned_tags: tags.map((tag) => tag.id) }),
    );
  };

  console.log({ project });

  return (
    <Modal>
      <DataFetcher
        queryKey={['tasks', taskId]}
        ep={`tasks/${taskId}`}
        render={(item: Task) => (
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>
                Project15 - Proyectos - {project?.name} - {item.title}
              </title>
            </Helmet>

            <ModalHeader
              onClose={() => {
                console.log('onclose requested');
                navigate(backTo);
              }}
            >
              <HStack spacing={2} verticalAlign="center">
                <Heading size="md">
                  {project?.customer?.name}
                  {project?.customer?.name.length && ' - '}
                  {project?.name}
                </Heading>
                {!(workingQuery.data?.task === false && item.closed) && (
                  <StartStopButton
                    taskId={parseInt(taskId as unknown as string)}
                  />
                )}
              </HStack>
            </ModalHeader>

            <ModalBody columns={2}>
              <ModalColumn borderRightWidth="1px">
                <TaskUpdateForm item={item} />
                <Box paddingTop={5}>
                  <Heading size="md" marginBottom={1}>
                    Subtareas
                  </Heading>
                  <TasksList
                    projectId={projectId as unknown as number}
                    taskParent={taskId as unknown as number}
                  />
                </Box>
              </ModalColumn>

              <ModalColumn>
                <UsersAvatars
                  selectable={true}
                  currentUsers={item.assigned_users}
                  onBlur={onUsersSelected}
                />
                <TagsSelector
                  selectable={true}
                  currentTags={item.assigned_tags}
                  onBlur={onTagsSelected}
                />

                <Tabs>
                  <TabList>
                    <Tab className="noOutline">Comentarios</Tab>
                    <Tab className="noOutline">Tiempos</Tab>
                    <Tab className="noOutline">Productos</Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel>
                      <Comments
                        type={CommentParentEnum.task}
                        parentId={taskId as unknown as number}
                      />
                    </TabPanel>

                    <TabPanel>
                      <TimesList
                        projectId={projectId as unknown as number}
                        taskId={taskId as unknown as number}
                      />
                    </TabPanel>

                    <TabPanel>
                      <ProductsWidget
                        parentId={item.id}
                        type={ProductLineParentEnum.task}
                        products={item.products}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </ModalColumn>
            </ModalBody>
          </>
        )}
      />
    </Modal>
  );
};
