import React from "react";
import { Avatar, Box, HStack, Icon, Text } from "@chakra-ui/react";
import { ICustomOptionProps } from "./types";
import useImageJwt from "../../ImageJWT/useImageJwt";
import { TiTick } from "react-icons/ti";
import { GrFormAdd } from "react-icons/gr";

export const CustomOption: React.FC<ICustomOptionProps> = ({
  innerRef,
  innerProps,
  isDisabled,
  data,

  ...rest
}) => {
  const { image: imageBlob } = useImageJwt(data.avatar);

  // @ts-ignore
  const { isSelected } = rest;
  // @ts-ignore
  const { isFocused } = rest;

  if (isDisabled) return null;
  return (
    <div ref={innerRef} {...innerProps}>
      <HStack
        p={1}
        pl={4}
        backgroundColor={
          isFocused ? "blue.50" : isSelected ? "gray.100" : "white"
        }
        _hover={{ backgroundColor: "gray.100", cursor: "pointer" }}
      >
        <Avatar name={data.label} src={imageBlob} size="sm" mr={2} />
        <Text>{data.label}</Text>
        <Box style={{ marginLeft: "auto" }}>
          {!isSelected && isFocused && (
            <Icon
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
              as={GrFormAdd}
            />
          )}
          {isSelected && !isFocused && <TiTick />}
          {isSelected && isFocused && (
            <Icon
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
              as={GrFormAdd}
              transform={"rotate(45deg)"}
            />
          )}
        </Box>
      </HStack>
    </div>
  );
};
