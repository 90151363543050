import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { SignUpDto } from "../../dto/signup.dto";
import { createValidator } from "class-validator-formik";
import { CustomTextInput } from "./CustomTextInput";
import React, { useState } from "react";
import { Form, Formik } from "formik";
import { IsNotEmpty } from "class-validator";
import useAuth from "../../context/auth/auth";
import { Match } from "../../decorators/match";

class SignUpWithRepeatPasswordDto extends SignUpDto {
  @IsNotEmpty()
  @Match("password", { message: "Las contraseñas no coinciden" })
  password_repeat: string;
}

export const RegisterForm = () => {
  const auth = useAuth();
  const [, setError] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);

  const initialValues: SignUpWithRepeatPasswordDto = {
    company_name: "goltratec",
    email: "mi@mail" + Date.now() + ".com",
    name: "nombre",
    password: "goltratec",
    password_repeat: "goltratec",
  };
  const submitForm = async (values: SignUpWithRepeatPasswordDto) => {
    const res = await auth.signUp(values);
    if (!res) onOpen();
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={submitForm}
        validate={createValidator(SignUpWithRepeatPasswordDto)}
      >
        {(props) => (
          <Form
            onChange={() => {
              setError(false);
            }}
          >
            <Stack spacing="6">
              <CustomTextInput
                helperText="Introduzca el nombre de la empresa"
                label="Empresa"
                placeholder="Su Empresa"
                name="company_name"
                autoComplete="off"
              />

              <CustomTextInput
                helperText="Introduzca su email"
                label="Email"
                placeholder="su@mail.com"
                name="email"
                autoComplete="off"
              />

              <CustomTextInput
                helperText="Introduzca su nombre"
                label="Nombre"
                placeholder="Juan Garcia"
                name="name"
                autoComplete="off"
              />

              <CustomTextInput
                helperText="Introduzca su contraseña"
                label="Contraseña"
                type="password"
                placeholder=""
                name="password"
                autoComplete="off"
              />

              <CustomTextInput
                helperText="Repita su contraseña"
                label="Contraseña"
                type="password"
                placeholder=""
                name="password_repeat"
                autoComplete="off"
              />

              <Button
                type="submit"
                colorScheme="blue"
                size="lg"
                fontSize="md"
                disabled={
                  !(
                    props.isValid &&
                    (props.dirty || Object.keys(props.touched).length)
                  ) || props.isSubmitting
                }
              >
                {props.isSubmitting ? "Espere" : "Regístrate"}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Ha habido un problema
            </AlertDialogHeader>

            <AlertDialogBody>
              Ha habido un problema con los datos introducidos. Es posible que
              sean incorrectos o que ya hayan sido usado anteriormente.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onClose}>Corregir los datos</Button>
              <Button
                colorScheme="blue"
                ref={cancelRef}
                onClick={() => {
                  onClose();
                  window.location.reload();
                }}
                ml={3}
              >
                Recargar la página
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
