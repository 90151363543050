import {DateTime} from 'luxon';


export const convertTimestampToDate = (seconds: number): string => {
  const date = DateTime.fromSeconds(seconds);
  return date.toFormat('dd/MM/yyyy HH:mm:ss');
}


export const parseDateToTimeZone = (item: Date): string => {
  const date = DateTime.fromISO(item.toString())
  return date.toFormat('dd/MM/yyyy HH:mm:ss');
}
