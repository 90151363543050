import { es } from "./es.i18n";
import { DefaultOption } from "../components/Forms/CustomSelect";

const translations = { es };

// El idioma español es el que va a mandar. Estaría bien que se pudiera comprobar que los demás idiomas
// siguieran los índices definidos en el español.
type ObjectKey = keyof typeof es;

type AvailableLanguages = "es";
export const t = (index: string, lang: AvailableLanguages): string => {
  const language = translations[lang];

  return language[index as ObjectKey] || index;
};

/**
 * Para traducir un enum y poder usarlo con react-select
 *
 * @param enumObject
 * @param lang
 */
export const enunToValuesTranslatedToSelect = (
  enumObject: any,
  lang: AvailableLanguages
): DefaultOption[] => {
  const properties = Object.keys(enumObject).filter((v) => isNaN(Number(v)));
  return properties
    .map((p) => ({
      value: enumObject[p],
      label: t(p, lang),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};
