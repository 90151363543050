import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ApiClient } from "common";
import Select from "react-select";
import { CustomOptionDataItem } from "./types";
import { CustomOption } from "./CustomOption";
import { CustomMultiValueLabel } from "./CustomMultiValueSelect";
import { UserEntity } from "../../../api";
import {
  Avatar,
  AvatarGroup,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import useImageJwt from "../../ImageJWT/useImageJwt";
import { GrUserAdd } from "react-icons/gr";
import constants from "../../../constants";

interface IUsersAvatarsProps {
  selectable: boolean;
  currentUsers: UserEntity[];
  onBlur?: (users: UserEntity[]) => void;
  showLabel?: boolean;
}

/**
 * Muestra los avatares de los usuarios asignados a una entidad
 *
 * @param selectable si el componente va a mostrar los avatares con posibilidad de hacer selección o no
 * @param currentUsers array con los ids de usuarios asignados
 * @param onBlur
 * @param showLabel
 * @constructor
 */
export const UsersAvatars: React.FC<IUsersAvatarsProps> = ({
  selectable,
  currentUsers,
  onBlur,
  showLabel = true,
}) => {
  const toast = useToast();

  const { data } = useQuery<UserEntity[]>(
    ["users"],
    () => ApiClient.get<UserEntity[]>("users"),
    {
      onError: () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );
  const [usersData, setUsersData] = useState<CustomOptionDataItem[]>([]);

  useEffect(() => {
    if (!data) return;

    setUsersData(
      data.map((item) => ({
        label: item.name,
        value: item.id,
        avatar: item.avatar,
      }))
    );
  }, [data]);

  const [currentUsersInternal, setCurrentUsersInternal] = useState<
    UserEntity[]
  >([]);

  useEffect(() => {
    setCurrentUsersInternal(currentUsers);
  }, [currentUsers]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  // const onClose = () => console.log('on close called');
  const closeModal = () => {
    onClose();
    onBlur && onBlur(currentUsersInternal);
  };

  return (
    <div>
      <Modal isOpen={isOpen} onClose={closeModal} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Seleccione usuarios para el proyecto </ModalHeader>
          {/*<ModalCloseButton />*/}
          <ModalBody>
            <Select
              onChange={(values) => {
                const newUsers = data?.filter((user) =>
                  values.map((val) => val.value).includes(user.id)
                );
                setCurrentUsersInternal(newUsers || []);
              }}
              options={usersData}
              placeholder="Seleccione..."
              isMulti={true}
              value={usersData.filter((option) =>
                currentUsersInternal
                  .map((user) => user.id)
                  .includes(option.value)
              )}
              styles={{
                // menuList: (provided, state) =>  {
                //     return { ...provided, display: 'inline-block', background: 'red' };
                // },
                menu: (provided) => {
                  return { ...provided, position: "static" };
                },
                multiValue: (provided) => {
                  return { ...provided, background: "none" };
                },
              }}
              hideSelectedOptions={false}
              menuIsOpen={true}
              autoFocus={true}
              minMenuHeight={500}
              components={{
                Option: CustomOption,
                MultiValueLabel: CustomMultiValueLabel,
                MultiValueRemove: () => <></>,
                NoOptionsMessage: () => (
                  <Text textAlign="center" textColor="gray.600">
                    No hay usuarios
                  </Text>
                ),
                // MultiValueContainer: Buscame
              }}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={closeModal}>
              Aceptar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <div
        onClick={() => {
          if (selectable) onOpen();
        }}
      >
        <div></div>

        <HStack _hover={{ cursor: selectable ? "pointer" : "auto" }}>
          {showLabel && <Text fontSize="sm">Usuarios asignados:</Text>}
          {currentUsersInternal.length > 0 && (
            <AvatarGroup size="md">
              {currentUsersInternal.map((user) => (
                <TooltipAvatar
                  key={user.id}
                  name={data?.find((item) => item.id === user.id)?.name}
                  src={user.avatar}
                  transition="all ease-in-out .1s"
                  _hover={{
                    transform: "scale(1.3)",
                    zIndex: 999999,
                    boxShadow: "0 0 20px rgba(0,0,0,.2)",
                  }}
                />
              ))}
            </AvatarGroup>
          )}

          {selectable && <Avatar bg="gray.200" icon={<GrUserAdd />} />}
        </HStack>
      </div>

      {/*@ts-ignore */}
    </div>
  );
};
const TooltipAvatar: typeof Avatar = (props: any) => {
  const { image: imageBlob } = useImageJwt(props.src as string);

  return (
    <Tooltip label={props.name} placement="top">
      <Avatar {...props} src={imageBlob} />
    </Tooltip>
  );
};
