import React, { ReactElement } from "react";
import { Box, useColorModeValue } from "@chakra-ui/react";

interface IContentBoxProps {
  children: ReactElement;
}

const ContentBox: React.FC<IContentBoxProps> = ({ children }) => (
  <Box bg={useColorModeValue("white", "gray.700")} boxShadow="md" mb={4} p={4}>
    <Box>{children}</Box>
  </Box>
);

export default ContentBox;
