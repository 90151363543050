import React from "react";
import { Time } from "../../api";
import { formatSeconds } from "common/dist/util";
import { Grid, GridItem, Text } from "@chakra-ui/react";

interface ITimesSummaryProps {
  tasks: Time[];
}

export const TimesSummary: React.FC<ITimesSummaryProps> = ({ tasks }) => {
  const total = tasks?.reduce(
    (oldValue, item) => oldValue + item.total_time_segs,
    0
  );
  const totalFormatted = formatSeconds(total);

  return (
    <Grid templateColumns="repeat(4, 1fr)">
      <GridItem colSpan={3} pr={4}>
        <Text textAlign="right">Total:</Text>
      </GridItem>
      <GridItem>
        <Text>{totalFormatted}</Text>
      </GridItem>
    </Grid>
  );
};
