import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Routes } from "../../routes";
import {
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { Customer } from "../../api";
import { DataFetcher } from "common";
import { Modal, ModalBody, ModalHeader } from "../Modal";
import { Comments } from "../Comments";
import { CommentParentEnum } from "../../dto/types/comment.parent.type";
import { CustomersUpdateForm } from "./CustomersUpdateForm";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { ProjectQuickList } from "../Projects/ProjectQuickList";
import { Helmet } from "react-helmet";
import { ModalColumn } from "../Modal/ModalColumn";

export const CustomersUpdate = () => {
  const backTo = Routes.CUSTOMERS + "?sr=false";
  const navigate = useNavigate();
  const { id } = useParams();

  const requestQueryBuilderProyectos = new RequestQueryBuilder();
  requestQueryBuilderProyectos.search({ customerId: id, closed: false });

  return (
    <Modal>
      <DataFetcher
        queryKey={["customers", id]}
        ep={`customers/${id}`}
        render={(d: Customer) => (
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Project15 - Clientes - {d.name}</title>
            </Helmet>

            <ModalHeader onClose={() => navigate(backTo)}>
              <HStack spacing={2}>
                <span>Modificar cliente {d.name}</span>
              </HStack>
            </ModalHeader>
            <ModalBody columns={2}>
              <ModalColumn borderRightWidth="1px">
                <CustomersUpdateForm item={d} />
              </ModalColumn>

              <ModalColumn>
                <Tabs>
                  <TabList>
                    <Tab className="noOutline">Comentarios</Tab>
                    <Tab className="noOutline">Proyectos</Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel>
                      <Comments
                        type={CommentParentEnum.customer}
                        parentId={id as unknown as number}
                      />
                    </TabPanel>

                    <TabPanel>
                      <ProjectQuickList
                        requestQueryBuilder={requestQueryBuilderProyectos}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </ModalColumn>
            </ModalBody>
          </>
        )}
      />
    </Modal>
  );
};
