import { IsNotEmpty, IsOptional } from 'class-validator';

export class HorasPorClienteYFechaDto {
  @IsNotEmpty()
  
  startDate: string;

  @IsNotEmpty()
  
  endDate: string;

  
  @IsOptional()
  projectTagsIgnore?: number[];

  
  @IsOptional()
  projectTagsInclude?: number[];

  
  @IsOptional()
  customers?: number[];
}
