import React from "react";
import {
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const Page404: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Flex height="100%" align={"center"} justify={"center"} py={12}>
      <Stack
        boxShadow={"2xl"}
        bg={useColorModeValue("white", "gray.700")}
        rounded={"xl"}
        p={10}
        spacing={8}
        align={"center"}
      >
        <Stack align={"center"} spacing={2}>
          <Heading
            textTransform={"uppercase"}
            fontSize={"3xl"}
            color={useColorModeValue("gray.800", "gray.200")}
          >
            Error 404
          </Heading>
          <Text fontSize={"lg"} color={"gray.500"}>
            La página solicitada no se puede mostrar
          </Text>

          <Text fontSize={"sm"} color={"gray.500"}>
            Elija un elemento del menú de la izquierda
          </Text>
        </Stack>
        <Stack spacing={4} direction={{ base: "column", md: "row" }} w={"full"}>
          <Button
            bg={"blue.400"}
            rounded={"full"}
            color={"white"}
            flex={"1 0 auto"}
            onClick={() => navigate("/")}
            _hover={{ bg: "blue.500" }}
            _focus={{ bg: "blue.500" }}
          >
            Ir al inicio
          </Button>
        </Stack>
      </Stack>
    </Flex>
  );
};
