import React, { useEffect, useRef } from "react";
import { Task } from "../../api";
import { Button, ButtonGroup, Text, useDisclosure } from "@chakra-ui/react";
import { useStartStopHook } from "../StartStop/startStopHook";
import { StopConfirmDialog } from "../StartStop";

interface IBuscadorNewTaskStartStop {
  item: Task;
  cerrarBuscadorHandler: () => void;
  volverSinIniciarHandler: () => void;
}

export const BuscadorNewTaskStartStop: React.FC<IBuscadorNewTaskStartStop> = ({
  item,
  cerrarBuscadorHandler,
  volverSinIniciarHandler,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    startStop,
    startMutation,
    stopMutation,
    workingQuery,
    stopCurrentTimeAndStartNewOne,
  } = useStartStopHook(item.id, onOpen, onClose, () => {
    console.log("on start stop finished");
    cerrarBuscadorHandler();
  });

  const buttonRef = useRef<HTMLButtonElement>(null);

  const isLoading =
    startMutation.isLoading ||
    stopMutation.isLoading ||
    workingQuery.isRefetching;

  useEffect(() => {
    buttonRef.current?.focus();
  });

  return (
    <div>
      <Text mb={4}>
        Acabas de crear la tarea <strong>{item.title}</strong>
      </Text>

      <ButtonGroup isAttached variant="outline">
        <Button
          onClick={() => startStop()}
          disabled={isLoading}
          mr="-px"
          colorScheme="blue"
          variant="solid"
          ref={buttonRef}
        >
          Iniciar la tarea
        </Button>

        <Button mr="-px" onClick={cerrarBuscadorHandler}>
          Cerrar Buscador
        </Button>

        <Button onClick={volverSinIniciarHandler}>Volver sin iniciar</Button>
      </ButtonGroup>

      <StopConfirmDialog
        isOpen={isOpen}
        onClose={onClose}
        taskTitle={(workingQuery.data?.task as Task).title}
        startHandler={stopCurrentTimeAndStartNewOne}
      />
    </div>
  );
};
