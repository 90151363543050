import React from "react";
import { Route, Routes } from "react-router-dom";

import useAuth from "../context/auth/auth";
import { Routes as R } from "./Routes";

import {
  CompanyPage,
  HomePage,
  LoginPage,
  Page404,
  ProductsPage,
  RecoverPasswordPage,
  RegisterPage,
  ReportHoursByCustomerDate,
  ReportsPage,
} from "../pages";
import { GuestLayout } from "../components/Layout";
import { CustomersPage } from "../pages/CustomersPage";
import { CustomersCreate, CustomersUpdate } from "../components/Customers";
import { RequireGuest } from "./RequireGuest";
import { ProjectsPage } from "../pages/ProjectsPage";
import { ProjectsCreate, ProjectsUpdate } from "../components/Projects";
import { RequireAuth } from "./RequireAuth";
import { ProjectsDetailPage } from "../pages/ProjectsDetailPage";
import { SubTaskDetail, TaskDetail, TasksCreate } from "../components/Tasks";
import { UsersPage } from "../pages/UsersPage";
import { UsersCreate, UsersUpdate } from "../components/Users";
import { ProductsCreate, ProductsUpdate } from "../components/Products";
import { UsersPasswordUpdate } from "../components/Users/UsersPasswordUpdate";
import SidebarWithHeader from "../components/Layout/SidebarWithHeader";
import { TagsPage } from "../pages/TagsPage";
import { TagsCreate, TagsUpdate } from "../components/Tags";
import { ProfilePage } from "../pages/ProfilePage";
import { ResetPasswordPage } from "../pages/ResetPasswordPage";
import { ReportHoursByCustomerDateResult } from "../pages/ReportHoursByCustomerDateResult";
import { TestPage } from "../pages/TestPage";
import { WidgetAssignedTasks } from "../pages/WidgetAssignedTasks";

export const AppRoutes: React.FC = (props) => {
  const auth = useAuth();

  const Layout = auth.isUserAuthenticated() ? SidebarWithHeader : GuestLayout;

  return (
    <Routes>
      <Route
        path={R.TEST}
        element={
          <RequireAuth>
            <TestPage />
          </RequireAuth>
        }
      />
      <Route path="/" element={<Layout />}>
        <Route path="*" element={<Page404 />} />
        {/* @todo ver como ponemos esto porque por la validación si ponemos home como '/', siempre se queda marcado */}
        <Route
          index
          element={
            <RequireAuth>
              <HomePage />
            </RequireAuth>
          }
        />
        <Route
          path={R.HOME}
          element={
            <RequireAuth>
              <HomePage />
            </RequireAuth>
          }
        />

        <Route
          path={R.WIDGET_ASSIGNED_TASKS}
          element={
            <RequireAuth>
              <WidgetAssignedTasks />
            </RequireAuth>
          }
        />

        <Route
          path={R.USERS}
          element={
            <RequireAuth>
              <UsersPage />
            </RequireAuth>
          }
        >
          <Route path={R.OUTLET_CREATE} element={<UsersCreate />} />
          <Route path={R.OUTLET_VIEW} element={<UsersUpdate />} />
          <Route
            path={R.USERS_UPDATE_PASSWORD}
            element={<UsersPasswordUpdate />}
          />
        </Route>

        <Route
          path={R.CUSTOMERS}
          element={
            <RequireAuth>
              <CustomersPage />
            </RequireAuth>
          }
        >
          <Route path={R.OUTLET_CREATE} element={<CustomersCreate />} />
          <Route path={R.OUTLET_VIEW} element={<CustomersUpdate />} />
        </Route>

        <Route
          path={R.PROJECTS}
          element={
            <RequireAuth>
              <ProjectsPage />
            </RequireAuth>
          }
        >
          <Route path={R.OUTLET_CREATE} element={<ProjectsCreate />} />
          <Route
            path={R.OUTLET_CREATE + "/client/:id"}
            element={<ProjectsCreate />}
          />
          <Route path={R.OUTLET_UPDATE} element={<ProjectsUpdate />} />
        </Route>

        <Route
          path="projects/:id"
          element={
            <RequireAuth>
              <ProjectsDetailPage />
            </RequireAuth>
          }
        >
          <Route path="task-create" element={<TasksCreate />} />
          <Route path="task/:taskId" element={<TaskDetail />} />
          <Route path="task/:taskId/:subTaskId" element={<SubTaskDetail />} />
        </Route>

        <Route
          path={R.REPORTS}
          element={
            <RequireAuth>
              <ReportsPage />
            </RequireAuth>
          }
        />
        <Route
          path={R.REPORT_HOURS_BY_CUSTOMER_DATE}
          element={
            <RequireAuth>
              <ReportHoursByCustomerDate />
            </RequireAuth>
          }
        >
          <Route path={"*"} element={<ReportHoursByCustomerDateResult />} />
        </Route>

        <Route
          path={R.PRODUCTS}
          element={
            <RequireAuth>
              <ProductsPage />
            </RequireAuth>
          }
        >
          <Route path={R.OUTLET_CREATE} element={<ProductsCreate />} />
          <Route path={R.OUTLET_VIEW} element={<ProductsUpdate />} />
        </Route>

        <Route
          path={R.TAGS}
          element={
            <RequireAuth>
              <TagsPage />
            </RequireAuth>
          }
        >
          <Route path={R.OUTLET_CREATE} element={<TagsCreate />} />
          <Route path={R.OUTLET_VIEW} element={<TagsUpdate />} />
        </Route>

        <Route
          path={R.USER_LOGIN}
          element={
            <RequireGuest>
              <LoginPage />
            </RequireGuest>
          }
        />
        <Route
          path={R.USER_REGISTER}
          element={
            <RequireGuest>
              <RegisterPage />
            </RequireGuest>
          }
        />
        <Route
          path={R.PROFILE}
          element={
            <RequireAuth>
              <ProfilePage />
            </RequireAuth>
          }
        />
        <Route
          path={R.COMPANY}
          element={
            <RequireAuth>
              <CompanyPage />
            </RequireAuth>
          }
        />

        <Route
          path={R.RECOVER_PASSWORD}
          element={
            <RequireGuest>
              <RecoverPasswordPage />
            </RequireGuest>
          }
        />
        <Route
          path={R.RESET_PASSWORD}
          element={
            <RequireGuest>
              <ResetPasswordPage />
            </RequireGuest>
          }
        />
      </Route>
    </Routes>
  );
};
