import React from "react";
import { Box, HStack, Icon, Text } from "@chakra-ui/react";
import { TiTick } from "react-icons/ti";
import { GrFormAdd } from "react-icons/gr";
import { ICustomOptionProps } from "./types";

export const CustomOption: React.FC<ICustomOptionProps> = ({
  innerRef,
  innerProps,
  isDisabled,
  data,

  ...rest
}) => {
  if (isDisabled) return null;

  // @ts-ignore
  const { isSelected } = rest;
  // @ts-ignore
  const { isFocused } = rest;

  return (
    <div ref={innerRef} {...innerProps}>
      <HStack
        p={1}
        pl={4}
        backgroundColor={
          isFocused ? "blue.50" : isSelected ? "gray.100" : "white"
        }
        _hover={{ backgroundColor: "gray.100", cursor: "pointer" }}
      >
        <Box
          bg={data.background_color}
          color={data.text_color}
          mr={1}
          py={0.5}
          px={2.5}
          borderRadius="sm"
          fontSize="sm"
        >
          {data.label}
        </Box>
        <Text>{data.label}</Text>
        <Box style={{ marginLeft: "auto" }}>
          {!isSelected && isFocused && (
            <Icon
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
              as={GrFormAdd}
            />
          )}
          {isSelected && !isFocused && <TiTick />}
          {isSelected && isFocused && (
            <Icon
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
              as={GrFormAdd}
              transform={"rotate(45deg)"}
            />
          )}
        </Box>
      </HStack>
    </div>
  );
};
