import React, { useEffect, useRef } from "react";
import { Text } from "@chakra-ui/react";
import { useStopwatch } from "react-timer-hook";
import { useWorkingQuery } from "../../hooks";
import { Task, Time } from "../../api";

export const Timer = () => {
  const workingQuery = useWorkingQuery();

  const { seconds, minutes, hours, days, isRunning, reset } = useStopwatch({
    autoStart: false,
  });

  const currentTask = useRef<false | number>();

  useEffect(() => {
    if (workingQuery.isLoading || workingQuery.isRefetching) return;

    if (!workingQuery.data) return;

    if (!workingQuery.data.working) {
      reset(undefined, false);
      currentTask.current = false;
      return;
    }

    if (isRunning) {
      return;
    }

    const time = workingQuery.data.time as Time;

    // reset quiere la fecha en el futuro para usarla como offset.
    const diff = Date.now() - time.start_time * 1000;
    const resetTime = new Date(Date.now() + diff);
    currentTask.current = (workingQuery.data.task as Task).id;

    reset(resetTime, true);
  }, [
    isRunning,
    reset,
    workingQuery.data,
    workingQuery.isLoading,
    workingQuery.isRefetching,
  ]);

  /**
   * Este use effect va a resetear el timmer cuando se sigue trabajando y la tarea ha cambiado.
   * Esto pasa cuando estás en varios navegadores y dejas los tabs abiertos.
   * De esta forma, reseteamos el contador al tiempo correcto.
   */
  useEffect(() => {
    if (!workingQuery.data) return;

    if (
      currentTask.current !== false &&
      currentTask.current !== (workingQuery.data.task as Task).id
    ) {
      currentTask.current = (workingQuery.data.task as Task).id;
      reset(undefined, false);
    }
  }, [workingQuery.data, isRunning]);

  return (
    <Text fontWeight="normal">
      {`${(hours + days * 24).toLocaleString("es-ES", {
        minimumIntegerDigits: 2,
      })}:${minutes.toLocaleString("es-ES", {
        minimumIntegerDigits: 2,
      })}:${seconds.toLocaleString("es-ES", { minimumIntegerDigits: 2 })}`}
    </Text>
  );
};
