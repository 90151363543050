import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box, Flex } from "@chakra-ui/react";
import { MdOutlineDragHandle } from "react-icons/md";

interface ISortableItemProps {
  id: string;
  isDragged?: boolean;
  canDrag: boolean;
}

export const SortableItem: React.FC<ISortableItemProps> = ({
  children,
  id,
  isDragged,
  canDrag,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style: any = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  if (isDragged) {
    style.opacity = "0.3";
    style.transition = "all ease-in-out .2s";
  }

  return (
    <Flex
      ref={setNodeRef}
      style={style}
      {...attributes}
      _hover={{
        backgroundColor: "gray.50",
        cursor: "default",
      }}
      role="group"
      borderLeft="1px"
      borderRight="1px"
      borderBottom="1px"
      borderColor="gray.300"
      alignItems="center"
      mb={1}
    >
      {canDrag && (
        <Box
          {...listeners}
          mr={1}
          style={{
            opacity: 0,
            transition: "opacity ease-in-out .1s",
          }}
          pl={1}
          _hover={{ cursor: "grab" }}
          _groupHover={{
            opacity: "1 !important",
          }}
        >
          <MdOutlineDragHandle />
        </Box>
      )}
      <Box flexGrow={1}>{children}</Box>
    </Flex>
  );
};
