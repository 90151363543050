import {
  IsNotEmpty,
  IsOptional,
  IsString,
  MaxLength,
  MinLength,
} from 'class-validator';

export class CustomerUpdateDto {
  @IsNotEmpty()
  @MinLength(5)
  @MaxLength(255)
  
  name: string;

  @IsString()
  @MaxLength(9)
  @IsOptional()
  
  document?: string;

  @MaxLength(100)
  @IsOptional()
  
  address?: string;

  @MaxLength(5)
  @IsOptional()
  
  postal_code?: string;

  @MaxLength(100)
  @IsOptional()
  
  city?: string;

  @MaxLength(100)
  @IsOptional()
  
  state?: string;

  @MaxLength(100)
  @IsOptional()
  
  country?: string;

  @MaxLength(15)
  @IsOptional()
  
  phone1?: string;

  @MaxLength(15)
  @IsOptional()
  
  phone2?: string;
}
