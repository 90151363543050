/**
 * https://github.com/horizon-ui/horizon-ui-chakra/blob/main/src/components/dataDispaly/TimelineRow.js
 */
import {
  Box, Button,
  Flex,
  Icon,
  Popover, PopoverArrow, PopoverBody, PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import parse from 'html-react-parser';

interface TimelineRowProps {
  logo: React.FC;
  title: string;
  date: string;
  color: string;
  index: number;
  arrLength: number;
  popoverHeader?: string;
  popoverBody?: string;
}
export function TimelineRow({
  logo,
  title,
  date,
  color,
  index,
  arrLength,
  popoverHeader,
  popoverBody
}: TimelineRowProps) {
  const textColor = useColorModeValue("gray.700", "white.300");
  const bgIconColor = useColorModeValue("white.300", "gray.700");

  return (
    <Flex minH={"54px"} mb={0}>
      <Flex flexDirection="column" mr={1} pt={1}>
        <Flex w={"26px"} justifyContent={"center"} alignItems="start" mb={1}>
          {popoverHeader || popoverBody ?
            <Popover isLazy>
              <PopoverTrigger>
                <Button
                  variant='link'
                  outline='none'
                  boxShadow='none'
                >
                  <Icon
                    as={logo}
                    bg={bgIconColor}
                    color={color}
                    w={"28px"}
                    zIndex="1"
                  />
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                {popoverHeader && <PopoverHeader fontWeight='semibold'>{popoverHeader}</PopoverHeader>}
                <PopoverArrow />
                <PopoverCloseButton />
                {popoverBody && <PopoverBody><Text fontSize="sm" color={textColor}>{parse(popoverBody)}</Text>
                </PopoverBody>}
              </PopoverContent>
            </Popover>
            :
            <Icon
              as={logo}
              bg={bgIconColor}
              color={color}
              w={"28px"}
              zIndex="1"
            />
          }
        </Flex>
        <Flex
          h={index === arrLength - 1 ? "12px" : "100%"}
          justifyContent="center"
        >
          <Box w={"2px"} bg="gray.200" />
        </Flex>
      </Flex>
      <Flex direction="column" justifyContent="flex-start" h="100%">
        <Text fontSize="sm" color={textColor}>
          {title}
        </Text>
        <Text fontSize="xs" color="gray.500" fontWeight="normal">
          {date}
        </Text>
      </Flex>
    </Flex>
  );
}
