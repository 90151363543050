import {Box, Button, Stack, Text} from "@chakra-ui/react";
import React, {useState} from "react";
import {Form, Formik} from "formik";
import {createValidator} from "class-validator-formik";
import {CustomTextInput} from ".";
import {RecoverPasswordDto} from "../../dto/recover-password.dto";
import useAuth from "../../context/auth/auth";

// export const RecoverPasswordForm = (props: HTMLChakraProps<'form'>) => {
export const RecoverPasswordForm = () => {
  const auth = useAuth();
  const [formVisible, setFormVisible] = useState(true);

  const initialValues: RecoverPasswordDto = {
    email: "",
  };

  const submitForm = async (values: RecoverPasswordDto) => {
    // en la inicial jose contemplaba mostrar error cuando el usuario no era correcto
    // pasamos de dar pistas, así que siempre decimos que ok, que le hemos mandado el correo
    await auth
      .recoverPassword(values.email)
      // .then((r) => {
      //     if (r)
      //         setFormVisible(false)
      // })
      .catch((e) => {
        // toast({
        //     title: "Error",
        //     description: "Ha habido un error al recuperar tu contraseña",
        //     status: "error",
        //     duration: 9000,
        //     isClosable: true,
        // })
        console.error(e);
      })
      .finally(() => setFormVisible(false));
  };

  return formVisible ? (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validate={createValidator(RecoverPasswordDto)}
    >
      {(props) => (
        <Form>
          <Stack spacing="6">
            <CustomTextInput
              helperText="Introduzca aquí su email"
              label="Email"
              placeholder="email"
              name="email"
              autoComplete="off"
            />

            <Button
              type="submit"
              colorScheme="blue"
              size="lg"
              fontSize="md"
              disabled={
                !(
                  props.isValid &&
                  (props.dirty || Object.keys(props.touched).length)
                ) || props.isSubmitting
              }
            >
              {props.isSubmitting ? "Espere" : "Entrar"}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  ) : (
    <Box>
      <Text>
        Se ha mandado un email a la dirección indicada con las instrucciones
        para restablecer tu contraseña.
      </Text>
    </Box>
  );
};
