import React, { useEffect, useState } from "react";
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Tag } from "../../../api";
import { ApiClient } from "common";
import Select from "react-select";
import { CustomTagDataProps } from "./types";
import { CustomOption } from "./CustomOption";
import { CustomMultiValueLabel } from "./CustomMultiValueSelect";
import { AiFillTags } from "react-icons/ai";
import { TooltipTagAvatar } from "./TooltipTagAvatar";
import constants from "../../../constants";

interface ITagsSelectorProps {
  selectable: boolean;
  currentTags: Tag[];
  onBlur?: (tags: Tag[]) => void;
}

export const TagsSelector: React.FC<ITagsSelectorProps> = ({
  selectable,
  currentTags,
  onBlur,
}) => {
  const toast = useToast();

  const { data } = useQuery<Tag[]>(
    ["tags"],
    () => ApiClient.get<Tag[]>("tags"),
    {
      onError: () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  const [tagsData, setTagsData] = useState<CustomTagDataProps[]>([]);

  useEffect(() => {
    if (!data) return;

    setTagsData(
      data.map((item) => ({
        label: item.title,
        value: item.id,
        text_color: item.text_color,
        background_color: item.background_color,
      }))
    );
  }, [data]);

  const [currentTagsInternal, setCurrentTagsInternal] = useState<Tag[]>([]);

  useEffect(() => {
    setCurrentTagsInternal(currentTags);
  }, [currentTags]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const closeModal = () => {
    onClose();
    onBlur && onBlur(currentTagsInternal);
  };

  return (
    <div>
      <Modal isOpen={isOpen} onClose={closeModal} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Seleccione tags para el proyecto </ModalHeader>
          <ModalBody>
            <Select
              onChange={(values) => {
                const newTags = data?.filter((tag) =>
                  values.map((val) => val.value).includes(tag.id)
                );
                setCurrentTagsInternal(newTags || []);
              }}
              options={tagsData}
              placeholder="Seleccione..."
              isMulti={true}
              value={tagsData.filter((option) =>
                currentTagsInternal.map((tag) => tag.id).includes(option.value)
              )}
              styles={{
                menu: (provided) => {
                  return { ...provided, position: "static" };
                },
                multiValue: (provided) => {
                  return { ...provided, background: "none" };
                },
              }}
              hideSelectedOptions={false}
              menuIsOpen={true}
              autoFocus={true}
              minMenuHeight={500}
              components={{
                Option: CustomOption,
                MultiValueLabel: CustomMultiValueLabel,
                MultiValueRemove: () => <></>,
                NoOptionsMessage: () => (
                  <Text textAlign="center" textColor="gray.600">
                    No hay tags
                  </Text>
                ),
              }}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={closeModal}>
              Aceptar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <div
        onClick={() => {
          if (selectable) onOpen();
        }}
      >
        <HStack _hover={{ cursor: "pointer" }}>
          <Text fontSize="sm">Tags asignados:</Text>
          {currentTagsInternal.length > 0 && (
            <HStack>
              {currentTagsInternal.map((tag) => (
                <TooltipTagAvatar
                  key={tag.id}
                  name={data?.find((item) => item.id === tag.id)?.title}
                  bg={tag.background_color}
                  color={tag.text_color}
                  mr={1}
                  py={0.5}
                  px={2.5}
                  borderRadius="sm"
                  fontSize="sm"
                />
              ))}
            </HStack>
          )}

          <HStack
            bg="#efefef"
            color="black"
            mr={1}
            py={1}
            px={2.5}
            borderRadius="sm"
            fontSize="sm"
          >
            Agregar <AiFillTags size={15} />
          </HStack>
        </HStack>
      </div>
    </div>
  );
};
