import React from "react";
import { Comment } from "../../api";
import { Button, Stack, useToast } from "@chakra-ui/react";
import { createValidator } from "class-validator-formik";
import { Form, Formik, FormikHelpers } from "formik";
import { CommentCreateDto } from "../../dto/comment-create.dto";
import { CommentParentEnum } from "../../dto/types/comment.parent.type";
import { useMutation } from "@tanstack/react-query";
import { ApiClient } from "common";
import constants from "../../constants";
import { CustomTextAreaAutosize } from "../Forms/CustomTextAreaAutosize";

interface ICommentsCreateProps {
  onCreate: (item: Comment) => void;
  parentType: CommentParentEnum;
  parentId: number;
}

export const CommentsCreate: React.FC<ICommentsCreateProps> = ({
  onCreate,
  parentType,
  parentId,
}) => {
  const toast = useToast();

  const initialValues: CommentCreateDto = {
    text: "",
    parent_type: parentType,
    parent_id: parentId,
  };

  const mutation = useMutation<Comment, unknown, CommentCreateDto>(
    (d) => ApiClient.post("comments", JSON.stringify(d)),
    {
      onError: (error) => {
        console.error(error);
        toast({
          title: "Error al crear el comentario",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onSuccess: async (data) => {
        onCreate(data);
        toast({
          title: "Correcto!",
          description: "El comentario ha sido agregado correctamente",
          status: "success",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  const onSubmit = (
    values: CommentCreateDto,
    helper: FormikHelpers<CommentCreateDto>
  ) => {
    helper.resetForm();
    mutation.mutate(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={createValidator(CommentCreateDto)}
    >
      {(props) => (
        <Form>
          <Stack spacing="6">
            <CustomTextAreaAutosize
              label={false}
              placeholder="Introduce comentario"
              name="text"
              autoComplete="off"
              maxRows={6}
            />

            <Button
              colorScheme="blue"
              size="lg"
              fontSize="md"
              type="submit"
              disabled={
                !(
                  props.isValid &&
                  (props.dirty || Object.keys(props.touched).length)
                ) || mutation.isLoading
              }
            >
              Agregar Comentario
              {/*{mutationLoading ? 'Espere' : (isNewRecord ? 'Crear' : 'Guardar')}*/}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
