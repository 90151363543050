import constants from "../constants";

interface ILocalStorage {
  justSignedUpCompany: boolean;
}

const defaultValues: ILocalStorage = {
  justSignedUpCompany: false,
};

type LocalStorageProperties = keyof ILocalStorage;

/**
 * Inicializa los datos de localStorage
 */
export const initLocalStorageData = (): void => {
  const data = localStorage.getItem(constants.localStorageDataIndex);
  if (!data)
    localStorage.setItem(
      constants.localStorageDataIndex,
      JSON.stringify(defaultValues)
    );
};

/**
 * Devuelve todos los datos de localstorage
 */
export const getLocalStorageData = (): ILocalStorage => {
  const data = localStorage.getItem(constants.localStorageDataIndex);

  if (!data) return defaultValues;

  return JSON.parse(data) as ILocalStorage;
};

/**
 * Setea los datos en localStorage
 * @param data {ILocalStorage}
 */
export const setLocalStorageData = (data: ILocalStorage) => {
  localStorage.setItem(constants.localStorageDataIndex, JSON.stringify(data));
};

/**
 * Setea el valor de uan propiedad en localStorage.
 * Si el objeto no estuviera inicializado, se inicializará con defaultValues
 * @param property {string}
 * @param value {any}
 */
export const setLocalStorageValue = (
  property: LocalStorageProperties,
  value: any
) => {
  const data = { ...getLocalStorageData(), [property]: value };
  setLocalStorageData(data);
};

export const removeAllLocalStorageData = () => {
  localStorage.removeItem(constants.localStorageDataIndex);
};
