import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Text,
  Tooltip,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { TooltipTagAvatar } from '../../Tags/TagsSelector';
import { Project } from '../../../api';
import { ReportTaskListItem } from './ReportTaskListItem';
import { BsClipboardCheck } from 'react-icons/bs';
import constants from '../../../constants';
import { IoMdCheckmark } from 'react-icons/io';
import { TagList } from '../../Tags';

interface IProjectListProps {
  item: Project;
}

export const ReportProjectListItem: React.FC<IProjectListProps> = ({
  item,
}) => {
  const [totalTime, setTotalTime] = useState<number>(0);
  const toast = useToast();

  const handleCopy = async () => {
    const str = item.tasks.map((t) => t.title).join('\n');
    try {
      await navigator.clipboard.writeText(str);
      toast({
        title: 'Tareas copiadas al portapapeles',
        description: `Las tareas del proyecto ${item.name} han sido copiadas al portapapeles`,
        status: 'success',
        duration: constants.toastDuration,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: 'Error al copiar en el portapapeles',
        description: 'No se han podido copiar los datos en el portapapeles',
        status: 'error',
        duration: constants.toastDuration,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    let cont = 0;
    item.tasks.forEach((task) => {
      task.times.forEach((time) => {
        cont += time.total_time_segs;
      });
    });

    setTotalTime(cont);
  }, [item.tasks]);

  return (
    <Box mb={12}>
      <Heading size="md">
        <Flex mb={3} alignItems="center">
          {item.closed && (
            <Tooltip label="Este proyecto está cerrado">
              <VStack style={{ justifyContent: 'center' }} mr={2}>
                <Icon
                  w={5}
                  h={5}
                  as={IoMdCheckmark}
                  _hover={{
                    cursor: 'pointer',
                  }}
                />
              </VStack>
            </Tooltip>
          )}
          <Link to={`/projects/${item.id}`} target="_blank">
            {item.name}
          </Link>
          <TagList items={item.assigned_tags} />
          <Icon
            ml="auto"
            onClick={handleCopy}
            aria-label="copy"
            as={BsClipboardCheck}
            _hover={{ cursor: 'pointer' }}
          />
        </Flex>
      </Heading>
      <hr style={{ marginBottom: '15px' }} />
      <Grid
        templateColumns="repeat(6, 1fr)"
        gap={3}
        mb={2}
        backgroundColor="gray.50"
        borderBottom={'1px solid'}
        borderBottomColor="gray.200"
        p={1}
      >
        <GridItem colSpan={3}>Tarea</GridItem>
        <GridItem>Fecha de inicio</GridItem>
        <GridItem>Fecha de fin</GridItem>
        <GridItem>Tiempo trabajado</GridItem>
      </Grid>
      {item.tasks.map((t, index) => (
        <ReportTaskListItem item={t} index={index} key={`task-${t.id}`} />
      ))}

      <Box backgroundColor={'gray.100'} p={2}>
        <Text mt={1}>
          Tiempo total trabajado en {item.name}:{' '}
          <strong>{`${Math.floor(totalTime / 60)} min ${
            totalTime % 60
          } seg`}</strong>
        </Text>
      </Box>
    </Box>
  );
};
