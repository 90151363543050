import React from "react";
import { Heading, HStack, useToast } from "@chakra-ui/react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Routes } from "../../routes";
import { CustomerCreateDto } from "../../dto/customer-create.dto";
import { useMutation } from "@tanstack/react-query";
import { Customer } from "../../api";
import { ApiClient } from "common";
import CustomersForm from "./CustomersForm";
import { OutletContext } from "../../types/outlet.context";
import { Modal, ModalBody, ModalHeader } from "../Modal";
import { Helmet } from "react-helmet";
import { ModalColumn } from "../Modal/ModalColumn";
import constants from "../../constants";

const initialValues: CustomerCreateDto = {
  name: "",
  document: "",
  address: "",
  postal_code: "",
  city: "",
  state: "",
  country: "",
  phone1: "",
  phone2: "",
};
export const CustomersCreate = () => {
  const backTo = Routes.CUSTOMERS;
  const navigate = useNavigate();
  const toast = useToast();

  const { onCreate } = useOutletContext<OutletContext<Customer>>();

  const mutation = useMutation<Customer, unknown, CustomerCreateDto>(
    (d) => ApiClient.post("customers", JSON.stringify(d)),
    {
      onError: () => {
        toast({
          title: "Error accediendo al servidor",
          description:
            "Ha habido un error accediendo al servidor y los datos no se han guardado. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onSuccess: async (data) => {
        toast({
          title: "Correcto",
          description: "El cliente ha sido creado correctamente.",
          status: "success",
          duration: constants.toastDuration,
          isClosable: true,
        });

        onCreate(data);
        navigate(backTo);
      },
    }
  );

  const handleSubmit = (values: CustomerCreateDto) => {
    mutation.mutate(values);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Project15 - Clientes - Crear</title>
      </Helmet>
      <Modal>
        <ModalHeader onClose={() => navigate(backTo)}>
          <HStack spacing={2}>
            <h3>Crear Cliente</h3>
          </HStack>
        </ModalHeader>

        <ModalBody columns={1}>
          <ModalColumn>
            <Heading>Crear Cliente</Heading>
            <CustomersForm
              initialValues={initialValues}
              isNewRecord={true}
              dto={CustomerCreateDto}
              onSubmit={handleSubmit}
              mutationLoading={mutation.isLoading}
            />
          </ModalColumn>
        </ModalBody>
      </Modal>
    </>
  );
};
