import React from "react";
import { Heading, HStack, useToast } from "@chakra-ui/react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Routes } from "../../routes";
import { useMutation } from "@tanstack/react-query";
import { Product } from "../../api";
import { ApiClient } from "common";
import { OutletContext } from "../../types/outlet.context";
import { Modal, ModalBody, ModalHeader } from "../Modal";
import { ProductCreateDto } from "../../dto/product-create.dto";
import { ProductsForm } from "./ProductsForm";
import { Helmet } from "react-helmet";
import { ModalColumn } from "../Modal/ModalColumn";
import constants from "../../constants";

const initialValues: Partial<ProductCreateDto> = {
  description: "",
  name: "",
  price: 0,
};

// let initialValuesWithCustomer: ProjectCreateDto;

export const ProductsCreate = () => {
  const backTo = Routes.PRODUCTS;
  const navigate = useNavigate();
  const toast = useToast();

  const { onCreate } = useOutletContext<OutletContext<Product>>();

  const mutation = useMutation<Product, unknown, ProductCreateDto>(
    (d) => ApiClient.post("products", JSON.stringify(d)),
    {
      onError: () => {
        toast({
          title: "Error accediendo al servidor",
          description:
            "Ha habido un error accediendo al servidor y los datos no se han guardado. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onSuccess: async (data) => {
        toast({
          title: "Correcto",
          description: "El producto ha sido creado correctamente.",
          status: "success",
          duration: constants.toastDuration,
          isClosable: true,
        });

        onCreate(data);
        navigate(backTo);
      },
    }
  );

  const handleSubmit = (values: ProductCreateDto) => {
    mutation.mutate(values);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Project15 - Productos - Crear</title>
      </Helmet>

      <Modal>
        <ModalHeader onClose={() => navigate(backTo)}>
          <HStack spacing={2}>
            <h3>Crear Producto</h3>
          </HStack>
        </ModalHeader>

        <ModalBody columns={1}>
          <ModalColumn>
            <Heading>Crear Producto</Heading>
            <ProductsForm
              initialValues={initialValues}
              isNewRecord={true}
              dto={ProductCreateDto}
              onSubmit={handleSubmit}
              mutationLoading={mutation.isLoading}
            />
          </ModalColumn>
        </ModalBody>
      </Modal>
    </>
  );
};
