import React from "react";
import { Box, Tooltip } from "@chakra-ui/react";
import { CustomTagDataProps } from "./types";

interface ICustomMultiValueSelectProps {
  data: CustomTagDataProps;
}

export const CustomMultiValueLabel: React.FC<ICustomMultiValueSelectProps> = ({
  data,
}) => {
  return (
    <Tooltip label={data.label} placement="top">
      <Box
        bg={data.background_color}
        color={data.text_color}
        mr={1}
        py={0.5}
        px={2.5}
        borderRadius="sm"
        fontSize="sm"
      >
        {data.label}
      </Box>
    </Tooltip>
  );
};
