import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { createValidator } from "class-validator-formik";
import { Box, Button, Stack, useToast } from "@chakra-ui/react";
import { CustomTextArea, CustomTextInput } from "../Forms";
import { ExpenseCreateDto } from "../../dto/expense-create.dto";
import { useMutation } from "@tanstack/react-query";
import { Expense } from "../../api";
import { ApiClient } from "common";
import constants from "../../constants";

interface IExpenseAddProps {
  projectId: number;
  onCreate: (data: Expense) => void;
}

export const ExpenseAdd: React.FC<IExpenseAddProps> = ({
  projectId,
  onCreate,
}) => {
  const toast = useToast();
  const [formVisible, setFormVisible] = useState<boolean>(false);

  const initialValues: ExpenseCreateDto = {
    amount: 0,
    concept: "",
    description: "",
    projectId,
  };

  const mutation = useMutation<Expense, unknown, ExpenseCreateDto>(
    (d) => {
      if (d.date) d.date = new Date(d.date);
      return ApiClient.post("expenses", JSON.stringify(d));
    },
    {
      onSuccess: async (data) => {
        onCreate(data);
        toast({
          title: "Correcto",
          description: "El gasto ha sido agregado correctamente.",
          status: "success",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  const onSubmit = (
    values: ExpenseCreateDto,
    helper: FormikHelpers<ExpenseCreateDto>
  ) => {
    helper.resetForm();
    console.log(values);
    mutation.mutate(values);
  };

  return (
    <Box mt={4}>
      {formVisible ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={createValidator(ExpenseCreateDto)}
        >
          {(props) => (
            <Form>
              <Stack spacing="6">
                <CustomTextInput
                  label="Concepto"
                  placeholder="Introduce el concepto"
                  name="concept"
                  autoComplete="off"
                />

                <CustomTextInput
                  name="date"
                  type="datetime-local"
                  label="Fecha y hora del gasto"
                />

                <CustomTextArea
                  label="Descripción"
                  placeholder="Introduce una descripción"
                  name="description"
                  autoComplete="off"
                />

                <CustomTextInput
                  label="Total"
                  placeholder="Introduce el total"
                  name="amount"
                  type="number"
                  autoComplete="off"
                />

                <Button
                  colorScheme="blue"
                  size="lg"
                  fontSize="md"
                  type="submit"
                  disabled={
                    !(
                      props.isValid &&
                      (props.dirty || Object.keys(props.touched).length)
                    ) || mutation.isLoading
                  }
                >
                  Agregar Gasto
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      ) : (
        <Button
          variant="outline"
          colorScheme="blue"
          onClick={() => {
            setFormVisible(true);
          }}
        >
          Agregar Gasto
        </Button>
      )}
    </Box>
  );
};
