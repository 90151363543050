import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { Project } from '../../api';

interface IProjectAlertDialogDeleteProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: (item: Project) => void;
  item: Project;
}

export const ProjectAlertDialogDelete: React.FC<
  IProjectAlertDialogDeleteProps
> = ({ isOpen, onClose, onDelete, item }) => {
  const cancelRef = React.useRef(null);

  const hasTimes = item.timeTotal > 0;

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Borrar Proyecto
          </AlertDialogHeader>

          <AlertDialogBody>
            ¿Deseas eliminar el proyecto {item.name}? Esta acción no se puede
            deshacer.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                onDelete(item);
                onClose();
              }}
              ml={3}
            >
              Si, borrar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
