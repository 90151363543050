import React, { useEffect, useState } from "react";
import { AlertProps } from "@chakra-ui/alert/dist/declarations/src/alert";
import { Alert, AlertIcon, CloseButton } from "@chakra-ui/react";

interface ISmallAlert {
  visible: boolean;
}

/**
 * Wrapper del alert de cakra mostrando simplemente icono, texto y cerrar.
 * Ver las propiedades del alert para su configuración
 *
 * @example
 * <SmallAlert
 *  status="error"
 *  visible={true}
 *  px={23}
 *  my={4}
 * >
 *    El usuario o contraseña no es correcto.
 * </SmallAlert>
 *
 * @see https://chakra-ui.com/docs/feedback/alert
 */
export const SmallAlert: React.FC<AlertProps & ISmallAlert> = ({
  visible,
  children,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(visible);

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  if (!isVisible) return null;

  const handleClick = () => {
    setIsVisible(false);
  };

  return (
    <Alert {...props}>
      <AlertIcon />
      {children}
      <CloseButton
        position="absolute"
        right="8px"
        top="8px"
        onClick={handleClick}
      />
    </Alert>
  );
};
