import React from "react";
import { Avatar, Box, Tooltip } from "@chakra-ui/react";

export const TooltipTagAvatar: typeof Avatar = (props) => {
  return (
    <Tooltip label={props.name} placement="top">
      <Box
        bg={props.bg}
        color={props.color}
        borderRadius={props.borderRadius}
        fontSize={props.fontSize}
        py={props.py}
        px={props.px}
        mr={props.mr}
      >
        {props.name}
      </Box>
    </Tooltip>
  );
};
