import React from "react";
import { Payment } from "../../api";
import { Text } from "@chakra-ui/react";
import { PaymentListItem } from "./PaymentListItem";
import { PaymentAdd } from "./PaymentAdd";
import { PaymentsListItemHeader } from "./PaymentsListItemHeader";
import { PaymentsListItemSummary } from "./PaymentsListItemSummary";

interface IPaymentsWidgetProps {
  parentId: number;
  items: Payment[];
  onCreate: (data: Payment) => void;
  onUpdate: (data: Payment) => void;
  onDelete: (id: number) => void;
}

export const PaymentsWidget: React.FC<IPaymentsWidgetProps> = ({
  parentId,
  items,
  onCreate,
  onDelete,
  onUpdate,
}) => {
  return (
    <div>
      {items.length > 0 ? (
        <>
          <PaymentsListItemHeader />
          {items.map((i) => (
            <PaymentListItem
              key={i.id}
              item={i}
              onDelete={onDelete}
              onUpdate={onUpdate}
            />
          ))}
          <PaymentsListItemSummary items={items} />
        </>
      ) : (
        <Text>No hay cobros asignados</Text>
      )}

      <PaymentAdd projectId={parentId} onCreate={onCreate} />
    </div>
  );
};
