import React from "react";
import { FieldAttributes, useField, useFormikContext } from "formik";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Textarea,
} from "@chakra-ui/react";
import ResizeTextarea from "react-textarea-autosize";

interface CustomTextAreaAutosizeProps extends FieldAttributes<any> {
  helperText?: string;
  minRows?: number;
  maxRows?: number;
}

/**
 * Componente que pinta un textarea multiline automático que funciona en formik
 * Internamente usa react-textarea-autosize
 * @param label
 * @param helperText
 * @param ref
 * @param minRows
 * @param maxRows
 * @param minRows
 * @param maxRows
 * @param props
 * @see https://github.com/Andarist/react-textarea-autosize
 * @constructor
 */
export const CustomTextAreaAutosize: React.FC<CustomTextAreaAutosizeProps> = ({
  label,
  helperText,
  ref,
  minRows = 1,
  maxRows = 5,
  ...props
}) => {
  const restProps: FieldAttributes<any> = { ...props };
  const [field, meta] = useField(restProps);
  const isInvalid = meta.touched && meta.error !== undefined;
  const fc = useFormikContext();

  return (
    <FormControl isRequired={restProps.required} isInvalid={isInvalid}>
      <FormLabel aria-required={true} htmlFor={restProps.id || restProps.name}>
        {label}
      </FormLabel>
      <Textarea
        id={restProps.id || restProps.name}
        {...field}
        value={field.value || ""}
        ref={ref}
        minH="unset"
        minRows={minRows}
        maxRows={maxRows}
        as={ResizeTextarea}
        onKeyPress={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            e.stopPropagation();
            fc.submitForm();
            console.log("enviar formik");
          }
        }}
        {...restProps}
      />

      <FormErrorMessage>{meta.error}</FormErrorMessage>
      {!isInvalid && helperText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
