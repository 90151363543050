import { HStack } from '@chakra-ui/react';
import { TooltipTagAvatar } from './TagsSelector';
import { Tag } from '../../api';

interface TagListProps {
  items: Tag[];
}

/**
 * Este componente se usa para pintar un listado de tags asociados a
 * una entidad en concreto (proyecto, tarea, etc)
 *
 * Este componente no es el listado general de tags.
 *
 * @example <TagList items={item.assigned_tags} />
 * @param param0
 * @returns
 */
export const TagList = ({ items }: TagListProps) => {
  return (
    <>
      {items.length > 0 && (
        <HStack>
          {items.map((tat) => (
            <TooltipTagAvatar
              key={`tat-${tat.id}`}
              name={tat.title}
              bg={tat.background_color}
              color={tat.text_color}
              mr={1}
              py={0.5}
              px={2.5}
              borderRadius="sm"
              fontSize="sm"
            />
          ))}
        </HStack>
      )}
    </>
  );
};
