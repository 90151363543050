import React from "react";
import { Button, Icon, Text, useDisclosure } from "@chakra-ui/react";
import { BsFillPlayFill, BsStopFill } from "react-icons/bs";
import { Task } from "../../api";
import { Timer } from "./Timer";
import { useStartStopHook } from "./startStopHook";
import { StopConfirmDialog } from "./StopConfirmDialog";

interface IStartStopButtonProps {
  taskId: number;
}

export const StartStopButton: React.FC<IStartStopButtonProps> = ({
  taskId,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    startStop,
    isCurrentTask,
    workingQuery,
    stopCurrentTimeAndStartNewOne,
  } = useStartStopHook(
    taskId,
    () => {
      onOpen();
    },
    () => {
      onClose();
    }
  );

  const taskTitle = workingQuery.data?.task
    ? (workingQuery.data?.task as Task).title
    : "";

  return (
    <>
      <Button onClick={() => startStop()} size="xs">
        {workingQuery.isLoading ? (
          // todo
          <Text fontWeight="normal">Espere</Text>
        ) : (
          <>
            <Icon
              as={
                workingQuery.data?.working === true && isCurrentTask
                  ? BsStopFill
                  : BsFillPlayFill
              }
              data-cy={`start-stop-button_${
                workingQuery.data?.working === true && isCurrentTask
                  ? "stop"
                  : "start"
              }`}
              w={5}
              h={5}
            />

            {isCurrentTask && <Timer />}
          </>
        )}
      </Button>

      <StopConfirmDialog
        isOpen={isOpen}
        onClose={onClose}
        taskTitle={taskTitle}
        startHandler={stopCurrentTimeAndStartNewOne}
      />
    </>
  );
};
