import IUser from "../../types/user.interface";
import React, { createContext, useContext, useEffect, useState } from "react";
import constants from "../../constants";
import {
  changePasswordUser,
  checkUserByIdToken,
  login as loginUser,
  logout as logoutUser,
  me,
  recoverPasswordUser,
  refreshToken,
  signUpUser,
} from "../../services/user.service";
import { SignUpDto } from "../../dto/signup.dto";
import {
  removeAllLocalStorageData,
  setLocalStorageValue,
} from "../../services/local.storage";
import { clearAllStatePersist } from "../../hooks";

const REFRESH_TOKEN_INTERVAL = 14 * 60 * 1000;

interface AuthContextType {
  loading: boolean;
  user?: IUser;
  setUser: (user: IUser) => void;
  access_token: string;
  setAccessToken: (at: string) => void;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => void;
  isUserAuthenticated: () => boolean;
  signUp: (data: SignUpDto) => Promise<boolean>;
  recoverPassword: (email: string) => Promise<boolean>;
  checkUserByToken: (id: number, token: string) => Promise<boolean>;
  changePassword: (
    id: number,
    token: string,
    password: string
  ) => Promise<boolean>;
}

const l = (...args: any) => {
  // return null;
  console.log(args);
};
const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider: React.FC = (props) => {
  const [user, setUser] = useState<IUser>();
  const [loading, setLoading] = useState(true);
  const [access_token, setAccess_token] = useState("");

  const login = async (email: string, password: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const response = await loginUser(email, password);
          if (response) {
            setAccess_token(response.access_token);
            resolve(true);
          } else {
            setAccess_token("");
            reject(false);
          }
        } catch (e) {
          reject("network error");
        }
      })();
    });
  };

  const recoverPassword = async (email: string): Promise<boolean> =>
    new Promise(async (resolve, reject) => {
      const res = await recoverPasswordUser(email);
      if (res) resolve(true);
      else reject(false);
    });

  const checkUserByToken = async (
    id: number,
    token: string
  ): Promise<boolean> =>
    new Promise(async (resolve, reject) => {
      const res = await checkUserByIdToken(id, token);
      if (res) resolve(true);
      else reject(false);
    });

  const changePassword = async (
    id: number,
    token: string,
    password: string
  ): Promise<boolean> =>
    new Promise(async (resolve, reject) => {
      const res = await changePasswordUser(id, token, password);
      if (res) resolve(true);
      else reject(false);
    });

  const signUp = async (data: SignUpDto): Promise<boolean> =>
    new Promise(async (resolve, reject) => {
      const res = await signUpUser(data);
      if (res) {
        setLocalStorageValue("justSignedUpCompany", true);
        setAccess_token(res.access_token);
        resolve(true);
      } else reject(false);
    });

  const logout = async () => {
    const res = await logoutUser();
    if (res) {
      removeAllLocalStorageData();
      setUser(undefined);
      setAccess_token("");
      clearAllStatePersist();
    } else {
      // ha habido un problema al hacer logout
    }
  };

  const isUserAuthenticated = () => {
    return user !== undefined;
  };

  /**
   * Si tenemos usuario en local storage, refrescamos su token
   * básicamente para generar un at y poder hacer peticiones.
   * TODO: implementar offline ( currentLocalStorageUser y refreshToken error)
   */
  useEffect(() => {
    l("useEffect general");
    const currentLocalStorageUser = localStorage.getItem(
      constants.localStorageCurrentUser
    );
    if (!currentLocalStorageUser) {
      l("Sin usuario en local storage");
      setLoading(false);
      return;
    }

    l("hay usuario en local storage", currentLocalStorageUser);

    setTimeout(() => {
      refreshToken().then((data) => {
        if (data) {
          setAccess_token(data.access_token);
        } else {
          setUser(undefined);
          setAccess_token("");
          setLoading(false);
        }
      });
    }, 100);
  }, []);

  useEffect(() => {
    l("useEffect access token", access_token);
    if (!access_token?.length) return;
    l("actualizando intervalo refreshTokenTimer");
    const refreshTokenTimer = setInterval(() => {
      l("refreshTokenTimer ejecutado");
      refreshToken().then((data) => {
        if (data) {
          setAccess_token(data.access_token);
        } else {
          setLoading(false);
          setUser(undefined);
          setAccess_token("");
        }
      });
    }, REFRESH_TOKEN_INTERVAL);
    l("use effect me");
    me(access_token).then((user) => {
      l("use effect me then", user);
      setUser(user);
      setLoading(false);
    });

    return () => {
      l("limpiando intervalo refreshTokenTimer");
      clearInterval(refreshTokenTimer);
    };
  }, [access_token]);

  useEffect(() => {
    l("use effect user", user);
    if (user) {
      l("guardando usuario en local storage");
      localStorage.setItem(
        constants.localStorageCurrentUser,
        JSON.stringify(user)
      );
    }
  }, [user]);

  if (loading) return <p>Cargando, espere...</p>;

  return (
    <AuthContext.Provider
      value={{
        loading,
        access_token,
        setAccessToken: setAccess_token,
        user,
        setUser,
        signUp,
        login,
        logout,
        isUserAuthenticated,
        recoverPassword,
        checkUserByToken,
        changePassword,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}
