import React from "react";
import { Heading, HStack, useToast } from "@chakra-ui/react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Routes } from "../../routes";
import { TagCreateDto } from "../../dto/tag-create.dto";
import { useMutation } from "@tanstack/react-query";
import { Tag } from "../../api";
import { ApiClient } from "common";
import TagsForm from "./TagsForm";
import { OutletContext } from "../../types/outlet.context";
import { Modal, ModalBody, ModalHeader } from "../Modal";
import { ModalColumn } from "../Modal/ModalColumn";
import constants from "../../constants";

const initialValues: TagCreateDto = {
  title: "",
  background_color: "",
  text_color: "",
};
export const TagsCreate = () => {
  const backTo = Routes.TAGS;
  const navigate = useNavigate();
  const toast = useToast();

  const { onCreate } = useOutletContext<OutletContext<Tag>>();

  const mutation = useMutation<Tag, unknown, TagCreateDto>(
    (d) => ApiClient.post("tags", JSON.stringify(d)),
    {
      onError: () => {
        toast({
          title: "Error accediendo al servidor",
          description:
            "Ha habido un error accediendo al servidor y los datos no se han guardado. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onSuccess: async (data) => {
        toast({
          title: "Correcto",
          description: "El tag ha sido creado correctamente.",
          status: "success",
          duration: constants.toastDuration,
          isClosable: true,
        });
        onCreate(data);
        navigate(backTo);
      },
    }
  );

  const handleSubmit = (values: TagCreateDto) => {
    mutation.mutate(values);
  };

  return (
    <Modal>
      <ModalHeader onClose={() => navigate(backTo)}>
        <HStack spacing={2}>
          <h3>Crear Tag</h3>
        </HStack>
      </ModalHeader>

      <ModalBody columns={1}>
        <ModalColumn>
          <Heading>Crear Tag</Heading>
          <TagsForm
            initialValues={initialValues}
            isNewRecord={true}
            dto={TagCreateDto}
            onSubmit={handleSubmit}
            mutationLoading={mutation.isLoading}
          />
        </ModalColumn>
      </ModalBody>
    </Modal>
  );
};
