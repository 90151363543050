export const isVisibleInViewport = function (
  ele: HTMLDivElement,
  container: HTMLDivElement
): boolean {
  const { bottom, height, top } = ele.getBoundingClientRect();
  // const containerRect = container.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  return top <= containerRect.top
    ? containerRect.top - top <= height
    : bottom - containerRect.bottom <= height;
};
