import React from "react";
import { Project, Tag } from "../../api";
import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { FiMoreHorizontal } from "react-icons/fi";
import { FaEdit, FaTrash } from "react-icons/fa";
import ListItemWrapper from "../Interface/ListItemWrapper";
import { UsersAvatars } from "../Users";
import { IoMdCheckmark } from "react-icons/io";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ApiClient } from "common";
import { ProjectUpdateDto } from "../../dto/project-update.dto";
import constants from "../../constants";
import { ProjectAlertDialogClose } from "./ProjectAlertDialogClose";
import { ProjectAlertDialogDelete } from "./ProjectAlertDialogDelete";
import { ProjectAlertDialogReopen } from "./ProjectAlertDialogReopen";
import { TagList } from "../Tags";

interface ProjectListItemProps {
  item: Project;
  onDelete: (item: Project) => void;
  extraButtons?: (item: Project) => JSX.Element;
  queryKey: string;
}

export const ProjectsListItem: React.FC<ProjectListItemProps> = ({
  item,
  onDelete,
  extraButtons,
  queryKey,
}) => {
  const navigate = useNavigate();

  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();

  const {
    isOpen: isOpenClose,
    onOpen: onOpenClose,
    onClose: onCloseClose,
  } = useDisclosure();

  const {
    isOpen: isOpenReopen,
    onOpen: onOpenReopen,
    onClose: onCloseReopen,
  } = useDisclosure();

  const detailUrl = `/projects/${item.id}`;
  const updateUrl = `/projects/update/${item.id}`;

  const queryClient = useQueryClient();
  const toast = useToast();

  const mutation = useMutation<Project, unknown, ProjectUpdateDto>(
    (d) => ApiClient.patch<Project>(`projects/${item.id}`, JSON.stringify(d)),
    {
      onError: () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onSuccess: async (data) => {
        console.log("refetch query");
        console.log(data);
        await queryClient.refetchQueries([queryKey]);

        toast({
          title: data.closed ? "Proyecto cerrado" : "Proyecto abierto",
          description: data.closed
            ? `El proyecto ${data.name} se ha cerrado correctamente`
            : `El proyecto ${data.name} se ha vuelto a abrir correctamente`,
          status: "success",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  const closeProject = () => {
    const project: ProjectUpdateDto = { ...item, closed: true };
    mutation.mutate(project);
  };

  const reopenProject = () => {
    const project: ProjectUpdateDto = { ...item, closed: false };
    mutation.mutate(project);
  };

  return (
    <ListItemWrapper key={item.id}>
      <Box flexGrow={1}>
        <Text>
          <Link to={detailUrl}>{item.name}</Link>
        </Text>
        <TagList items={item.assigned_tags} />
      </Box>

      <Flex alignItems="center" border={1}>
        <Box>
          <UsersAvatars
            selectable={false}
            currentUsers={item.assigned_users}
            showLabel={false}
          />
        </Box>
        <Box ml={4}>
          <Text>{item.customer.name}</Text>
        </Box>
      </Flex>

      <Box width={14} ml={2}>
        <Menu placement="bottom-end">
          <MenuButton
            as={IconButton}
            aria-label="Ordenar"
            size="xs"
            variant="ghost"
            icon={<FiMoreHorizontal />}
          />
          <MenuList alignItems="flex-end">
            <MenuItem
              icon={<IoMdCheckmark />}
              onClick={() => (item.closed ? onOpenReopen() : onOpenClose())}
            >
              {item.closed ? "Volver a Abrir" : "Cerrar"}
            </MenuItem>

            <MenuItem icon={<FaEdit />} onClick={() => navigate(updateUrl)}>
              Modificar
            </MenuItem>

            <MenuItem icon={<FaTrash />} onClick={onOpenDelete}>
              Borrar
            </MenuItem>
            {extraButtons && extraButtons(item)}
          </MenuList>
        </Menu>
      </Box>

      <ProjectAlertDialogReopen
        isOpen={isOpenReopen}
        onClose={onCloseReopen}
        reopenProject={reopenProject}
        item={item}
      />

      <ProjectAlertDialogClose
        isOpen={isOpenClose}
        onClose={onCloseClose}
        closeProject={closeProject}
        item={item}
      />

      <ProjectAlertDialogDelete
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        onDelete={onDelete}
        item={item}
      />
    </ListItemWrapper>
  );
};
