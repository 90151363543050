import React from "react";
import { FieldAttributes, useField } from "formik";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";

interface ICustomColorInputProps extends FieldAttributes<any> {
  helperText?: string;
}

export const CustomColorInput: React.FC<ICustomColorInputProps> = ({
  label,
  helperText,
  ...props
}) => {
  const restProps: FieldAttributes<any> = { ...props };
  const [field, meta] = useField(restProps);

  const isInvalid = meta.touched && meta.error !== undefined;

  return (
    <FormControl isRequired={restProps.required} isInvalid={isInvalid}>
      <FormLabel aria-required={true} htmlFor={restProps.id || restProps.name}>
        {label}
      </FormLabel>

      <input
        {...field}
        {...restProps}
        id={restProps.id || restProps.name}
        type="color"
      />

      <FormErrorMessage>{meta.error}</FormErrorMessage>

      {!isInvalid && helperText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
