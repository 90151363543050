import { CloseButton, HStack, useColorModeValue } from "@chakra-ui/react";
import React from "react";

export const ModalHeader: React.FC<{ onClose: () => void }> = ({
  onClose,
  children,
}) => (
  <HStack
    flexShrink={0}
    height={10}
    backgroundColor={useColorModeValue("gray.200", "gray.600")}
    borderBottomWidth="1px"
    borderBottomColor={useColorModeValue("gray.300", "gray.600")}
    justifyContent="space-between"
    px={4}
  >
    <div>{children}</div>

    <CloseButton onClick={onClose} size="lg" backgroundColor="transparent" />
  </HStack>
);
