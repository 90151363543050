"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatSeconds = void 0;
const luxon_1 = require("luxon");
const formatSeconds = (seconds) => {
    var _a;
    const totalSecondsDateTime = luxon_1.DateTime.fromSeconds(seconds);
    const totalSecondsDateTimeObject = totalSecondsDateTime.toObject();
    return Math.floor((_a = luxon_1.Duration.fromObject({
        hours: totalSecondsDateTimeObject.hour - 1,
        minute: totalSecondsDateTimeObject.minute,
        second: totalSecondsDateTimeObject.second,
        days: totalSecondsDateTimeObject.day - 1,
        month: totalSecondsDateTime.month - 1,
    }).shiftTo('hours').toObject().hours) !== null && _a !== void 0 ? _a : 0).toString().padStart(2, '0') + ':' +
        totalSecondsDateTime.toFormat('mm:ss');
};
exports.formatSeconds = formatSeconds;
