import React from "react";
import { Logo } from "../components/Logo";
import {
  Box,
  Button,
  Heading,
  SimpleGrid,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from "@chakra-ui/react";
import { DividerWithText } from "../components/DividirWithText";
import { FaFacebook, FaGithub, FaGoogle } from "react-icons/fa";
import { Card } from "../components/Card";
import { RegisterForm } from "../components/Forms/RegisterForm";
import { Link } from "react-router-dom";
import { Routes } from "../routes";
import { Helmet } from "react-helmet";

export const RegisterPage = () => {
  const boxBG = useColorModeValue("gray.50", "inherit");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Project15 - Regístrate</title>
      </Helmet>

      <Box bg={boxBG} minH="100vh" py="12" px={{ base: "4", lg: "8" }}>
        <Box maxW="md" mx="auto">
          <Logo mx="auto" h="16" mb={{ base: "10", md: "20" }} />

          <Heading textAlign="center" size="xl" fontWeight="extrabold">
            Regístrate
          </Heading>

          <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
            <Text as="span">¿Ya eres usuario?</Text>
            <Link to={Routes.USER_LOGIN}>Identifícate</Link>
          </Text>

          <Card>
            <RegisterForm />
            <DividerWithText mt="6">o regístrate con</DividerWithText>
            <SimpleGrid mt="6" columns={3} spacing="3">
              <Button color="currentColor" variant="outline">
                <VisuallyHidden>Entrar con Facebook</VisuallyHidden>
                <FaFacebook />
              </Button>
              <Button color="currentColor" variant="outline">
                <VisuallyHidden>Entrar con Google</VisuallyHidden>
                <FaGoogle />
              </Button>
              <Button color="currentColor" variant="outline">
                <VisuallyHidden>Entrar con Github</VisuallyHidden>
                <FaGithub />
              </Button>
            </SimpleGrid>
          </Card>
        </Box>
      </Box>
    </>
  );
};
