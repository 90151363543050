import {
  IsNotEmpty,
  IsNumber,
  IsOptional,
  MaxLength,
  MinLength,
  ValidateIf,
} from 'class-validator';

export class ProjectCreateDto {
  @MinLength(3)
  @MaxLength(255)
  @IsNotEmpty()
  
  name: string;

  @MaxLength(255)
  
  description: string;

  @IsNotEmpty()
  
  customerId: number;

  
  @IsNumber()
  @IsOptional()
  @ValidateIf((o) => o.amount !== '')
  amount?: number;
}
