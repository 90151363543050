import React from "react";
import { createValidator } from "class-validator-formik";
import { Form, Formik } from "formik";
import { Button, Stack } from "@chakra-ui/react";
import { CustomTextInput } from "../Forms";
import { ProductCreateDto } from "../../dto/product-create.dto";
import { ProductUpdateDto } from "../../dto/product-update.dto";

interface ProductFormProps {
  initialValues: Partial<ProductCreateDto> | ProductUpdateDto;
  isNewRecord: boolean;
  dto: any;
  onSubmit: (values: ProductCreateDto | ProductUpdateDto) => void;
  mutationLoading: boolean;
}

export const ProductsForm: React.FC<ProductFormProps> = ({
  initialValues,
  isNewRecord,
  dto,
  onSubmit,
  mutationLoading,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => onSubmit(values as unknown as typeof dto)}
      validate={createValidator(dto)}
    >
      {({ isValid }) => (
        <Form>
          <Stack spacing="6">
            <CustomTextInput
              label="Nombre"
              placeholder="Nombre del producto"
              name="name"
              autoComplete="off"
            />
            <CustomTextInput
              label="Descripción"
              placeholder="Descripción del producto"
              name="description"
              autoComplete="off"
            />

            <CustomTextInput
              label="Precio"
              placeholder="Precio del producto"
              name="price"
              type="number"
              step="any"
              autoComplete="off"
            />

            <Button
              colorScheme="blue"
              size="lg"
              fontSize="md"
              type="submit"
              disabled={!isValid || mutationLoading}
            >
              {mutationLoading ? "Espere" : isNewRecord ? "Crear" : "Guardar"}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
