import React, { useEffect, useState } from "react";
import { ApiClient } from "common";

export const TestPage = () => {
  const [url, setUrl] = useState("");
  const [cont, setCont] = useState(0);

  const downloadPdf = async () => {
    const res = await ApiClient.get(`projects/21/pdf`);

    // @ts-ignore
    const blob = await res.blob();
    const newBlob = new Blob([blob], { type: "application/pdf" });

    return window.URL.createObjectURL(newBlob);
  };

  useEffect(() => {
    downloadPdf().then((r) => setUrl(r));
    const test = true;
    setTimeout(function () {
      test && setCont(cont + 1);
    }, 6000);
  }, [cont]);

  return <iframe src={url} width="100%" height="1200px" title="PDF Viewer" />;
};
