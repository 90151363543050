import React, { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  useToast,
} from "@chakra-ui/react";
import { PaginatedResponse } from "../services/interfaces/ApiResponse";
import { ApiClient } from "common";
import { QuerySortOperator, RequestQueryBuilder } from "@nestjsx/crud-request";
import { InputFilterDebounced } from "../components/Interface/InputFilterDebounced";
import { BiFilter } from "react-icons/bi";
import LoadMoreButton from "../components/LoadMoreButton/LoadMoreButton";
import { Product } from "../api";
import { Can } from "../context/can";
import { ProductsListItem } from "../components/Products";
import { BsThreeDots } from "react-icons/bs";
import { CSVLink } from "react-csv";
import { getCurrentDateWithHours } from "../util";
import ListItemHeader from "../components/Interface/ListItemHeader";
import ContentBox from "../components/Layout/ContentBox";
import useSetBreadcrumbs from "../components/BreadCrumbsHeader/useSetBreadcrumbs";
import { Helmet } from "react-helmet";
import { useStatePersist } from "../hooks";
import constants from "../constants";

const breadCrumbs = [
  {
    to: "/",
    label: "Home",
  },
  {
    label: "Productos",
  },
];
export const ProductsPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const loadMoreRef = useRef(null);
  const [search, setSearch] = useStatePersist("ProductsPageSearch", "");
  const [order, setOrder] = useState<QuerySortOperator>("ASC");
  const [orderBy, setOrderBy] = useState("");
  const toast = useToast();

  useSetBreadcrumbs(breadCrumbs);

  const queryKey = "infsc_products";
  const requestQueryBuilder = new RequestQueryBuilder();
  requestQueryBuilder
    .setLimit(40)
    .setPage(1)
    .search({
      $or: [
        {
          name: {
            $cont: search,
          },
        },
        {
          description: {
            $cont: search,
          },
        },
      ],
    });

  if (orderBy.length) {
    requestQueryBuilder.sortBy({
      field: orderBy,
      order,
    });
  }

  const ep = `products`;

  const {
    data,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<PaginatedResponse<Product[]>, Error>(
    [queryKey, search, order, orderBy],
    async ({ pageParam = 1 }) => {
      requestQueryBuilder.setPage(pageParam);
      return await ApiClient.get<PaginatedResponse<Product[]>>(
        ep,
        requestQueryBuilder
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.page < lastPage.pageCount
          ? lastPage.page + 1
          : undefined;
      },
      onError: () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  const onCreate = (newItem: Product) => {
    queryClient.setQueriesData([queryKey], (oldData: any) => {
      const newData = { ...oldData };
      newData.pages[0].data = [newItem].concat(data?.pages[0].data || []);
      return newData;
    });
  };

  const onUpdate = (id: number, data: Product) => {
    queryClient.setQueriesData([queryKey], (oldData: any) => {
      const newPages = oldData.pages.map((group: any) => {
        return {
          ...group,
          data: group.data.map((item: any) => {
            if (item.id === id) return data;
            return item;
          }),
        };
      });

      return {
        pages: newPages,
        pageParams: oldData.pageParams,
      };
    });

    queryClient.setQueryData(["products", id.toString()], () => data);
  };

  const onDelete = (id: number) => {
    queryClient.setQueriesData([queryKey], (oldData: any) => {
      const newPages = oldData.pages.map((group: any) => {
        return {
          ...group,
          data: group.data.filter((item: any) => item.id !== id),
        };
      });

      return {
        pages: newPages,
        pageParams: oldData.pageParams,
      };
    });
  };

  const deleteMutation = useMutation(
    (id: number) => ApiClient.delete(`${ep}/${id}`),
    {
      onSuccess: async (data, variables, context) => {
        toast({
          title: "Correcto",
          description: "El producto ha sido eliminado correctamente.",
          status: "success",
          duration: constants.toastDuration,
          isClosable: true,
        });

        onDelete(variables as unknown as number);
      },
      onError: () => {
        toast({
          title: "Error accediendo al servidor",
          description:
            "Ha habido un error accediendo al servidor y los datos no se han guardado. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  const handleDeleteItem = async (item: Product) => {
    deleteMutation.mutate(item.id);
  };

  const [csvData, setCsvData] = useState<any[]>([]);
  useEffect(() => {
    if (!data) {
      setCsvData([]);
      return;
    }

    const headers = ["id", "nombre", "descripción", "precio"];
    const newCSVData = data.pages
      .map((page) =>
        page.data.map((item) => [
          item.id,
          item.name,
          item.description,
          item.price,
        ])
      )
      .flat();
    setCsvData([headers, ...newCSVData]);
  }, [data]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Project15 - Productos</title>
      </Helmet>

      <ContentBox>
        <Flex>
          <HStack>
            <InputFilterDebounced
              onChangeDebounced={(d) => setSearch(d)}
              value={search}
            />
            <Menu closeOnSelect={false}>
              <MenuButton
                as={IconButton}
                aria-label="Ordenar"
                variant="ghost"
                icon={<BiFilter />}
              />
              <MenuList minWidth="240px">
                <MenuOptionGroup
                  onChange={(v) => setOrder(v.toString() as QuerySortOperator)}
                  value={order}
                  title="Ordenar"
                  type="radio"
                >
                  <MenuItemOption command="sa" value="ASC">
                    Ascendente
                  </MenuItemOption>
                  <MenuItemOption command="sd" value="DESC">
                    Descendente
                  </MenuItemOption>
                </MenuOptionGroup>
                <MenuDivider />
                <MenuOptionGroup
                  onChange={(v) => setOrderBy(v.toString())}
                  value={orderBy}
                  title="Ordenar por"
                  type="radio"
                >
                  <MenuItemOption value="name">Nombre</MenuItemOption>
                  <MenuItemOption value="description">
                    Descripción
                  </MenuItemOption>
                  <MenuItemOption value="price">Precio</MenuItemOption>
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          </HStack>

          <Box ml="auto">
            <Can I="create" a="Product">
              <Button
                size="sm"
                variant="ghost"
                onClick={() => navigate("create")}
              >
                Crear
              </Button>
            </Can>

            <Menu closeOnSelect={true}>
              <MenuButton
                as={IconButton}
                aria-label="Acciones"
                variant="ghost"
                icon={<BsThreeDots />}
              />
              <MenuList minWidth="240px">
                <MenuItemOption>
                  <CSVLink
                    filename={`Productos ${getCurrentDateWithHours()}`}
                    data={csvData}
                    target="_blank"
                  >
                    Exportar datos en CSV
                  </CSVLink>
                </MenuItemOption>
              </MenuList>
            </Menu>
          </Box>
        </Flex>
      </ContentBox>

      <ContentBox>
        <Box>
          {isError ? (
            <p>Ha habido un error cargando los datos</p>
          ) : isLoading ? (
            <p>cargando</p>
          ) : data?.pages[0].data.length === 0 ? (
            <p>No hay resultados.</p>
          ) : (
            <>
              <ListItemHeader columns={["Nombre", "Descripción", "Precio"]} />

              {data?.pages.map((group, i) => (
                <React.Fragment key={i}>
                  {group.data.map((item) => (
                    <ProductsListItem
                      item={item}
                      key={item.id}
                      onDelete={handleDeleteItem}
                    />
                  ))}
                </React.Fragment>
              ))}
            </>
          )}

          <LoadMoreButton
            loadMoreRef={loadMoreRef}
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
          />

          <Outlet
            context={{
              onUpdate,
              onCreate,
            }}
          />
        </Box>
      </ContentBox>
    </>
  );

  // return <MainBox>
  //     <HStack justifyContent="space-between">
  //         <Heading>Productos</Heading>
  //         <Can I="create" a="Product">
  //             <Button
  //                 size="sm"
  //                 variant="ghost"
  //                 onClick={() => navigate('create')}
  //             >
  //                 Crear
  //             </Button>
  //         </Can>
  //     </HStack>
  //
  //     <HStack justifyContent="space-between">
  //         <Box>
  //             <InputFilterDebounced onChangeDebounced={d => setSearch(d)}/>
  //             <Menu closeOnSelect={false}>
  //                 <MenuButton as={IconButton} aria-label="Ordenar" variant="ghost" icon={<BiFilter />} />
  //                 <MenuList minWidth='240px'>
  //                     <MenuOptionGroup onChange={(v) => setOrder(v.toString() as QuerySortOperator)} value={order} title='Ordenar' type='radio'>
  //                         <MenuItemOption command="sa" value='ASC'>Ascendente</MenuItemOption>
  //                         <MenuItemOption command="sd" value='DESC'>Descendente</MenuItemOption>
  //                     </MenuOptionGroup>
  //                     <MenuDivider />
  //                     <MenuOptionGroup onChange={(v) => setOrderBy(v.toString())} value={orderBy} title='Ordenar por' type='radio'>
  //                         <MenuItemOption value='name'>Nombre</MenuItemOption>
  //                         <MenuItemOption value='address'>Descripción</MenuItemOption>
  //                     </MenuOptionGroup>
  //                 </MenuList>
  //             </Menu>
  //         </Box>
  //
  //         <Box ml="auto">
  //             <Menu closeOnSelect={true}>
  //                 <MenuButton as={IconButton} aria-label="Acciones" variant="ghost" icon={<BsThreeDots />} />
  //                 <MenuList minWidth='240px'>
  //                     <MenuItemOption> <CSVLink filename={`Productos ${getCurrentDateWithHours()}`} data={csvData} target="_blank">Exportar datos en CSV</CSVLink> </MenuItemOption>
  //                 </MenuList>
  //             </Menu>
  //         </Box>
  //
  //     </HStack>
  //
  //     {isError ? (
  //             <p>Ha habido un error cargando los datos</p>
  //         ) :
  //         isLoading ? (
  //             <p>cargando</p>
  //         ) : data?.pages[0].data.length === 0 ? (
  //             <p>No hay resultados.</p>
  //         ) : (
  //             <>
  //                 <ListItemHeader columns={['Nombre', 'Descripción', 'Precio']}/>
  //
  //                 {data?.pages.map((group, i) => (
  //                     <React.Fragment key={i}>
  //                         {group.data.map(item => (
  //                             <ProductsListItem
  //                                 item={item}
  //                                 key={item.id}
  //                                 onDelete={handleDeleteItem}
  //                             />
  //                         ))}
  //                     </React.Fragment>
  //                 ))}
  //             </>
  //         )
  //     }
  //
  //     <LoadMoreButton
  //         loadMoreRef={loadMoreRef}
  //         onClick={() => fetchNextPage()}
  //         disabled={!hasNextPage || isFetchingNextPage}
  //         isFetchingNextPage={isFetchingNextPage}
  //         hasNextPage={hasNextPage}
  //     />
  //
  //     <Outlet context={{
  //         onUpdate,
  //         onCreate,
  //     }}/>
  // </MainBox>
};
