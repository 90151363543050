import React from 'react';
import { Task } from '../../../api';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { StartStopButton } from '../../StartStop';
import { TagList } from '../../Tags';

interface IAssignedTasksItemProps {
  item: Task;
}

export const AssignedTasksItem: React.FC<IAssignedTasksItemProps> = ({
  item,
}) => {
  return (
    <Box mb={2} pb={1} borderBottomWidth={1} _last={{ marginBottom: 0 }}>
      <HStack>
        <VStack flexGrow={1} border={1} alignItems="flex-start">
          {item.project && <Text size="sm">{item.project.name}</Text>}
          <Text fontWeight={600}>{item.title}</Text>
        </VStack>
        <TagList items={item.assigned_tags} />

        <StartStopButton taskId={item.id} />
      </HStack>
    </Box>
  );
};
