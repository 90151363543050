import React, { useEffect, useRef } from "react";
import { BuscadorTareaItem } from "./BuscadorTareaItem";
import { Box, Flex, IconButton, Spacer, Text } from "@chakra-ui/react";
import { FlattenedObject } from "./BuscadorVisible";
import { isVisibleInViewport } from "../../util";
import { useNavigate } from "react-router-dom";
import {
  SearchByNameResultDto,
  SearchProjectByNameResult,
} from "../../dto/search-by-name-result.dto";
import { AiOutlineEye } from "react-icons/ai";
import { MdOutlinePlaylistAdd } from "react-icons/md";

interface IBuscadorProyectoItem {
  item: SearchProjectByNameResult;
  cliente: SearchByNameResultDto;
  currentItem: FlattenedObject;
  setCurrentIndexById: (id: number, type: string) => void;
  setIsCreatingNewTask: (v: boolean) => void;
  currentArrowDirection: string;
  wrapperRef: React.RefObject<HTMLDivElement>;
  isActive: boolean;
  onClose: () => void;
}

export const BuscadorProyectoItem: React.FC<IBuscadorProyectoItem> = (
  props
) => {
  const {
    item,
    cliente,
    currentItem,
    setCurrentIndexById,
    currentArrowDirection,
    wrapperRef,
    isActive,
    onClose,
    setIsCreatingNewTask,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const styles = isActive
    ? {
        boxShadow: "md",
        backgroundColor: "gray.100",
        zIndex: 1,
      }
    : {
        backgroundColor: "white",
      };

  useEffect(() => {
    if (!ref.current || !wrapperRef.current) return;

    if (isActive && !isVisibleInViewport(ref.current, wrapperRef.current)) {
      ref.current?.scrollIntoView(currentArrowDirection === "up");
    }
  }, [currentArrowDirection, isActive, wrapperRef]);

  const goToProject = () => {
    navigate(`projects/${item.id}`);
    onClose();
  };

  return (
    <Box style={{ outline: "none" }} ref={ref} tabIndex={0}>
      <Flex
        py={1}
        px={1}
        alignItems="center"
        {...styles}
        onMouseEnter={() => setCurrentIndexById(item.id, "project")}
      >
        <Text fontSize="lg" fontWeight="bold">
          {cliente.name} - {item.name}
        </Text>
        <Spacer />
        {isActive && (
          <div id="mainActionWrapper">
            <IconButton
              aria-label="Crear tarea"
              icon={<MdOutlinePlaylistAdd />}
              onClick={() => setIsCreatingNewTask(true)}
            />
            <IconButton
              aria-label="Ver"
              icon={<AiOutlineEye />}
              onClick={goToProject}
            />
          </div>
        )}
      </Flex>

      <Box mb={4}>
        {item.tasks.map((tarea) => (
          <BuscadorTareaItem
            item={tarea}
            key={tarea.id}
            isActive={
              currentItem &&
              currentItem.type === "task" &&
              currentItem.id === tarea.id
            }
            setCurrentIndexById={setCurrentIndexById}
            currentArrowDirection={currentArrowDirection}
            wrapperRef={wrapperRef}
            project={item}
            onClose={onClose}
          />
        ))}
      </Box>
    </Box>
  );
};
