import React from "react";
import { HStack, useToast } from "@chakra-ui/react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Routes } from "../../routes";
import { Product } from "../../api";
import { ApiClient, DataFetcher } from "common";
import { useMutation } from "@tanstack/react-query";
import { OutletContext } from "../../types/outlet.context";
import { Modal, ModalBody, ModalHeader } from "../Modal";
import { ProductUpdateDto } from "../../dto/product-update.dto";
import { ProductsForm } from "./ProductsForm";
import { Comments } from "../Comments";
import { CommentParentEnum } from "../../dto/types/comment.parent.type";
import { Helmet } from "react-helmet";
import { ModalColumn } from "../Modal/ModalColumn";
import constants from "../../constants";

export const ProductsUpdate = () => {
  const backTo = Routes.PRODUCTS + "?sr=false";
  const { id } = useParams();
  const navigate = useNavigate();
  const { onUpdate } = useOutletContext<OutletContext<Product>>();
  const toast = useToast();

  const mutation = useMutation<Product, unknown, ProductUpdateDto>(
    (d) => ApiClient.patch(`products/${id}`, JSON.stringify(d)),
    {
      onError: () => {
        toast({
          title: "Error accediendo al servidor",
          description:
            "Ha habido un error accediendo al servidor y los datos no se han guardado. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onSuccess: async (data) => {
        toast({
          title: "Correcto",
          description: "El producto ha sido modificado correctamente.",
          status: "success",
          duration: constants.toastDuration,
          isClosable: true,
        });

        onUpdate(data.id, data);
        navigate(backTo);
      },
    }
  );

  const handleSubmit = (values: ProductUpdateDto) => {
    mutation.mutate(values);
  };

  return (
    <Modal>
      <DataFetcher
        queryKey={["products", id]}
        ep={`products/${id}`}
        render={(d: Product) => (
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Project15 - Productos - {d.name}</title>
            </Helmet>

            <ModalHeader onClose={() => navigate(backTo)}>
              <HStack spacing={2}>
                <span>Modificar Producto {d.name}</span>
              </HStack>
            </ModalHeader>

            <ModalBody columns={2}>
              <ModalColumn borderRightWidth="1px">
                <ProductsForm
                  initialValues={d}
                  isNewRecord={false}
                  dto={ProductUpdateDto}
                  onSubmit={handleSubmit}
                  mutationLoading={false}
                />
              </ModalColumn>

              <ModalColumn>
                <Comments type={CommentParentEnum.product} parentId={d.id} />
              </ModalColumn>
            </ModalBody>
          </>
        )}
      />
    </Modal>
  );
};
