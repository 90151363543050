import { globalStyles } from "./styles";
import { extendTheme } from "@chakra-ui/react";
import { buttonStyles } from "./buttons.styles";

export default extendTheme(
  { ...globalStyles },
  { ...buttonStyles },
  {
    initialColorMode: "light",
    useSystemColorMode: true,
  }
);
