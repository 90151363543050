import React from "react";
import { Modal, ModalBody, ModalHeader } from "../Modal";
import { useNavigate, useParams } from "react-router-dom";
import { Button, HStack, Stack, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { UserEntity } from "../../api";
import { ApiClient } from "common";
import { UsersPasswordUpdateDto } from "../../dto/users-password-update.dto";
import { createValidator } from "class-validator-formik";
import { Form, Formik } from "formik";
import { CustomTextInput } from "../Forms";
import { IsNotEmpty, MinLength } from "class-validator";
import { Match } from "../../decorators/match";
import { Helmet } from "react-helmet";
import { ModalColumn } from "../Modal/ModalColumn";
import constants from "../../constants";

class UpdatePasswordValidation {
  @IsNotEmpty()
  @MinLength(6)
  password: string;

  @IsNotEmpty()
  @Match("password", { message: "Las contraseñas no coinciden" })
  password_repeat: string;
}

export const UsersPasswordUpdate: React.FC = () => {
  const navigate = useNavigate();
  const backTo = "/users";
  const { id } = useParams();
  const ep = `users/password-update/${id}`;
  const toast = useToast();

  const mutation = useMutation<UserEntity, unknown, UsersPasswordUpdateDto>(
    (d) => ApiClient.post<UserEntity>(ep, JSON.stringify(d)),
    {
      onError: () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onSuccess: async () => {
        toast({
          title: "Correcto",
          description:
            "La contraseña del usuario ha sido modificada correctamente.",
          status: "success",
          duration: constants.toastDuration,
          isClosable: true,
        });

        // queryClient.refetchQueries(["infsc_users"]);
        navigate("/users");
        // onUpdate(data.id, data);
      },
    }
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Project15 - Usuarios - Modificar contraseña</title>
      </Helmet>
      <Modal>
        <ModalHeader onClose={() => navigate(backTo)}>
          <HStack spacing={2}>
            <span>Modificar Contraseña</span>
          </HStack>
        </ModalHeader>

        <ModalBody columns={1}>
          <ModalColumn>
            <Formik
              initialValues={{ password: "", password_repeat: "" }}
              onSubmit={async (values) => {
                try {
                  console.log(values);
                  mutation.mutate(values);

                  // onSubmit(values as unknown as typeof dto)
                } catch (error) {
                  // @todo notificar al usuario y ver que hacer.
                  console.error(error);
                }
              }}
              validate={createValidator(UpdatePasswordValidation)}
            >
              {({ isValid, dirty, touched }) => (
                <Form>
                  <Stack spacing="6">
                    <CustomTextInput
                      label="Contraseña"
                      placeholder="Contraseña del usuario"
                      type="password"
                      name="password"
                      autoComplete="off"
                    />

                    <CustomTextInput
                      label="Repetir Contraseña"
                      placeholder="Repita la contraseña del usuario"
                      type="password"
                      name="password_repeat"
                      autoComplete="off"
                    />

                    <Button
                      colorScheme="blue"
                      size="lg"
                      fontSize="md"
                      type="submit"
                      disabled={
                        !(isValid && (dirty || Object.keys(touched).length)) ||
                        mutation.isLoading
                      }
                    >
                      {mutation.isLoading ? "Espere" : "Actualizar contraseña"}
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </ModalColumn>
        </ModalBody>
      </Modal>
    </>
  );
};
