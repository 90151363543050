import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Box,
  Button,
  Grid,
  Text,
  GridItem,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  useColorModeValue,
  useToast,
  Flex,
} from '@chakra-ui/react';
import { BsJustify, BsPlus } from 'react-icons/bs';
import { ApiClient } from 'common';
import { StartStopButton } from './StartStopButton';
import { LastTasksByTimesDto } from '../../api';
import { Link } from 'react-router-dom';
import constants from '../../constants';

export const CurrentlyNotWorking = () => {
  const hoverRowBG = useColorModeValue('gray.200', 'gray.200');

  const initialFocusRef = React.useRef(null);

  const [isOpened, setIsOpened] = useState(false);
  const toast = useToast();

  const { data } = useQuery<LastTasksByTimesDto[]>(
    ['ultimastareas'],
    () => ApiClient.get<LastTasksByTimesDto[]>('times/latests-tasks'),
    {
      onError: () => {
        toast({
          title: 'Error al recuperar los datos',
          description:
            'Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.',
          status: 'error',
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    },
  );

  return (
    <>
      <Popover
        initialFocusRef={initialFocusRef}
        placement="top"
        onOpen={() => setIsOpened(true)}
        onClose={() => setIsOpened(false)}
      >
        <PopoverTrigger>
          <Button>
            <BsPlus />
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverHeader pt={4} fontWeight="bold" border="0">
            Últimas tareas iniciadas
          </PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            {isOpened && (
              <List>
                {data
                  ?.sort((a, b) => b.last_start_time - a.last_start_time)
                  .map((task: LastTasksByTimesDto, index) => {
                    const zebraStyle =
                      index % 2 === 0 ? {} : { backgroundColor: 'gray.100' };

                    return (
                      <Grid
                        key={task.id}
                        sx={zebraStyle}
                        borderBottom="1px solid"
                        borderBottomColor="gray.200"
                        _hover={{ backgroundColor: hoverRowBG }}
                      >
                        <ListItem key={task.id}>
                          <Link
                            to={`projects/${task.projectId}/task/${task.id}`}
                          >
                            <Text
                              fontSize="13"
                              as="b"
                              marginTop={2}
                              noOfLines={1}
                            >
                              {task.customerName} - {task.projectTitle}{' '}
                            </Text>
                          </Link>

                          <Flex alignItems="center">
                            <Link
                              to={`projects/${task.projectId}/task/${task.id}`}
                            >
                              <Text
                                fontSize="16"
                                paddingBottom={2}
                                paddingTop={2}
                                paddingRight={2}
                              >
                                {task.title}
                              </Text>
                            </Link>
                            {!task.closed && (
                              <StartStopButton taskId={task.id} />
                            )}
                          </Flex>
                        </ListItem>
                      </Grid>
                    );
                  })}
              </List>
            )}
          </PopoverBody>
          <PopoverFooter
            border="0"
            d="flex"
            alignItems="center"
            justifyContent="space-between"
            pb={4}
          >
            <Box fontSize="sm">
              Selecciona una tarea para un inicio rápido...
            </Box>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </>
  );
};
