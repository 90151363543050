import {
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  MaxLength,
  MinLength,
  ValidateIf,
} from 'class-validator';

export class ProjectUpdateDto {
  @MinLength(3)
  @MaxLength(255)
  @IsNotEmpty()
  
  name: string;

  @MaxLength(255)
  @IsOptional()
  
  description?: string;

  @IsOptional()
  
  customerId: number;

  @IsBoolean()
  
  @IsOptional()
  closed: boolean;

  
  @IsNumber()
  @IsOptional()
  @ValidateIf((o) => o.amount !== '' && o.amount)
  amount?: number;
}
