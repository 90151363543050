import React from "react";
import { Heading, HStack, useToast } from "@chakra-ui/react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Routes } from "../../routes";
import { ProjectCreateDto } from "../../dto/project-create.dto";
import { useMutation } from "@tanstack/react-query";
import { Project } from "../../api";
import { ApiClient } from "common";
import { OutletContext } from "../../types/outlet.context";
import { Modal, ModalBody, ModalHeader } from "../Modal";
import { ProjectsForm } from "./ProjectsForm";
import { ModalColumn } from "../Modal/ModalColumn";
import constants from "../../constants";

const initialValues: Partial<ProjectCreateDto> = {
  description: "",
  name: "",
};

// let initialValuesWithCustomer: ProjectCreateDto;

export const ProjectsCreate = () => {
  const backTo = Routes.PROJECTS;
  const navigate = useNavigate();
  const toast = useToast();

  const { id: customerId } = useParams();

  if (customerId) initialValues.customerId = +customerId;
  else delete initialValues.customerId;

  const { onCreate } = useOutletContext<OutletContext<Project>>();

  const mutation = useMutation<Project, unknown, ProjectCreateDto>(
    (d) => ApiClient.post("projects", JSON.stringify(d)),
    {
      onError: () => {
        toast({
          title: "Error accediendo al servidor",
          description:
            "Ha habido un error accediendo al servidor y los datos no se han guardado. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onSuccess: async (data) => {
        toast({
          title: "Correcto",
          description: "El proyecto ha sido creado correctamente.",
          status: "success",
          duration: constants.toastDuration,
          isClosable: true,
        });
        onCreate(data);
        navigate(backTo);
      },
    }
  );

  const handleSubmit = (values: ProjectCreateDto) => {
    console.log({ values });
    mutation.mutate(values);
  };

  return (
    <Modal>
      <ModalHeader onClose={() => navigate(backTo)}>
        <HStack spacing={2}>
          <h3>Crear Proyecto para cliente {customerId}</h3>
        </HStack>
      </ModalHeader>

      <ModalBody columns={1}>
        <ModalColumn>
          <Heading>Crear Proyecto</Heading>
          <ProjectsForm
            initialValues={initialValues}
            isNewRecord={true}
            dto={ProjectCreateDto}
            onSubmit={handleSubmit}
            mutationLoading={mutation.isLoading}
          />
        </ModalColumn>
      </ModalBody>
    </Modal>
  );
};
