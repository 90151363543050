import React from "react";
import { Heading, HStack, useToast } from "@chakra-ui/react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Routes } from "../../routes";
import { Project } from "../../api";
import { ApiClient, DataFetcher } from "common";
import { useMutation } from "@tanstack/react-query";
import { OutletContext } from "../../types/outlet.context";
import { Modal, ModalBody, ModalHeader } from "../Modal";
import { ProjectUpdateDto } from "../../dto/project-update.dto";
import { ProjectsForm } from "./ProjectsForm";
import { ModalColumn } from "../Modal/ModalColumn";
import constants from "../../constants";

export const ProjectsUpdate = () => {
  const backTo = Routes.PROJECTS;
  const { id } = useParams();
  const navigate = useNavigate();
  const { onUpdate } = useOutletContext<OutletContext<Project>>();
  const toast = useToast();

  const mutation = useMutation<Project, unknown, ProjectUpdateDto>(
    (d) => ApiClient.patch(`projects/${id}`, JSON.stringify(d)),
    {
      onError: () => {
        toast({
          title: "Error accediendo al servidor",
          description:
            "Ha habido un error accediendo al servidor y los datos no se han guardado. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onSuccess: async (data) => {
        toast({
          title: "Correcto",
          description: "El proyecto ha sido modificado correctamente.",
          status: "success",
          duration: constants.toastDuration,
          isClosable: true,
        });

        onUpdate(data.id, data);
        navigate(backTo);
      },
    }
  );

  const handleSubmit = (values: ProjectUpdateDto) => {
    console.log(values);
    mutation.mutate(values);
  };

  return (
    <Modal>
      <DataFetcher
        queryKey={["projects", id]}
        ep={`projects/${id}`}
        render={(d: Project) => (
          <>
            <ModalHeader onClose={() => navigate(backTo)}>
              <HStack spacing={2}>
                <span>Modificar Proyecto</span>
              </HStack>
            </ModalHeader>

            <ModalBody columns={1}>
              <ModalColumn>
                <Heading>Modificar Proyecto</Heading>
                <ProjectsForm
                  initialValues={d}
                  isNewRecord={false}
                  dto={ProjectUpdateDto}
                  onSubmit={handleSubmit}
                  mutationLoading={false}
                />
              </ModalColumn>
            </ModalBody>
          </>
        )}
      />
    </Modal>
  );
};
