import constants from "../constants";
import IUser from "../types/user.interface";
import { SignUpDto } from "../dto/signup.dto";

export interface IATResponse {
  access_token: string;
}

/**
 * consulta el ep me
 * guarda el resultado en local storage si response.statusCode === 200
 * @param accessToken
 */
export const me = (accessToken: string): Promise<IUser> => {
  return fetch(constants.apiUrl + "me", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((r) => r.json())
    .then((data) => {
      if (data.statusCode === 200)
        localStorage.setItem(
          constants.localStorageCurrentUser,
          JSON.stringify(data)
        );
      return data;
    });
};

export const logout = (): Promise<boolean> => {
  return fetch(constants.apiUrl + "auth/logout", {
    method: "POST",
    credentials: "include",
  })
    .then((d) => {
      if (d.status === 200) return true;

      throw new Error(d.status.toString());
    })
    .catch((error) => {
      alert("error");
      console.error(error);
      return false;
    });
};

export const signUpUser = (data: SignUpDto): Promise<IATResponse | false> => {
  return fetch(constants.apiUrl + "auth/signup", {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((r) => {
      if (r.status !== 201) throw new Error();
      return r.json();
    })
    .then((r) => {
      if (r.access_token?.length) return r;
      return false;
    })
    .catch(() => false);
};

export const login = (
  email: string,
  password: string
): Promise<IATResponse | false> => {
  return fetch(constants.apiUrl + "auth/login", {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  })
    .then((r) => r.json())
    .then((r) => {
      if (r.access_token?.length) return r;
      return false;
    });
};

export const recoverPasswordUser = (email: string): Promise<boolean> => {
  return fetch(constants.apiUrl + "auth/recover-password", {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  })
    .then((r) => {
      return r.status < 300 && r.status >= 200;
    })
    .catch((r) => {
      return false;
    });
};

export const checkUserByIdToken = (
  id: number,
  token: string
): Promise<boolean> => {
  return fetch(constants.apiUrl + "auth/check-user-by-token", {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id, token }),
  })
    .then((r) => {
      return r.status < 300 && r.status >= 200;
    })
    .catch((r) => {
      return false;
    });
};

export const changePasswordUser = (
  id: number,
  token: string,
  password: string
): Promise<boolean> => {
  return fetch(constants.apiUrl + "auth/change-password", {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id, token, password }),
  })
    .then((r) => {
      return r.status < 300 && r.status >= 200;
    })
    .catch((r) => {
      return false;
    });
};

export const refreshToken = async (): Promise<IATResponse | false> => {
  const res = await fetch(constants.apiUrl + "auth/refresh", {
    method: "POST",
    credentials: "include",
  })
    .then((r) => r.json())
    .catch(() => false);

  if (res.statusCode && res.statusCode !== 200) return false;

  return res;
};
