import React from "react";
import { Heading, HStack, useToast } from "@chakra-ui/react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { Task } from "../../api";
import { ApiClient } from "common";
import { OutletContext } from "../../types/outlet.context";
import { Modal, ModalBody, ModalHeader } from "../Modal";
import { TaskCreateDto } from "../../dto/task-create.dto";
import { TasksForm } from "./TasksForm";
import { Helmet } from "react-helmet";
import { ModalColumn } from "../Modal/ModalColumn";
import constants from "../../constants";

export const TasksCreate = () => {
  const navigate = useNavigate();
  const { id: projectId } = useParams();
  const backTo = `/projects/${projectId}`;
  const toast = useToast();

  const initialValues: TaskCreateDto = {
    projectId: Number(projectId),
    title: "",
    description: "",
  };

  const { onCreate } = useOutletContext<OutletContext<Task>>();

  const mutation = useMutation<Task, unknown, TaskCreateDto>(
    (d) => ApiClient.post("tasks", JSON.stringify(d)),
    {
      onError: () => {
        toast({
          title: "Error al crear la tarea",
          description:
            "Ha habido un error al crear la tarea. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onSuccess: async (data) => {
        toast({
          title: "Tarea creada correctamente",
          description: `La tarea ha sido creada correctamente.`,
          status: "success",
          duration: constants.toastDuration,
          isClosable: true,
        });
        onCreate(data);
        navigate(backTo);
      },
    }
  );

  const handleSubmit = (values: TaskCreateDto) => {
    console.log({ values });
    mutation.mutate(values);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Project15 - Proyectos - Crear Tarea</title>
      </Helmet>

      <Modal>
        <ModalHeader onClose={() => navigate(backTo)}>
          <HStack spacing={2}>
            <h3>Crear Tarea</h3>
          </HStack>
        </ModalHeader>

        <ModalBody columns={1}>
          <ModalColumn>
            <Heading>Crear Tarea</Heading>
            <TasksForm
              initialValues={initialValues}
              isNewRecord={true}
              dto={TaskCreateDto}
              onSubmit={handleSubmit}
              mutationLoading={mutation.isLoading}
            />
          </ModalColumn>
        </ModalBody>
      </Modal>
    </>
  );
};
