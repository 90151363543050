import React from "react";
import { BuscadorVisible } from "./BuscadorVisible";
import { Box, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { useShortcut } from "../../hooks";
import { AiOutlineSearch } from "react-icons/ai";

export const Buscador = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useShortcut("k", () => {
    if (!isOpen) onOpen();
  });

  return (
    <Box>
      <Flex
        onClick={onOpen}
        border="1px"
        borderColor="gray.400"
        minWidth="120px"
        p={1}
        borderRadius={6}
        alignItems="center"
        pl={1}
        _hover={{
          cursor: "pointer",
        }}
      >
        <AiOutlineSearch />
        <Text pl={1}>Buscar...</Text>
      </Flex>

      <BuscadorVisible onClose={onClose} isOpen={isOpen} />
    </Box>
  );
};
