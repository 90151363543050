import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { createValidator } from "class-validator-formik";
import { Button, Stack } from "@chakra-ui/react";
import { CustomTextInput } from "../Forms";
import { useMutation } from "@tanstack/react-query";
import { Task } from "../../api";
import { ApiClient } from "common";
import { TaskCreateDto } from "../../dto/task-create.dto";

interface ISubTasksCreateProps {
  taskParent: number;
  projectId: number;
  onCreate: (values: Task, bottom: boolean) => void;
}

export const SubTasksCreate: React.FC<ISubTasksCreateProps> = ({
  taskParent,
  onCreate,
  projectId,
}) => {
  const initialValues: TaskCreateDto = {
    description: "",
    title: "",
    parentId: parseInt(taskParent as unknown as string),
    projectId,
  };

  const mutation = useMutation<Task, unknown, TaskCreateDto>(
    (d) => ApiClient.post("tasks", JSON.stringify(d)),
    {
      onError: (error, variables, context) => {
        console.log("error");
        console.log({ error, variables, context });
      },
      onSuccess: () => console.log("success"),
    }
  );

  const handleSubmit = (
    values: TaskCreateDto,
    actions: FormikHelpers<TaskCreateDto>
  ) => {
    mutation.mutate(values, {
      onSuccess: async (data) => {
        onCreate(data, true);
        actions.resetForm();
      },
    });
  };

  const [isFocussed, setIsFocussed] = useState(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        actions.resetForm();
        handleSubmit(values, actions);
      }}
      validate={createValidator(TaskCreateDto)}
    >
      {(props) => (
        <Form
          onFocus={() => setIsFocussed(true)}
          onBlur={() => {
            props.values.title?.length === 0 && props.setTouched({});
            console.log(props.values);
            setIsFocussed(false);
          }}
        >
          <Stack spacing="6">
            <CustomTextInput
              label={false}
              placeholder="Agregar subtarea"
              name="title"
              autoComplete="off"
            />

            {(props.dirty || isFocussed) && (
              <Button
                colorScheme="blue"
                size="lg"
                fontSize="md"
                type="submit"
                disabled={
                  !(
                    (props.isValid &&
                      (props.dirty || Object.keys(props.touched).length)) ||
                    mutation.isLoading
                  )
                }
              >
                {mutation.isLoading ? "Espere" : "Agregar subtarea"}
              </Button>
            )}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
