import { Button, Stack, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import useAuth from "../../context/auth/auth";
import { LoginDto } from "../../dto/login.dto";
import { Form, Formik } from "formik";
import { createValidator } from "class-validator-formik";
import { Link, useNavigate } from "react-router-dom";
import { SmallAlert } from "../SmallAlert";
import { CustomTextInput } from ".";
import { Routes } from "../../routes";
import constants from "../../constants";

export const LoginForm = () => {
  const auth = useAuth();
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const toast = useToast();

  const initialValues: LoginDto = {
    email: "",
    password: "",
  };

  const submitForm = async (values: LoginDto) => {
    setError(false);
    await auth
      .login(values.email, values.password)
      .then(() => {
        navigate("/");
      })
      .catch((e) => {
        if (e === "network error") {
          toast({
            title: "Error",
            description:
              "Error conectando con el servidor. Inténtalo de nuevo en unos minutos",
            status: "error",
            duration: constants.toastDuration,
            isClosable: true,
          });
        } else {
          toast({
            title: "Datos incorrectos",
            description: "El usuario o contraseña no es correcto.",
            status: "error",
            duration: constants.toastDuration,
            isClosable: true,
          });
          setError(true);
        }
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validate={createValidator(LoginDto)}
    >
      {(props) => (
        <Form
          onChange={() => {
            setError(false);
          }}
        >
          <Stack spacing="6">
            <CustomTextInput
              helperText="Introduzca aquí su email"
              label="Email"
              placeholder="email"
              name="email"
              autoComplete="off"
            />

            <CustomTextInput
              helperText="Introduzca aquí su contraseña"
              label="Contraseña"
              type="password"
              placeholder="123456"
              name="password"
            />

            <SmallAlert status="error" visible={error} px={23} my={4}>
              <p>
                El usuario o contraseña no es correcto.
                <br />
                <Link to={Routes.RECOVER_PASSWORD}>
                  ¿Has olvidado tu contraseña?
                </Link>
              </p>
            </SmallAlert>

            <Button
              type="submit"
              colorScheme="blue"
              size="lg"
              fontSize="md"
              disabled={
                !(
                  props.isValid &&
                  (props.dirty || Object.keys(props.touched).length)
                ) || props.isSubmitting
              }
            >
              {props.isSubmitting ? "Espere" : "Entrar"}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
