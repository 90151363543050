import React, { useRef } from "react";
import { Field, Form, Formik } from "formik";
import { createValidator } from "class-validator-formik";
import { TaskUpdateDto } from "../../dto/task-update.dto";
import { Heading, Text, useToast } from "@chakra-ui/react";
import { EditableField } from "../EditableField";
import { Task } from "../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ApiClient } from "common";
import constants from "../../constants";

interface ITaskUpdateFormProps {
  item: Task;
}

export const TaskUpdateForm: React.FC<ITaskUpdateFormProps> = ({ item }) => {
  const titleRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const toast = useToast();

  const queryClient = useQueryClient();

  const mutation = useMutation<Task, unknown, TaskUpdateDto>(
    (d) => ApiClient.patch<Task>(`tasks/${item.id}`, JSON.stringify(d)),
    {
      onError: (error, variables, context) => {
        console.log("mutation on error");
        console.log({ error, variables, context });
        toast({
          title: "Error al guardar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onSuccess: async (data) => {
        console.log("mutation on success");
        onUpdate(data.id, data);
      },
    }
  );

  const onUpdate = (id: number, data: Task) => {
    queryClient.setQueryData(["tasks", item.id], data);
    queryClient.refetchQueries(["infsc_tasks_project", item.projectId]);
    console.log("onupdate");
  };

  const submitForm = (values: TaskUpdateDto) => {
    mutation.mutate(values);
  };

  return (
    <Formik
      initialValues={{
        title: item.title,
        description: item.description,
        closed: item.closed,
      }}
      onSubmit={submitForm}
      validate={createValidator(TaskUpdateDto)}
    >
      {(props) => (
        <Form
          onBlur={() => {
            console.log("blur");
            props.submitForm();
          }}
        >
          <Heading>
            <EditableField
              visibleValue={props.values.title}
              childRef={titleRef}
            >
              <Field
                type="text"
                name="title"
                innerRef={titleRef}
                placeholder="Agregar título"
                style={{ width: "100%", paddingLeft: "5px" }}
              />
            </EditableField>
          </Heading>

          <EditableField
            visibleValue={
              props.values.description || (
                <Text as="cite" color="gray.400">
                  Agregar descripción
                </Text>
              )
            }
            childRef={descriptionRef}
          >
            <Field
              as="textarea"
              name="description"
              placeholder="Agregar Descripción"
              innerRef={descriptionRef}
              style={{ width: "100%", paddingLeft: "5px" }}
            />
          </EditableField>
        </Form>
      )}
    </Formik>
  );
};
