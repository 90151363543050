import React from "react";
import {useQuery} from "@tanstack/react-query";
import {ApiClient} from "common";
import {LogEntityType, LogEventType, LogResponse,} from "../../dto/types/log.type";
import {formatSeconds} from "common/dist/util";
import {BiComment, BiFolder, BiTag} from "react-icons/bi";
import {BsCart, BsClockHistory, BsCreditCard, BsFileEarmarkText, BsPeople} from "react-icons/bs";
import {AiOutlineProject} from "react-icons/ai";
import {TimelineRow} from "../Layout";
import {DateTime} from "luxon";
import {HiOutlineFolderRemove} from 'react-icons/hi';
import {convertTimestampToDate, parseDateToTimeZone} from '../../util';
import {Text} from '@chakra-ui/react';

interface ILogWidgetProps {
  entity: LogEntityType;
  entityId: number;
}

type IconType = {
  [K in LogEntityType]: {
    icon: React.FC;
    color: string;
  };
};
const icons: IconType = {
  Customer: { color: "green.200", icon: BsPeople },
  Expense: { color: "red.500", icon: HiOutlineFolderRemove },
  Payment: { color: "green.500", icon: BsCreditCard },
  Product: { color: "blue.400", icon: BsCart },
  ProductLine: { color: "cyan.500", icon: BsCart },
  Project: { color: "purple.400", icon: AiOutlineProject },
  Tag: { color: "pink.600", icon: BiTag },
  Time: { color: "yellow.500", icon: BsClockHistory },
  Upload: { color: "orange.400", icon: BsFileEarmarkText },
  UserEntity: { color: "teal.400", icon: BsPeople },
  Comment: {
    icon: BiComment,
    color: "gray.600",
  },
  Task: {
    icon: BiFolder,
    color: "blue.200",
  },
};
export const LogWidget: React.FC<ILogWidgetProps> = ({ entity, entityId }) => {
  const { data, isLoading } = useQuery(["log", entity, entityId], () =>
    ApiClient.get<{ data: LogResponse[] }>("log?entity="+entity+"&entity_id="+entityId)
  );


  if(!isLoading && data?.data.length === 0)
    return <div><Text textAlign='center' fontSize="sm">No hay datos para mostrar</Text></div>

  return (
    <div>
      {isLoading ? (
        <p>Espere...</p>
      ) : (
        data?.data.map((item, index) => (
          <TimelineRow
            key={index}
            logo={icons[item.entity].icon}
            title={generateText(item, entity, item.entityId) as string}
            date={DateTime.fromISO(item.date.toString()).toFormat(
              "dd-MM-yyyy HH:mm:ss"
            )}
            color={icons[item.entity].color}
            index={index}
            arrLength={data!.data.length}
            // popoverHeader={generateText(item, entity, item.entityId) as string}
            popoverBody={generatePopoverBody(item, entity)}
          />
        ))
      )}
    </div>
  );
};

function generatePopoverBody(
  item: LogResponse,
  currentEntityType: LogEntityType
) {

  let response = '';

  if (item.entity === LogEntityType.Time){
    item.children?.forEach((time) => {
      switch (time.event){
        case 'Start':
            response += `<p style="margin-bottom: 2px">Inició el tiempo el ${convertTimestampToDate(time.newValues.start_time as number)}</p>`;
          break;
        case 'Stop':
            response += `<p>Detuvo el tiempo el ${convertTimestampToDate(time.newValues.end_time as number)}</p>`;
          break;
        case 'Update':
          // const startUpdated = typeof time.oldValues.start_time !== undefined;
          // const oldUpdatedTimestamp = convertTimestampToDate((startUpdated ? time.oldValues.start_time : time.oldValues.end_time) as number);
          // const newUpdatedTimestamp = convertTimestampToDate((startUpdated ? time.newValues.start_time : time.newValues.end_time) as number);
          // response += `<p>${time.date}: Modificó el tiempo de ${( startUpdated ? 'inicio' : 'fin')} de ${oldUpdatedTimestamp} a ${newUpdatedTimestamp}</p>`;
          response += `<p>El ${parseDateToTimeZone(time.date)}: Modificó el tiempo a ${formatSeconds(time.newValues.total_time_segs as number)}</p>`;
          break;
        case 'Deleted':
          response += `<p>Borró el tiempo el ${time.date} </p>`;
          break;
      }

    })
  }

  return response;
}

function generateText(
  item: LogResponse,
  currentEntityType: LogEntityType,
  currentEntityId: number
) {
  let response = item.userName;

  if (item.entity === LogEntityType.Project){
    switch (item.event){
      case LogEventType.Create:
        response += ` ha creado el proyecto`;
        return response;

      case LogEventType.Delete:
        return response + ` ha borrado el proyecto`;

      case LogEventType.Update:
        if (!item.oldValues?.closed && item.newValues?.closed)
          response += ' ha cerrado ';
        else if (item.oldValues?.closed && !item.newValues?.closed)
          response += ' ha abierto ';
        else
          response += ' ha modificado ';

        return response + ' el proyecto';
    }
  }

  if (item.entity === LogEntityType.Expense){
    switch (item.event){
      case LogEventType.Create:
        response += ` ha agregado un gasto`;
        return response;

      case LogEventType.Delete:
        return `ha borrado un gasto`;

      case LogEventType.Update:
        response += ` ha modificado un gasto`;
        return response;
    }
  }

  if (item.entity === LogEntityType.Payment){
    switch (item.event){
      case LogEventType.Create:
        response += ` ha agregado un cobro `;
        return response;

      case LogEventType.Delete:
        return response + ` ha borrado un cobro`;

      case LogEventType.Update:
        response += ` ha modificado un cobro`;
        return response;
    }
  }

  if (item.entity === LogEntityType.ProductLine){
    switch (item.event){
      case LogEventType.Create:
        if (item.parentEntity === currentEntityType)
          response += ` ha asignado un producto`;
        else response += ` ha asignado un producto en la tarea "${item.parentName}"`;
        return response;

      case LogEventType.Delete:
        if (item.parentEntity === currentEntityType)
          response += ` ha borrado un producto asignado`;
        else response += ` ha borrado un producto asignado a la tarea "${item.parentName}"`;
        return response

      case LogEventType.Update:
        if (item.parentEntity === currentEntityType)
          response += ` ha modificado un producto asignado`;
        else response += ` ha modificado un producto asignado a la tarea "${item.parentName}"`;
        return response;
    }
  }

  if (item.entity === LogEntityType.Upload){
    switch (item.event){
      case LogEventType.Create:
        response += ` ha subido un fichero`;
        return response;

      case LogEventType.Delete:
        return response + ` ha borrado un fichero`;

      case LogEventType.Update:
        response += ` ha modificado un fichero`;
        return response;
    }
  }

  if (item.entity === LogEntityType.Time) {
    if (item.children?.find(tchild => tchild.event.toLowerCase() === "deleted"))
      return response + ` ha eliminado un tiempo de la tarea ${item.parentName}`


    if (item.children?.length === 1 && item.children[0]?.event.toLowerCase() === "start")
      return response + ` ha empezado a trabajar en la tarea "${item.parentName}"`;
    else
      return (
        response +
        ` ha trabajado durante ${item.totalTime && formatSeconds(item.totalTime!)} en la tarea "${
          item.parentName
        }"`
      );
  }

  if (item.entity === LogEntityType.Task) {
    switch (item.event) {
      case LogEventType.Create:
        response += ` ha creado la tarea "${item.parentName}"`;
        return response;

      case LogEventType.Delete:
        return response += ` ha borrado la tarea "${item.parentName}"`;
      case LogEventType.Update:
        if (!item.oldValues?.closed && item.newValues?.closed)
          response += ' ha cerrado ';
        else if (item.oldValues?.closed && !item.newValues?.closed)
           response += ' ha abierto ';
        else
          response += ' ha modificado ';

        return response + ` la tarea "${item.parentName}"`;
    }
  }
  if (item.entity === LogEntityType.Comment) {
    switch (item.event) {
      case LogEventType.Create:
        if (item.parentEntity === currentEntityType)
          response += ` ha hecho un comentario`;
        else response += ` ha comentado en la tarea "${item.parentName}"`;

        return response;

      case LogEventType.Delete:
        return "borrado";
      case LogEventType.Update:
        if (item.parentEntity === currentEntityType)
          response += ` ha modificado un comentario`;
        else response += ` ha modificado un comentario en la tarea "${item.parentName}"`;

        return response;
    }
  }

  return "no definido en parseText";
}

function parseEntityToString(entity: LogEntityType) {
  switch (entity) {
    case LogEntityType.Project:
      return "proyecto";

    case LogEntityType.Time:
      return "tiempo";
    case LogEntityType.Tag:
      return "tag";
    case LogEntityType.Customer:
      return "cliente";
    case LogEntityType.Expense:
      return "gasto";
    case LogEntityType.Payment:
      return "pago";
    case LogEntityType.Product:
      return "producto";
    case LogEntityType.ProductLine:
      return "producto";
    case LogEntityType.Upload:
      return "fichero";
    case LogEntityType.User:
      return "usuario";
    case LogEntityType.Task:
      return "tarea";
  }
}
