import { Box, Flex, HStack, useToast } from "@chakra-ui/react";
import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import ContentBox from "../components/Layout/ContentBox";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useStatePersist } from "../hooks";
import { PaginatedResponse } from "../services/interfaces/ApiResponse";
import { Task } from "../api";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import useAuth from "../context/auth/auth";
import { ApiClient } from "common";
import constants from "../constants";
import LoadMoreButton from "../components/LoadMoreButton/LoadMoreButton";
import { InputFilterDebounced } from "../components/Interface/InputFilterDebounced";
import useSetBreadcrumbs from "../components/BreadCrumbsHeader/useSetBreadcrumbs";
import { AssignedTasksItem } from "../components/Widgets/AssignedTasks/AssignedTasksItem";

interface IWidgetAssignedTasksProps {}

export const WidgetAssignedTasks: React.FC<IWidgetAssignedTasksProps> = () => {
  const queryKey = "infsc_assigned_tasks";
  const [search, setSearch] = useStatePersist("AssignedTasksPageSearch", "");
  const { user } = useAuth();
  const toast = useToast();
  const loadMoreRef = useRef(null);

  const requestQueryBuilder = new RequestQueryBuilder();
  requestQueryBuilder
    .setLimit(10)
    .setPage(1)
    .setJoin(["project"])
    .search({
      "assigned_users.id": {
        $in: [user!.sub],
      },
      closed: false,
      $or: [
        {
          title: {
            $cont: search,
          },
        },
        {
          "project.name": {
            $cont: search,
          },
        },
      ],
    });

  useSetBreadcrumbs([
    {
      to: "/",
      label: "Home",
    },
    {
      label: "Tareas asignadas",
    },
  ]);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    isLoading,
  } = useInfiniteQuery<PaginatedResponse<Task[]>>(
    [queryKey, search],
    async ({ pageParam }) => {
      requestQueryBuilder.setPage(pageParam);
      return ApiClient.get<PaginatedResponse<Task[]>>(
        "tasks",
        requestQueryBuilder
      );
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage.page < lastPage.pageCount
          ? lastPage.page + 1
          : undefined;
      },
      onError: () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  const onChangeDebounced = React.useCallback((d: string) => setSearch(d), []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Project15 - Tareas asignadas</title>
      </Helmet>

      <ContentBox>
        <Flex>
          <HStack flexShrink={0}>
            <Box>
              <InputFilterDebounced
                onChangeDebounced={onChangeDebounced}
                value={search}
              />
            </Box>
          </HStack>
        </Flex>
      </ContentBox>

      <ContentBox>
        <Box>
          <Flex flexDirection="column">
            {isError ? (
              <p>Ha habido un error cargando los datos</p>
            ) : isLoading ? (
              <p>cargando</p>
            ) : data?.pages[0].data.length === 0 ? (
              <p>No hay resultados.</p>
            ) : (
              <>
                {data?.pages.map((group, i) => (
                  <React.Fragment key={i}>
                    {group.data.map((item) => (
                      <AssignedTasksItem item={item} />
                    ))}
                  </React.Fragment>
                ))}
              </>
            )}
          </Flex>

          <LoadMoreButton
            loadMoreRef={loadMoreRef}
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
          />
        </Box>
      </ContentBox>
    </>
  );
};
