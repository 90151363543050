import React, { useCallback, useEffect, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  HStack,
  Input,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { Time } from "../../api";
import { useMutation } from "@tanstack/react-query";
import { TimeUpdateDto } from "../../dto/time-update.dto";
import { ApiClient } from "common";
import constants from "../../constants";

interface ITimesAlertDialogQuickUpdateProps {
  isOpen: boolean;
  onClose: () => void;
  item: Time;
  onUpdate: (data: Time) => void;
}

export const TimesAlertDialogQuickUpdate: React.FC<
  ITimesAlertDialogQuickUpdateProps
> = ({ isOpen, onClose, item, onUpdate }) => {
  const cancelRef = React.useRef(null);

  const [startTime, setStartTime] = useState(item.start_time);
  const [endTime, setEndTime] = useState(item.end_time);
  const [customStartTimeModifier, setCustomStartTimeModifier] = useState("");
  const [customEndTimeModifier, setCustomEndTimeModifier] = useState("");

  const toast = useToast();

  const updateTime = useCallback(
    (minutes: number, timeToUpdate: string) => {
      if (timeToUpdate === "start") {
        setStartTime(endTime - minutes * 60);
      } else {
        setEndTime(startTime + minutes * 60);
      }
    },
    [startTime, endTime]
  );

  useEffect(() => {
    if (customStartTimeModifier.length === 0) return;

    updateTime(Number(customStartTimeModifier), "start");
  }, [customStartTimeModifier, updateTime]);

  useEffect(() => {
    if (customEndTimeModifier.length === 0) return;

    updateTime(Number(customEndTimeModifier), "end");
  }, [customEndTimeModifier, updateTime]);

  const mutation = useMutation<Time, unknown, TimeUpdateDto>(
    (d) => ApiClient.patch<Time>(`times/${item.id}`, JSON.stringify(d)),
    {
      onSuccess: async (data) => {
        toast({
          title: "Correcto",
          description: "El tiempo ha sido modificado correctamente.",
          status: "success",
          duration: constants.toastDuration,
          isClosable: true,
        });
        setStartTime(data.start_time);
        setEndTime(data.end_time);
        setCustomStartTimeModifier("");
        setCustomEndTimeModifier("");
        onUpdate(data);
      },
    }
  );

  useEffect(() => {
    /**
     * como los tiempos están en el estado, cuando cambie item
     * porque se ha actualizado la query, actualizamos el tiempo
     * de inicio y de fin. Si no, se mantiene el del estado (que si
     * estaba iniciado sería null)
     */
    //
    setStartTime(item.start_time);
    setEndTime(item.end_time);
  }, [item]);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Modificar tiempo
          </AlertDialogHeader>

          <AlertDialogBody>
            <SimpleGrid columns={2}>
              <Box>
                <Box mb={2}>
                  {DateTime.fromSeconds(startTime).toFormat(
                    "dd-MM-yyyy HH:mm:ss"
                  )}
                </Box>
                <Flex>
                  <Button size="xs" onClick={() => updateTime(15, "start")}>
                    -15
                  </Button>
                  <Button size="xs" onClick={() => updateTime(30, "start")}>
                    -30
                  </Button>
                  <Button size="xs" onClick={() => updateTime(60, "start")}>
                    -60
                  </Button>
                  <HStack>
                    <span>-</span>
                    <Input
                      style={{ marginInlineStart: 0 }}
                      type="number"
                      size="xs"
                      min={0}
                      width={10}
                      value={customStartTimeModifier}
                      onChange={(e) =>
                        setCustomStartTimeModifier(e.target.value)
                      }
                    />
                  </HStack>
                </Flex>
              </Box>

              <Box>
                <Box mb={2}>
                  {endTime &&
                    DateTime.fromSeconds(endTime).toFormat(
                      "dd-MM-yyyy HH:mm:ss"
                    )}
                </Box>
                <Flex>
                  <Button size="xs" onClick={() => updateTime(15, "end")}>
                    +15
                  </Button>
                  <Button size="xs" onClick={() => updateTime(30, "end")}>
                    +30
                  </Button>
                  <Button size="xs" onClick={() => updateTime(60, "end")}>
                    +60
                  </Button>
                  <HStack>
                    <span>-</span>
                    <Input
                      style={{ marginInlineStart: 0 }}
                      type="number"
                      size="xs"
                      min={0}
                      width={10}
                      value={customEndTimeModifier}
                      onChange={(e) => setCustomEndTimeModifier(e.target.value)}
                    />
                  </HStack>
                </Flex>
              </Box>
            </SimpleGrid>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              colorScheme="green"
              onClick={() => {
                mutation.mutate({
                  ...item,
                  start_time: startTime,
                  end_time: endTime,
                });
                // onDelete(item);
                onClose();
              }}
              ml={3}
            >
              Guardar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
