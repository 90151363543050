import React, { useState } from "react";
import { Button, Spinner } from "@chakra-ui/react";

interface IButtonDownloadProps {
  download: () => Promise<any>;
  dstFilename: string;
}

export const ButtonDownload: React.FC<IButtonDownloadProps> = (props) => {
  const [downloading, setDownloading] = useState(false);

  const handleDownload = async () => {
    setDownloading(true);
    const res = await props.download();

    // https://stackoverflow.com/a/63946003
    const blob = await res.blob();
    const newBlob = new Blob([blob]);

    const blobUrl = window.URL.createObjectURL(newBlob);

    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", props.dstFilename);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);

    window.URL.revokeObjectURL(blobUrl);

    setDownloading(false);
  };

  return (
    <Button onClick={handleDownload} isDisabled={downloading}>
      {downloading && <Spinner size="sm" mr={1} />}
      {props.children}
    </Button>
  );
};
