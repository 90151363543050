import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { useQuery } from "@tanstack/react-query";
import { ApiClient } from "common";
import React from "react";
import { Task } from "../../../api";
import { PaginatedResponse } from "../../../services/interfaces/ApiResponse";
import { WidgetWrapper } from "../ui";
import { AssignedTasksItem } from "./AssignedTasksItem";
import { Link } from "react-router-dom";
import { Routes } from "../../../routes";
import { Badge } from "@chakra-ui/react";

interface IAssignedTasksProps {
  userId: number;
}

export const AssignedTasks: React.FC<IAssignedTasksProps> = ({ userId }) => {
  const requestQueryBuilder = new RequestQueryBuilder();
  requestQueryBuilder
    .setLimit(10)
    .setPage(1)
    .setJoin(["project"])
    .search({
      "assigned_users.id": {
        $in: [userId],
      },
      closed: false,
    });
  const { data, isLoading } = useQuery(["tasks_assigned"], () =>
    ApiClient.get<PaginatedResponse<Task[]>>("tasks", requestQueryBuilder)
  );

  return (
    <WidgetWrapper
      title={
        <Link to={`/${Routes.WIDGET_ASSIGNED_TASKS}`}>
          Tareas asignadas
          <Badge>
            {data?.count}/{data?.total}
          </Badge>
        </Link>
      }
    >
      {isLoading ? (
        <p>Cargando</p>
      ) : (
        data?.data.map((item) => (
          <AssignedTasksItem item={item} key={item.id} />
        ))
      )}
    </WidgetWrapper>
  );
};
