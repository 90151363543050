import React, { useState } from "react";
import { Timer } from "./Timer";
import { useWorkingQuery } from "../../hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Comment, Task, Time } from "../../api";
import { ApiClient } from "common";
import {
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { BsStopFill } from "react-icons/bs";
import { CurrentlyNotWorking } from "./CurrentlyNotWorking";
import { Link } from "react-router-dom";
import { CommentCreateDto } from "../../dto/comment-create.dto";
import { CommentParentEnum } from "../../dto/types/comment.parent.type";
import { TaskUpdateDto } from "../../dto/task-update.dto";
import constants from "../../constants";

export const CurrentlyWorkingStatus = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [comment, setComment] = useState("");
  const workingQuery = useWorkingQuery();
  const toast = useToast();
  const queryClient = useQueryClient();

  // event y stringify vacío es para que TS no se queje
  const stopMutation = useMutation<Time>(
    () => ApiClient.post("times/stop", JSON.stringify({})),
    {
      onSuccess: () => {
        workingQuery.refetch();

        toast({
          title: "Tiempo detenido",
          description: `Has detenido la tarea ${
            (workingQuery.data?.task as unknown as Task).title
          } correctamente`,
          status: "success",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: "Error al recuperar los datos (3)",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  const addCommentMutation = useMutation<Comment, unknown, CommentCreateDto>(
    (d) => ApiClient.post("comments", JSON.stringify(d)),
    {
      onError: () => {
        toast({
          title: "Error al recuperar los datos (2)",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onSuccess: async () => {
        setComment("");
      },
    }
  );

  const closeMutation = useMutation<Task, unknown, TaskUpdateDto>(
    (d) =>
      ApiClient.patch<Task>(
        `tasks/${(workingQuery.data?.task as unknown as Task).id}`,
        JSON.stringify(d)
      ),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["infsc_assigned_tasks"]);
        queryClient.refetchQueries(["tasks_assigned"]);
      },
      onError: (error) => {
        console.error(error);
        toast({
          title: "Error al recuperar los datos (2)",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  const addComment = async () => {
    const values: CommentCreateDto = {
      text: comment,
      parent_type: CommentParentEnum.task,
      parent_id: (workingQuery?.data?.task as Task).id,
    };

    return addCommentMutation.mutateAsync(values);
  };

  const handleStop = async (close: boolean = false) => {
    console.log("stop");
    onClose();
    if (comment.length) {
      console.log("comment length");
      const mutationCommentRes = await addComment();
      console.log(mutationCommentRes);
    }

    console.log("stop mutation");
    const stopMutationRes = await stopMutation.mutateAsync();
    console.log(stopMutationRes);

    if (close) {
      console.log("cerrar mutation");
      const task: Partial<TaskUpdateDto> = {
        ...(workingQuery.data?.task as unknown as Task),
        closed: true,
      };
      const closeMutationRes = await closeMutation.mutateAsync(task);
      console.log(closeMutationRes);
    }
  };

  return (
    <HStack px={2} data-test="currentlyWorkingOn">
      {workingQuery.isLoading ? (
        // @todo
        <Text fontWeight="normal">Espere</Text>
      ) : workingQuery.data?.working === true ? (
        <>
          <Icon
            as={BsStopFill}
            data-cy="currently-working-status_stop"
            w={5}
            h={5}
            onClick={onOpen}
            _hover={{ cursor: "pointer" }}
          />
          <Text isTruncated>
            <Link
              to={`projects/${
                (workingQuery.data.task as Task).projectId
              }/task/${(workingQuery.data.task as Task).id}`}
            >
              {(workingQuery.data.task as Task).title}
            </Link>
          </Text>

          <Timer />

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                Detener {(workingQuery?.data?.task as Task).title}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Textarea
                  tabIndex={1}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></Textarea>
              </ModalBody>

              <ModalFooter>
                <Button
                  tabIndex={2}
                  colorScheme="blue"
                  mr={3}
                  onClick={() => handleStop(false)}
                >
                  Detener
                </Button>

                {workingQuery.data?.task &&
                  !(workingQuery.data?.task as unknown as Task).closed && (
                    <Button
                      tabIndex={2}
                      colorScheme="red"
                      onClick={() => handleStop(true)}
                    >
                      Detener y finalizar
                    </Button>
                  )}
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      ) : (
        <CurrentlyNotWorking />
      )}
    </HStack>
  );
};
