import React from "react";
import ContentBox from "../Layout/ContentBox";
import { Box, Button, Grid, GridItem, Stack, useToast } from "@chakra-ui/react";
import { Company } from "../../api";
import { createValidator } from "class-validator-formik";
import { CustomTextInput } from "../Forms";
import { Form, Formik } from "formik";
import { CompanyUpdateDto } from "../../dto/company-update.dto";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ApiClient } from "common";
import { AvatarUploader } from "../Uploader";
import constants from "../../constants";

interface ICompaniesFormProps {
  item: Company;
}

export const CompaniesForm: React.FC<ICompaniesFormProps> = ({ item }) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  let ep = `companies/${item.id}`;
  const queryKey = ["companies", item.id];
  const mutation = useMutation<Company, unknown, CompanyUpdateDto>(
    (d) => ApiClient.patch(ep, JSON.stringify(d)),
    {
      onError: () => {
        toast({
          title: "Error accediendo al servidor",
          description:
            "Ha habido un error accediendo al servidor y los datos no se han guardado. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onSuccess: async (data) => {
        toast({
          title: "Correcto",
          description: "Los datos han sido modificado correctamente.",
          status: "success",
          duration: constants.toastDuration,
          isClosable: true,
        });

        queryClient.setQueryData(queryKey, () => data);

        // onUpdate(data.id, data);
        // navigate(backTo);
      },
    }
  );

  // console.log(values);
  // try {
  //     console.log(values);
  //     mutation.mutate(values);
  //
  //     // onSubmit(values as unknown as typeof dto)
  // } catch (error) {
  //     // @todo notificar al usuario y ver que hacer.
  //     console.error(error)
  // }

  return (
    <ContentBox>
      <Box>
        <AvatarUploader
          modelId={item.id}
          defaultText={item.name}
          onImageUploadSuccess={(avatar) => {
            queryClient.setQueryData(queryKey, (oldData) => {
              return { ...(oldData as unknown as Company), avatar };
            });
          }}
          image={item.avatar}
          type="companies"
        />

        <Formik
          initialValues={item}
          onSubmit={async (values, formikHelpers) => {
            mutation.mutate(values);
            formikHelpers.resetForm({ values });
          }}
          validate={createValidator(CompanyUpdateDto)}
        >
          {({ isValid, dirty }) => (
            <Form>
              <Stack spacing="6">
                <Grid templateColumns="2fr 1fr" gap={2}>
                  <GridItem>
                    <CustomTextInput
                      label="Nombre"
                      placeholder="Nombre la empresa"
                      name="name"
                      autoComplete="off"
                    />
                  </GridItem>

                  <GridItem>
                    <CustomTextInput
                      label="CIF"
                      placeholder="CIF"
                      name="cif"
                      autoComplete="off"
                    />
                  </GridItem>
                </Grid>

                <CustomTextInput
                  label="Dirección"
                  placeholder="Dirección fiscal"
                  name="address"
                  autoComplete="off"
                />

                <Grid templateColumns="1fr 1fr" gap={2}>
                  <GridItem>
                    <CustomTextInput
                      label="Email"
                      placeholder="Dirección de email"
                      name="email"
                      type="email"
                      autoComplete="off"
                    />
                  </GridItem>

                  <GridItem>
                    <CustomTextInput
                      label="Teléfono"
                      placeholder="Teléfono"
                      name="phone"
                      type="phone"
                      autoComplete="off"
                    />
                  </GridItem>
                </Grid>

                <Button
                  colorScheme="blue"
                  size="lg"
                  fontSize="md"
                  type="submit"
                  disabled={!isValid || mutation.isLoading || !dirty}
                >
                  {mutation.isLoading ? "Espere" : "Guardar"}
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Box>
    </ContentBox>
  );
};
