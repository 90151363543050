import { MultipleUploader } from "./MultipleUploader";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiClient } from "common";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { MultipleList } from "./MultipleList";
import { UploadTypeEnum } from "../../dto/types/UploadType.type";
import { Upload } from "../../api";
import { Box, useToast } from "@chakra-ui/react";
import constants from "../../constants";

interface IMultipleFilesProps {
  id: number;
  type: UploadTypeEnum;
}

/**
 * Componente para mostrar tanto el widget de listado de ficheros como el de subida múltiple
 * @param id
 * @param type
 * @constructor
 */
export const MultipleFiles: React.FC<IMultipleFilesProps> = ({ id, type }) => {
  const request = RequestQueryBuilder.create();
  const queryClient = useQueryClient();
  const cacheKey = ["files", id, type];
  const toast = useToast();

  request.search({ parentId: id, type });
  const { data } = useQuery<Upload[]>(
    cacheKey,
    () => ApiClient.get<Upload[]>("uploads", request),
    {
      staleTime: 60 * 1000,
      onError: () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  const onFileDeleted = (fileId: number) => {
    queryClient.setQueryData<Upload[]>(
      cacheKey,
      (oldData) => oldData?.filter((item: Upload) => item.id !== fileId) || []
    );
  };

  const onFileUpdated = (updatedItem: Upload) => {
    queryClient.setQueryData<Upload[]>(
      cacheKey,
      (oldData) =>
        oldData?.map((item) =>
          item.id === updatedItem.id ? updatedItem : item
        ) || []
    );
  };

  return (
    <div>
      <MultipleList
        data={data}
        onFileDeleted={onFileDeleted}
        onFileUpdated={onFileUpdated}
      />

      <Box mt={4}>
        <MultipleUploader parentId={id} type={type} />
      </Box>
    </div>
  );
};
