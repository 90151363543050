import React from "react";
import { Avatar, Tooltip } from "@chakra-ui/react";
import { CustomOptionDataItem } from "./types";
import useImageJwt from "../../ImageJWT/useImageJwt";

interface ICustomMultiValueSelectProps {
  data: CustomOptionDataItem;
}

export const CustomMultiValueLabel: React.FC<ICustomMultiValueSelectProps> = ({
  data,
}) => {
  const { image: imageBlob } = useImageJwt(data.avatar as string);
  return (
    <Tooltip label={data.label} placement="top">
      <Avatar name={data.label} src={imageBlob} size="sm" />
    </Tooltip>
  );
};
