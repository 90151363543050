import { AppRoutes } from "./routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import useAuth from "./context/auth/auth";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import theme from "./theme/theme";
import { AppContextProvider } from "./context/AppContext";
import { ApiClient } from "common";
import { AbilityContext } from "./context/can";
import ability from "./context/ability";
import { unpackRules } from "@casl/ability/extra";
import React, { useEffect } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import constants from "./constants";

const queryClient = new QueryClient();

function App() {
  const auth = useAuth();

  useEffect(() => {
    if (!auth.user?.rules) return;
    const rules = unpackRules(auth.user?.rules);
    ability.update(rules);
  }, [auth.user]);

  ApiClient.url = constants.apiUrl;
  ApiClient.access_token = auth.access_token;

  return (
    <AbilityContext.Provider value={ability}>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme} resetCSS={true}>
          <AppContextProvider>
            <ScrollToTop>
              <AppRoutes />
            </ScrollToTop>
            <ReactQueryDevtools
              initialIsOpen={false}
              position={"bottom-right"}
            />
          </AppContextProvider>
        </ChakraProvider>
      </QueryClientProvider>
    </AbilityContext.Provider>
  );
}

export default App;
