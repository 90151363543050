import React, { ReactText } from "react";
import {
  Avatar,
  Box,
  BoxProps,
  chakra,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FiChevronDown, FiMenu, FiSettings } from "react-icons/fi";
import { FaProjectDiagram, FaUsers } from "react-icons/fa";
import { IconType } from "react-icons";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";
import { Routes } from "../../routes";
import { BsMoonStars, BsPeopleFill, BsSunFill } from "react-icons/bs";
import {
  AiOutlineHome,
  AiOutlineLogout,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { RiFilePaper2Line } from "react-icons/ri";
import useAuth from "../../context/auth/auth";
import { Logo } from "../Logo";
import useImageJwt from "../ImageJWT/useImageJwt";
import { rolToString } from "common/dist/util";
import { BreadcrumbsHeader } from "../BreadCrumbsHeader";
import { CurrentlyWorkingStatus } from "../StartStop";
import { Buscador } from "../Buscador";
import { DevelopmentWarning } from "./DevelopmentWarning";

interface LinkItemProps {
  name: string;
  icon: IconType;
  to: string;
}

export default function SidebarWithHeader() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const appBorder =
    process.env.NODE_ENV === "development" ? "10px solid tomato" : "none";

  return (
    <Box
      minH="100vh"
      bg={useColorModeValue("gray.100", "gray.900")}
      border={appBorder}
    >
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />

      <DevelopmentWarning />

      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p={{ base: 8, md: 4, lg: 8 }}>
        <Outlet />
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const LinkItems: Array<LinkItemProps> = [
    { name: "Home", icon: AiOutlineHome, to: Routes.HOME },
    { name: "Usuarios", icon: FaUsers, to: Routes.USERS },
    { name: "Clientes", icon: BsPeopleFill, to: Routes.CUSTOMERS },
    { name: "Proyectos", icon: FaProjectDiagram, to: Routes.PROJECTS },
    { name: "Tags", icon: FiSettings, to: Routes.TAGS },
    { name: "Productos", icon: AiOutlineShoppingCart, to: Routes.PRODUCTS },
    { name: "Informes", icon: RiFilePaper2Line, to: Routes.REPORTS },
  ];

  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="full" direction="column">
        <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
          <Logo mx="auto" h="14" />
          <CloseButton
            display={{ base: "flex", md: "none" }}
            onClick={onClose}
          />
        </Flex>

        <Flex direction="column">
          {LinkItems.map((link) => (
            <NavItem key={link.name} icon={link.icon} to={link.to}>
              {link.name}
            </NavItem>
          ))}
        </Flex>

        <Box p={2}>
          <Buscador />
        </Box>
        <Box mt="auto" mb={8}>
          <CurrentlyWorkingStatus />
        </Box>
      </Flex>
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactText;
  to: string;
}

const NavItem = ({ icon, children, to, ...rest }: NavItemProps) => {
  // let resolved = useResolvedPath(to);
  // let match = useMatch({ path: resolved.pathname });
  // console.log({resolved, to});

  let location = useLocation();
  let path = useResolvedPath(to);
  let match = location.pathname.startsWith(path.pathname);

  const activeStyles = match
    ? {
        bg: "cyan.200",
      }
    : {};

  return (
    <Link to={to} style={{ textDecoration: "none" }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        transition="all .1s ease-in-out"
        _hover={{
          bg: "cyan.400",
          color: "white",
        }}
        {...rest}
        {...activeStyles}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const auth = useAuth();
  const navigate = useNavigate();

  const { image: avatar } = useImageJwt(auth.user?.avatar || "");

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Logo mx="auto" h="14" display={{ md: "none" }} />

      <BreadcrumbsHeader />

      <HStack spacing={{ base: "0", md: "6" }}>
        <chakra.button
          bg="transparent"
          onClick={toggleColorMode}
          display={{ base: "none", md: "block" }}
        >
          {colorMode === "light" ? <BsMoonStars /> : <BsSunFill />}
        </chakra.button>

        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar size={"sm"} src={avatar} />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{auth.user?.name}</Text>
                  <Text fontSize="xs" color="gray.600">
                    {rolToString(auth.user?.rol)}
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <MenuItem onClick={() => navigate(Routes.PROFILE)}>
                Perfil
              </MenuItem>
              <MenuItem onClick={() => navigate(Routes.COMPANY)}>
                Empresa
              </MenuItem>
              <MenuItem>Ajustes</MenuItem>
              <MenuItem>Facturas</MenuItem>
              <MenuDivider />
              <MenuItem
                bgColor="gray.200"
                justifyContent="center"
                onClick={toggleColorMode}
                display={{ md: "none" }}
              >
                {colorMode === "light" ? <BsMoonStars /> : <BsSunFill />}
              </MenuItem>

              <MenuDivider />
              <MenuItem>
                <HStack
                  px={4}
                  onClick={() => auth.logout()}
                  backgroundColor={useColorModeValue("transparent", "gray.600")}
                  padding={0}
                  _hover={{
                    backgroundColor: useColorModeValue("gray.100", "gray.600"),
                  }}
                >
                  <AiOutlineLogout />

                  <Text fontSize="sm">Cerrar sesión</Text>
                </HStack>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};
