import React from "react";
import useAuth from "../context/auth/auth";
import {Navigate, useLocation} from "react-router-dom";

/**
 * Fuerza el estar logeado para acceder a una ruta
 *
 * https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src/App.tsx
 * @param children
 * @constructor
 */
export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.isUserAuthenticated())
    return <Navigate to="/login" state={{ from: location }} replace />;

  return children;
};
