import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Box, Button, SimpleGrid, useToast } from '@chakra-ui/react';
import { CustomTextInput } from '../Forms';
import { useQuery } from '@tanstack/react-query';
import { Customer, Tag } from '../../api';
import { ApiClient } from 'common';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { CustomMultiSelect } from '../Forms/CustomMultiSelect';
import { CustomOptionDataItem } from '../Users/UsersAvatars/types';
import { HorasPorClienteYFechaDto } from '../../dto/horas-por-cliente-y-fecha.dto';
import { createValidator } from 'class-validator-formik';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import constants from '../../constants';

interface IReportHoursByCustomerDateFormProps {
  dto: any;
  onSubmit: (values: HorasPorClienteYFechaDto) => void;
}

export const ReportHoursByCustomerDateForm: React.FC<
  IReportHoursByCustomerDateFormProps
> = ({ dto, onSubmit }) => {
  const params = useParams();
  const data = new URLSearchParams(params['*']);
  const toast = useToast();
  const tagRequest = RequestQueryBuilder.create();
  const [customerLoading, setCustomerLoading] = useState(true);
  const [tagLoading, setTagLoading] = useState(true);
  const [customerData, setCustomerData] = useState<CustomOptionDataItem[]>([]);
  const [tagData, setTagData] = useState<CustomOptionDataItem[]>([]);

  const initialValues: HorasPorClienteYFechaDto = {
    customers: data.getAll('customers').map((c) => Number(c)),
    projectTagsIgnore: data.getAll('projectTagsIgnore').map((c) => Number(c)),
    projectTagsInclude: data.getAll('projectTagsInclude').map((c) => Number(c)),
    startDate:
      data.get('startDate')?.split(' ')[0] ||
      DateTime.now().minus({ month: 1 }).startOf('month').toISODate(),
    endDate:
      data.get('endDate')?.split(' ')[0] ||
      DateTime.now().minus({ month: 1 }).endOf('month').toISODate(),
  };

  tagRequest.sortBy({
    field: 'title',
    order: 'ASC',
  });

  const { data: tagResData, isLoading: tagsIsLoading } = useQuery<Tag[]>(
    ['tags', tagRequest],
    () => ApiClient.get<Tag[]>('tags', tagRequest),
    {
      onError: () => {
        toast({
          title: 'Error al recuperar los datos',
          description:
            'Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.',
          status: 'error',
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    },
  );

  const customerRequest = RequestQueryBuilder.create();
  customerRequest.sortBy({
    field: 'name',
    order: 'ASC',
  });

  const { data: customerResData, isLoading: customersIsLoading } = useQuery<
    Customer[]
  >(
    ['customers', customerRequest],
    () => ApiClient.get<Customer[]>('customers', customerRequest),
    {
      onError: () => {
        toast({
          title: 'Error al recuperar los datos',
          description:
            'Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.',
          status: 'error',
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    },
  );

  useEffect(() => {
    if (!customerResData) return;

    setCustomerData(
      customerResData.map((item) => ({
        label: item.name,
        value: item.id,
        avatar: item.name,
      })),
    );

    setCustomerLoading(false);
  }, [customerResData]);

  useEffect(() => {
    if (!tagResData) return;

    setTagData(
      tagResData.map((item) => ({
        label: item.title,
        value: item.id,
        avatar: item.title,
      })),
    );

    setTagLoading(false);
  }, [tagResData]);

  return tagsIsLoading ||
    customersIsLoading ||
    tagLoading ||
    customerLoading ? (
    <p>Cargando...</p>
  ) : (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => onSubmit(values)}
      validate={createValidator(dto)}
    >
      {() => (
        <Form>
          <SimpleGrid columns={2} spacing={3}>
            <Box>
              <CustomTextInput
                name="startDate"
                type="date"
                label="Fecha de inicio"
              />
            </Box>
            <Box>
              <CustomTextInput
                name="endDate"
                type="date"
                label="Fecha de fin"
              />
            </Box>
            <Box>
              <CustomMultiSelect
                options={customerData}
                label="Clientes"
                name="customers"
              />
            </Box>
            <Box>
              <CustomMultiSelect
                options={tagData}
                label="Tags de proyectos"
                name="projectTagsInclude"
              />
            </Box>
            <Box>
              <CustomMultiSelect
                options={tagData}
                label="Ignorar tags de proyectos"
                name="projectTagsIgnore"
              />
            </Box>
          </SimpleGrid>
          <Box marginTop={5}>
            <Button colorScheme="blue" size="lg" fontSize="md" type="submit">
              Buscar
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
