import {useEffect, useState} from "react";
import useAuth from "../../context/auth/auth";
import constants from "../../constants";

// @todo implementar algún método de caché si no se hacen las imágenes de los avatares públicas
const useImageJwt = (
  src: string
): { image: string | undefined; isLoading: boolean } => {
  const { access_token } = useAuth();
  const [image, setImage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    function loadImage() {
      fetch(constants.apiUrl + "uploads" + src, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
        .then((r) => r.blob())
        .then((response) => {
          setImage(URL.createObjectURL(response));
          setIsLoading(false);
        });
    }

    if (!src) {
      setImage(undefined);
      setIsLoading(false);
    } else loadImage();
  }, [access_token, src]);

  return { image, isLoading };
};

export default useImageJwt;
