import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Icon,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { MdPlaylistAddCheck } from "react-icons/md";
import { Project } from "../../api";
import { ApiClient } from "common";
import constants from "../../constants";
import { CgExport } from "react-icons/cg";
import { CSVLink } from "react-csv";
//@ts-ignore
import filenamify from "filenamify/browser";

interface ITaskListActionsProps {
  projectId: number;
  csvData: any[];
}

export const TaskListActions: React.FC<ITaskListActionsProps> = ({
  projectId,
  csvData,
}) => {
  const queryClient = useQueryClient();
  const project: Project = queryClient.getQueryData([
    "projects",
    projectId.toString(),
  ]) as unknown as Project;
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const mutation = useMutation(
    (id: number) =>
      ApiClient.post(`projects/${id}/delete-all-tasks`, JSON.stringify({})),
    {
      onSuccess: async () => {
        toast({
          title: "Correcto",
          description: "Se han cerrado todas las tareas del proyecto.",
          status: "success",
          duration: constants.toastDuration,
          isClosable: true,
        });
        queryClient.setQueriesData([`infsc_tasks_project_${projectId}`], () => {
          return {
            pages: [
              {
                data: [],
                count: 0,
                total: 0,
                page: 1,
                pageCount: 1,
              },
            ],
          };
        });
      },
      onError: () => {
        toast({
          title: "Error accediendo al servidor",
          description:
            "Ha habido un error accediendo al servidor y los datos no se han guardado. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  return (
    <Flex mt={2} justifyContent="flex-end">
      {csvData && (
        <Box>
          <Tooltip label="Exportar todas las tareas">
            <VStack style={{ justifyContent: "center" }}>
              <CSVLink
                filename={filenamify(`Tareas-${project?.name}.csv`)}
                data={csvData}
              >
                <Icon w={4} h={4} as={CgExport} />
              </CSVLink>
            </VStack>
          </Tooltip>
        </Box>
      )}

      <Box ml={2}>
        <Tooltip label="Cerrar todas las tareas">
          <VStack style={{ justifyContent: "center" }}>
            <Icon
              w={5}
              h={5}
              as={MdPlaylistAddCheck}
              onClick={onOpen}
              _hover={{
                cursor: "pointer",
              }}
            />
          </VStack>
        </Tooltip>
      </Box>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              ¿Cerrar todas las tareas del proyecto?
            </AlertDialogHeader>

            <AlertDialogBody>
              <p>
                Vas a cerrar todas las tareas del proyecto:{" "}
                <strong>{project?.name}</strong>
              </p>
              <p>¿Estás seguro de que quieres continuar?</p>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onClose} ref={cancelRef}>
                No, mantenerlas abiertas
              </Button>
              <Button
                colorScheme="red"
                onClick={() => mutation.mutate(projectId)}
                ml={3}
              >
                Cerrar todas
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};
