import React from "react";
import Select from "react-select";
import { FieldAttributes, useField } from "formik";
import { FormErrorMessage, FormHelperText, FormLabel } from "@chakra-ui/react";

export interface DefaultOption {
  value: number;
  label: string;
}

interface ICustomSelectProps extends FieldAttributes<any> {
  options: DefaultOption[];
  helperText?: string;
  label: string;
}

export interface selectItem {
  value: string | number;
  label: string;
}

export const CustomSelect: React.FC<ICustomSelectProps> = ({
  label,
  options,
  helperText,
  ...props
}) => {
  const restProps: FieldAttributes<any> = { ...props };
  const [field, meta, helpers] = useField(restProps);

  const isInvalid = meta.touched && meta.error !== undefined;

  return (
    <div>
      <FormLabel aria-required={true} htmlFor={restProps.name || restProps.id}>
        {label}
      </FormLabel>
      <Select
        onChange={(item: selectItem) => {
          helpers.setTouched(true);
          helpers.setValue(item.value);
        }}
        options={options}
        placeholder="Seleccione..."
        value={options?.filter((o) => {
          return o.value === field.value;
        })}
        {...restProps}
      />

      <FormErrorMessage>{meta.error}</FormErrorMessage>

      {!isInvalid && helperText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </div>
  );
};
