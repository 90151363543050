import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { ApiClient } from "common";
import { Button, useToast } from "@chakra-ui/react";
import useAuth from "../../context/auth/auth";
import { IATResponse } from "../../services/user.service";
import { useNavigate } from "react-router-dom";
import constants from "../../constants";
import { GoogleRequestBodyType } from "../../dto/types/google.request.body.type";

interface IGoogleSocialLoginProps {}
export const GoogleSocialLogin: React.FC<IGoogleSocialLoginProps> = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async ({ code }) => {
      const body: GoogleRequestBodyType = {
        code,
        redirectUri: "postmessage",
      };
      return fetch(constants.apiUrl + "auth/login-google", {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then<IATResponse>((r) => r.json())

        .then((d) => {
          auth.setAccessToken(d.access_token);
          navigate("/");
        })
        .catch(() => {
          toast({
            title: "Error en el login de Google",
            description: "La cuenta que has usado no está registrada en la app",
            status: "error",
            duration: constants.toastDuration,
            isClosable: true,
          });
        });

      // const tokens = await axios.post("http://localhost:3001/auth/google", {
      //   code: codeResponse.code,
      // });

      // console.log(tokens);
    },
    onError: (errorResponse) => console.log(errorResponse),
  });
  return <Button onClick={googleLogin}>Google login</Button>;
};
