import React, { InputHTMLAttributes, useEffect, useState } from "react";
import { Input } from "@chakra-ui/react";
import { useDebounce } from "use-debounce";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  onChangeDebounced: (v: string) => void;
  debounceTime?: number;
}

/**
 *
 * @param onChangeDebounced (this should be a React.useCallback)
 * @param debounceTime
 * @param rest
 * @constructor
 */
export const InputFilterDebounced: React.FC<IProps> = ({
  onChangeDebounced,
  debounceTime = 500,
  ...rest
}) => {
  const [val, setVal] = useState<string>(rest.value?.toString() || "");
  const [value] = useDebounce<string>(val, debounceTime);

  useEffect(() => {
    console.log("useEffect value, onChangeDebounced");
    onChangeDebounced(value);
  }, [value, onChangeDebounced]);

  return (
    <Input
      value={val}
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        e.preventDefault();
        setVal(e.target.value);
      }}
    />
  );
};
