import React from "react";
import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Stack,
} from "@chakra-ui/react";
import { FieldAttributes, useField } from "formik";

interface ICustomCheckBoxProps extends FieldAttributes<any> {
  helperText?: string;
}

export const CustomCheckBox: React.FC<ICustomCheckBoxProps> = ({
  label,
  helperText,
  ...props
}) => {
  const restProps: FieldAttributes<any> = { ...props };
  const [field, meta] = useField(restProps);

  const isInvalid = meta.touched && meta.error !== undefined;

  return (
    <FormControl isRequired={restProps.required} isInvalid={isInvalid}>
      <Stack spacing={5} direction="row">
        <Checkbox {...restProps} {...field} isChecked={field.value}>
          {label}
        </Checkbox>
      </Stack>
      <FormErrorMessage>error</FormErrorMessage>
      {!isInvalid && helperText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
