import {IsNotEmpty, IsNumber, IsString, MinLength} from 'class-validator';

export class ChangePasswordDto {
  
  @IsNumber()
  @IsNotEmpty({ message: 'Id no puede ir vacio' })
  id: number;

  
  @IsString()
  @IsNotEmpty({ message: 'Token no puede ir vacio' })
  token: string;

  
  @IsString()
  @IsNotEmpty()
  @MinLength(6)
  password: string;
}
