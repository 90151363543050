import React, { useRef } from "react";
import { Field, Form, Formik } from "formik";
import { createValidator } from "class-validator-formik";
import { Box, Heading, HStack, Text } from "@chakra-ui/react";
import { EditableField } from "../EditableField";
import { UserEntity } from "../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ApiClient } from "common";
import { useOutletContext } from "react-router-dom";
import { OutletContext } from "../../types/outlet.context";

import { UsersUpdateDto } from "../../dto/users-update.dto";
import { AvatarUploader } from "../Uploader";
import { CustomSelect } from "../Forms";
import { enunToValuesTranslatedToSelect } from "../../i18n";
import { RolUser } from "../../dto/types/rol.type";
import useAuth from "../../context/auth/auth";

interface ICustomerUpdateFormProps {
  item: UserEntity;
}

export const UsersUpdateNameForm: React.FC<ICustomerUpdateFormProps> = ({
  item,
}) => {
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const documentRef = useRef<HTMLInputElement>(null);
  const { onUpdate: outletOnUpdate } =
    useOutletContext<OutletContext<UserEntity>>();
  const { user, setUser } = useAuth();

  const queryClient = useQueryClient();

  const mutation = useMutation<UserEntity, unknown, UsersUpdateDto>(
    (d) => ApiClient.patch<UserEntity>(`users/${item.id}`, JSON.stringify(d)),
    {
      onSuccess: async (data) => {
        onUpdate(data.id, data);
      },
    }
  );

  const onUpdate = (id: number, data: UserEntity) => {
    queryClient.setQueryData(["users", item.id], data);
    outletOnUpdate(id, data);
  };

  const submitForm = (values: UsersUpdateDto) => {
    console.log("submitForm");
    console.log({ values });
    mutation.mutate(values);
  };

  const onImageUploadSuccess = (avatar: string) => {
    if (user?.sub === item.id) {
      const newUser = { ...user, avatar };
      setUser(newUser);
    }
    queryClient.setQueryData(["users", item.id.toString()], {
      ...item,
      avatar,
    });
  };

  // initial values, tiene email y rol para que no se queje typescript de que falta campos.
  // este formulario sólo actualiza el nombre.
  return (
    <Formik
      initialValues={{
        name: item.name,
        email: item.email,
        rol: item.rol,
        document: item.document,
      }}
      onSubmit={submitForm}
      validate={createValidator(UsersUpdateDto)}
    >
      {(props) => (
        <Form onBlur={() => props.submitForm()}>
          <HStack>
            <Box flexGrow={1}>
              <Heading>
                <EditableField
                  visibleValue={
                    props.values.name || (
                      <Text as="cite" color="gray.400">
                        Agregar Nombre
                      </Text>
                    )
                  }
                  childRef={nameRef}
                >
                  <Field
                    type="text"
                    name="name"
                    placeholder="Agregar Nombre"
                    innerRef={nameRef}
                    style={{ width: "100%", paddingLeft: "5px" }}
                  />
                </EditableField>
              </Heading>

              <EditableField
                visibleValue={
                  props.values.email || (
                    <Text as="cite" color="gray.400">
                      Agregar email
                    </Text>
                  )
                }
                childRef={emailRef}
              >
                <Field
                  type="email"
                  name="email"
                  placeholder="Agregar Email"
                  innerRef={emailRef}
                  style={{ width: "100%", paddingLeft: "5px" }}
                />
              </EditableField>

              <EditableField
                visibleValue={
                  props.values.document || (
                    <Text as="cite" color="gray.400">
                      Agregar Dni
                    </Text>
                  )
                }
                childRef={documentRef}
              >
                <Field
                  type="text"
                  name="document"
                  placeholder="Agregar Dni"
                  innerRef={documentRef}
                  style={{ width: "100%", paddingLeft: "5px" }}
                />
              </EditableField>

              <CustomSelect
                options={enunToValuesTranslatedToSelect(RolUser, "es")}
                label="Rol"
                name="rol"
              />
            </Box>

            <Box>
              <AvatarUploader
                modelId={item.id}
                defaultText={item.name}
                image={item.avatar}
                type="users"
                onImageUploadSuccess={onImageUploadSuccess}
              />
            </Box>
          </HStack>
        </Form>
      )}
    </Formik>
  );
};
