import React, { useRef } from "react";
import { Field, Form, Formik } from "formik";
import { createValidator } from "class-validator-formik";
import { CustomerUpdateDto as CustomerUpdateDtoValidator } from "../../dto/customer-update.dto";
import { Customer, CustomerUpdateDto } from "../../api";
import { Box, Heading, HStack, Text, useToast } from "@chakra-ui/react";
import { EditableField } from "../EditableField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ApiClient } from "common";
import { AvatarUploader } from "../Uploader";
import { useOutletContext } from "react-router-dom";
import { OutletContext } from "../../types/outlet.context";
import constants from "../../constants";

interface ICustomerUpdateFormProps {
  item: Customer;
}

export const CustomersUpdateForm: React.FC<ICustomerUpdateFormProps> = ({
  item,
}) => {
  const nameRef = useRef<HTMLInputElement>(null);
  const documentRef = useRef<HTMLInputElement>(null);
  const addressRef = useRef<HTMLTextAreaElement>(null);
  const postalCodeRef = useRef<HTMLTextAreaElement>(null);
  const cityRef = useRef<HTMLTextAreaElement>(null);
  const stateRef = useRef<HTMLTextAreaElement>(null);
  const countryRef = useRef<HTMLTextAreaElement>(null);
  const phone1Ref = useRef<HTMLTextAreaElement>(null);
  const phone2Ref = useRef<HTMLTextAreaElement>(null);

  const { onUpdate: outletOnUpdate } =
    useOutletContext<OutletContext<Customer>>();
  const toast = useToast();

  const queryClient = useQueryClient();

  const mutation = useMutation<Customer, unknown, CustomerUpdateDto>(
    (d) => ApiClient.patch<Customer>(`customers/${item.id}`, JSON.stringify(d)),
    {
      onSuccess: async (data) => {
        onUpdate(data.id, data);
      },
      onError: () => {
        toast({
          title: "Error accediendo al servidor",
          description:
            "Ha habido un error accediendo al servidor y los datos no se han guardado. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  const onUpdate = (id: number, data: Customer) => {
    queryClient.setQueryData(["customers", item.id], data);
    outletOnUpdate(id, data);
  };

  const submitForm = (values: CustomerUpdateDto) => {
    mutation.mutate(values);
  };

  const onImageUploadSuccess = (avatar: string) => {
    queryClient.setQueryData(["customers", item.id], { ...item, avatar });
  };

  const initialValues: CustomerUpdateDto = {
    name: item.name,
    document: item.document,
    address: item.address,
    postal_code: item.postal_code,
    city: item.city,
    state: item.state,
    country: item.country,
    phone1: item.phone1,
    phone2: item.phone2,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validate={createValidator(CustomerUpdateDtoValidator)}
    >
      {(props) => (
        <Form onBlur={() => props.submitForm()}>
          <HStack>
            <Box flexGrow={1}>
              <Heading>
                <EditableField
                  visibleValue={props.values.name}
                  childRef={nameRef}
                >
                  <Field
                    type="text"
                    name="name"
                    placeholder="Agregar nombre"
                    innerRef={nameRef}
                    style={{ width: "100%", paddingLeft: "5px" }}
                  />
                </EditableField>
              </Heading>

              <EditableField
                visibleValue={
                  props.values.document || (
                    <Text as="cite" color="gray.400">
                      Agregar Dni/Nif/Cif
                    </Text>
                  )
                }
                childRef={documentRef}
              >
                <Field
                  type="text"
                  name="document"
                  placeholder="Agregar Dni/Nif/Cif"
                  innerRef={documentRef}
                  style={{ width: "100%", paddingLeft: "5px" }}
                />
              </EditableField>

              <EditableField
                visibleValue={
                  props.values.address || (
                    <Text as="cite" color="gray.400">
                      Agregar dirección
                    </Text>
                  )
                }
                childRef={addressRef}
              >
                <Field
                  type="text"
                  name="address"
                  placeholder="Agregar dirección"
                  innerRef={addressRef}
                  style={{ width: "100%", paddingLeft: "5px" }}
                />
              </EditableField>

              <EditableField
                visibleValue={
                  props.values.postal_code || (
                    <Text as="cite" color="gray.400">
                      Agregar código postal
                    </Text>
                  )
                }
                childRef={postalCodeRef}
              >
                <Field
                  type="text"
                  name="postal_code"
                  placeholder="Agregar código postal"
                  innerRef={postalCodeRef}
                  style={{ width: "100%", paddingLeft: "5px" }}
                />
              </EditableField>

              <EditableField
                visibleValue={
                  props.values.city || (
                    <Text as="cite" color="gray.400">
                      Agregar localidad
                    </Text>
                  )
                }
                childRef={cityRef}
              >
                <Field
                  type="text"
                  name="city"
                  placeholder="Agregar localidad"
                  innerRef={cityRef}
                  style={{ width: "100%", paddingLeft: "5px" }}
                />
              </EditableField>

              <EditableField
                visibleValue={
                  props.values.state || (
                    <Text as="cite" color="gray.400">
                      Agregar provincia
                    </Text>
                  )
                }
                childRef={stateRef}
              >
                <Field
                  type="text"
                  name="state"
                  placeholder="Agregar provincia"
                  innerRef={stateRef}
                  style={{ width: "100%", paddingLeft: "5px" }}
                />
              </EditableField>

              <EditableField
                visibleValue={
                  props.values.country || (
                    <Text as="cite" color="gray.400">
                      Agregar país
                    </Text>
                  )
                }
                childRef={countryRef}
              >
                <Field
                  type="text"
                  name="country"
                  placeholder="Agregar país"
                  innerRef={countryRef}
                  style={{ width: "100%", paddingLeft: "5px" }}
                />
              </EditableField>

              <EditableField
                visibleValue={
                  props.values.phone1 || (
                    <Text as="cite" color="gray.400">
                      Agregar teléfono 1
                    </Text>
                  )
                }
                childRef={phone1Ref}
              >
                <Field
                  type="text"
                  name="phone1"
                  placeholder="Agregar teléfono 1"
                  innerRef={phone1Ref}
                  style={{ width: "100%", paddingLeft: "5px" }}
                />
              </EditableField>

              <EditableField
                visibleValue={
                  props.values.phone2 || (
                    <Text as="cite" color="gray.400">
                      Agregar teléfono 2
                    </Text>
                  )
                }
                childRef={phone2Ref}
              >
                <Field
                  type="text"
                  name="phone2"
                  placeholder="Agregar teléfono 2"
                  innerRef={phone2Ref}
                  style={{ width: "100%", paddingLeft: "5px" }}
                />
              </EditableField>
            </Box>

            <Box>
              <AvatarUploader
                modelId={item.id}
                defaultText={item.name}
                image={item.avatar}
                type="customers"
                onImageUploadSuccess={onImageUploadSuccess}
              />
            </Box>
          </HStack>
        </Form>
      )}
    </Formik>
  );
};
