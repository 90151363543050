import React, { useEffect, useRef } from "react";
import { Flex, IconButton, Spacer, Text } from "@chakra-ui/react";
import { StartStopButton } from "../StartStop";
import { isVisibleInViewport } from "../../util";
import { SearchTaskByNameResult } from "../../dto/search-by-name-result.dto";
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

interface IBuscadorTareaItem {
  item: SearchTaskByNameResult;
  project: SearchTaskByNameResult;
  isActive: boolean;
  setCurrentIndexById: (id: number, type: string) => void;
  currentArrowDirection: string;
  wrapperRef: React.RefObject<HTMLDivElement>;
  onClose: () => void;
}

export const BuscadorTareaItem: React.FC<IBuscadorTareaItem> = ({
  item,
  isActive,
  setCurrentIndexById,
  currentArrowDirection,
  wrapperRef,
  project,
  onClose,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!ref.current || !wrapperRef.current) return;

    if (isActive && !isVisibleInViewport(ref.current, wrapperRef.current)) {
      console.log("scrollIntoView tarea");
      ref.current?.scrollIntoView(currentArrowDirection === "up");
    }
  }, [currentArrowDirection, isActive, wrapperRef]);

  const styles = isActive
    ? {
        backgroundColor: "gray.100",
      }
    : {
        backgroundColor: "white",
      };

  return (
    <Flex
      borderRadius={8}
      py={2}
      px={3}
      onMouseEnter={() => setCurrentIndexById(item.id, "task")}
      ref={ref}
      {...styles}
      tabIndex={0}
      style={{ outline: "none" }}
    >
      <Text>{item.name}</Text>
      <Spacer />
      {isActive && (
        <div id="mainActionWrapper">
          <IconButton
            aria-label="Ver"
            icon={<AiOutlineEye />}
            onClick={() => {
              navigate(`projects/${project.id}/task/${item.id}`);
              onClose();
            }}
          />
          <StartStopButton taskId={item.id} />
        </div>
      )}
    </Flex>
  );
};
