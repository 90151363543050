import React from 'react';
import { Task } from '../../api';
import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { FiMoreHorizontal } from 'react-icons/fi';
import { FaEdit, FaEye, FaTrash } from 'react-icons/fa';
import { IoMdCheckmark } from 'react-icons/io';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TaskUpdateDto } from '../../dto/task-update.dto';
import { ApiClient } from 'common';
import constants from '../../constants';
import { formatSeconds } from 'common/dist/util';
import { TaskDialogUpdateProject } from './TaskDialogChangeProject';
import { TaskAlertDialogClose } from './TaskAlertDialogClose';
import { TaskAlertDialogDelete } from './TaskAlertDialogDelete';
import { TaskAlertDialogReopen } from './TaskAlertDialogReopen';
import { TagList } from '../Tags';

interface TasksListItemProps {
  item: Task;
  onDelete: (item: Task) => void;
}

export const TasksListItem: React.FC<TasksListItemProps> = ({
  item,
  onDelete,
}) => {
  const navigate = useNavigate();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();

  const {
    isOpen: isOpenClose,
    onOpen: onOpenClose,
    onClose: onCloseClose,
  } = useDisclosure();

  const {
    isOpen: isOpenReopen,
    onOpen: onOpenReopen,
    onClose: onCloseReopen,
  } = useDisclosure();

  const {
    isOpen: isOpenProjectChange,
    onOpen: onOpenProjectChange,
    onClose: onCloseProjectChange,
  } = useDisclosure();

  const toast = useToast();
  const queryClient = useQueryClient();

  const mutation = useMutation<Task, unknown, TaskUpdateDto>(
    (d) => ApiClient.patch<Task>(`tasks/${item.id}`, JSON.stringify(d)),
    {
      onError: () => {
        toast({
          title: 'Error al recuperar los datos',
          description:
            'Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.',
          status: 'error',
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onSuccess: async (data) => {
        console.log('refetch query');
        console.log(`infsc_tasks_project_${item.projectId}`);
        await queryClient.refetchQueries([
          `infsc_tasks_project_${item.projectId}`,
        ]);

        if (data.projectId !== item.projectId)
          toast({
            title: 'Tarea movida correctamente',
            description: 'La tarea se ha movido correctamente a otro proyecto.',
            status: 'success',
            duration: constants.toastDuration,
            isClosable: true,
          });
        else
          toast({
            title: data.closed ? 'Tarea cerrada' : 'Tarea abierta',
            description: data.closed
              ? `La tarea ${data.title} se ha cerrado correctamente`
              : `La tarea ${data.title} se ha vuelto a abrir correctamente`,
            status: 'success',
            duration: constants.toastDuration,
            isClosable: true,
          });
      },
    },
  );

  const closeTask = () => {
    const task: TaskUpdateDto = { ...item, closed: true };
    mutation.mutate(task);
  };

  const reopenTask = () => {
    const task: TaskUpdateDto = { ...item, closed: false };
    mutation.mutate(task);
  };

  const onProjectChange = (projectId: number) => {
    const task: TaskUpdateDto = { ...item, projectId };
    mutation.mutate(task);
  };

  return (
    <Flex
      alignItems="center"
      borderLeft="1px"
      borderRight="1px"
      borderBottom="1px"
      borderColor="gray.300"
      p={1}
      _hover={{
        backgroundColor: 'gray.50',
      }}
    >
      <Box flexGrow={1}>
        <Text>
          <Link to={`task/${item.id}`}>{item.title}</Link>
        </Text>
        <TagList items={item.assigned_tags} />
      </Box>

      <Box>
        <Text fontSize="sm">{formatSeconds(item.timeTotal)}</Text>
      </Box>

      <Box width={6} ml={2}>
        <Menu placement="bottom-end">
          <MenuButton
            as={IconButton}
            aria-label="Ordenar"
            size="xs"
            variant="ghost"
            icon={<FiMoreHorizontal />}
          />
          <MenuList alignItems="flex-end">
            <MenuItem
              icon={<IoMdCheckmark />}
              onClick={() => (item.closed ? onOpenReopen() : onOpenClose())}
            >
              {item.closed ? 'Volver a Abrir' : 'Cerrar'}
            </MenuItem>
            <MenuItem
              icon={<FaEye />}
              onClick={() => navigate(`task/${item.id}`)}
            >
              Ver
            </MenuItem>
            <MenuItem icon={<FaEdit />} onClick={onOpenProjectChange}>
              Cambiar Proyecto
            </MenuItem>
            <MenuItem icon={<FaTrash />} onClick={onOpenDelete}>
              Borrar
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>

      <TaskAlertDialogReopen
        isOpen={isOpenReopen}
        onClose={onCloseReopen}
        reopenTask={reopenTask}
        item={item}
      />

      <TaskAlertDialogClose
        isOpen={isOpenClose}
        onClose={onCloseClose}
        closeTask={closeTask}
        item={item}
      />

      <TaskAlertDialogDelete
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        onDelete={onDelete}
        item={item}
      />

      <TaskDialogUpdateProject
        isOpen={isOpenProjectChange}
        onClose={onCloseProjectChange}
        onChange={onProjectChange}
        item={item}
      />
    </Flex>
  );
};
