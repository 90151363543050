import React from "react";
import { ProductLineParentEnum } from "../../dto/types/product-line.parent.type";
import { ProductLine } from "../../api";
import { Box, Text } from "@chakra-ui/react";
import { ProductsWidgetItem } from "./ProductsWidgetItem";
import { ProductsWidgetAdd } from "./ProductsWidgetAdd";
import { ProductsWidgetHeader } from "./ProductsWidgetHeader";
import { ProductsWidgetSummary } from "./ProductsWidgetSummary";

interface IProductWidgetProps {
  type: ProductLineParentEnum;
  parentId: number;
  products: ProductLine[];
}

export const ProductsWidget: React.FC<IProductWidgetProps> = ({
  type,
  parentId,
  products,
}) => {
  return (
    <Box>
      {products.length > 0 ? (
        <>
          <ProductsWidgetHeader />
          {products.map((item) => (
            <ProductsWidgetItem
              type={type}
              item={item}
              key={item.id}
              parentId={parentId}
            />
          ))}
          <ProductsWidgetSummary products={products} />
        </>
      ) : (
        <Text>No hay productos asignados</Text>
      )}

      <ProductsWidgetAdd parentId={parentId} type={type} />
    </Box>
  );
};
