import React, { useEffect, useRef } from "react";
import { SearchByNameResultDto } from "../../dto/search-by-name-result.dto";
import { Box, Flex, IconButton, Spacer, Text } from "@chakra-ui/react";
import { isVisibleInViewport } from "../../util";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";

interface IBuscadorClienteItemProps {
  item: SearchByNameResultDto;
  isActive: boolean;
  setCurrentIndexById: (id: number, type: string) => void;
  wrapperRef: React.RefObject<HTMLDivElement>;
  currentArrowDirection: string;
  onClose: () => void;
}

export const BuscadorClienteItem: React.FC<IBuscadorClienteItemProps> = ({
  item,
  onClose,
  isActive,
  setCurrentIndexById,
  wrapperRef,
  currentArrowDirection,
}) => {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const goToCustomer = () => {
    navigate(`customers/${item.id}`);
    onClose();
  };

  useEffect(() => {
    if (!ref.current || !wrapperRef.current) return;

    if (isActive && !isVisibleInViewport(ref.current, wrapperRef.current)) {
      ref.current?.scrollIntoView(currentArrowDirection === "up");
    }
  }, [currentArrowDirection, isActive, wrapperRef]);

  const styles = isActive
    ? {
        boxShadow: "md",
        backgroundColor: "gray.100",
        zIndex: 1,
      }
    : {
        backgroundColor: "white",
      };

  return (
    <Box style={{ outline: "none" }} ref={ref} tabIndex={0} mb={2}>
      <Flex
        py={1}
        px={1}
        alignItems="center"
        onMouseEnter={() => setCurrentIndexById(item.id, "customer")}
        {...styles}
      >
        <Text fontSize="xl" fontWeight="bold">
          {item.name}
        </Text>
        <Spacer />
        {isActive && (
          <div id="mainActionWrapper">
            <IconButton
              aria-label="Ver"
              icon={<AiOutlineEye />}
              onClick={goToCustomer}
            />
          </div>
        )}
      </Flex>
    </Box>
  );
};
