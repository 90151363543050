export const buttonStyles = {
  components: {
    Button: {
      baseStyle: {
        borderRadius: "8px",
        track: {
          boxShadow: "none",
          _focus: {
            boxShadow: "none",
          },
        },
      },
    },
  },
};
