import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Project, Tag, Task } from "../../api";
import { Modal, ModalBody, ModalHeader } from "../Modal";
import { Heading, HStack, useToast } from "@chakra-ui/react";
import { ApiClient, DataFetcher } from "common";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { TaskUpdateForm } from "./TaskUpdateForm";
import { CommentParentEnum } from "../../dto/types/comment.parent.type";
import { Comments } from "../Comments";
import { TagsSelector } from "../Tags/TagsSelector";
import { Helmet } from "react-helmet";
import { ModalColumn } from "../Modal/ModalColumn";
import constants from "../../constants";

export const SubTaskDetail = () => {
  const { taskId, id: projectId, subTaskId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const backTo = `/projects/${projectId}/task/${taskId}`;

  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<Project>(["projects", projectId]);

  const onTagsSelected = (tags: Tag[]) => {
    ApiClient.post(
      `tasks/assign-tags/${taskId}`,
      JSON.stringify({ assigned_tags: tags.map((tag) => tag.id) })
    );
  };

  const parentRequestEP = `tasks/${taskId}`;
  const parentRequest = useQuery<Task>(
    ["tasks", taskId],
    () => ApiClient.get<Task>(parentRequestEP),
    {
      onError: () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        {!parentRequest.isLoading && (
          <title>Project15 - Proyectos - {parentRequest.data?.title}</title>
        )}
      </Helmet>
      <Modal>
        <DataFetcher
          queryKey={["tasks", subTaskId]}
          ep={`tasks/${subTaskId}`}
          render={(item: Task) => (
            <>
              <ModalHeader
                onClose={() => {
                  navigate(backTo);
                }}
              >
                <HStack spacing={2}>
                  <Heading size="sm">
                    {project?.customer?.name}
                    {project?.customer?.name.length && " - "}
                    {project?.name}
                    {project?.name && project?.name.length > 0 && " - "}
                    <Link to={backTo}>{parentRequest.data?.title}</Link>
                  </Heading>
                </HStack>
              </ModalHeader>

              <ModalBody columns={2}>
                <ModalColumn borderRightWidth="1px">
                  <TaskUpdateForm item={item} />
                </ModalColumn>

                <ModalColumn>
                  <TagsSelector
                    selectable={true}
                    currentTags={item.assigned_tags}
                    onBlur={onTagsSelected}
                  />
                  <Comments
                    type={CommentParentEnum.task}
                    parentId={subTaskId as unknown as number}
                  />
                </ModalColumn>
              </ModalBody>
            </>
          )}
        />
      </Modal>
    </>
  );
};
