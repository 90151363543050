import React from "react";
import {
  Box,
  Button,
  Divider,
  Heading,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Logo } from "../components/Logo";
import { Link } from "react-router-dom";
import { Card } from "../components/Card";
import { LoginForm } from "../components/Forms";
import { Routes } from "../routes";
import { Helmet } from "react-helmet";
import SocialLogins from "../components/SocialLogins";

export const LoginPage: React.FC = () => {
  const boxBG = useColorModeValue("gray.50", "inherit");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Project15 - Login</title>
      </Helmet>

      <Box>
        <SocialLogins />
      </Box>

      <Box bg={boxBG} minH="100vh" py="12" px={{ base: "4", lg: "8" }}>
        <Box maxW="md" mx="auto">
          <Logo mx="auto" h="16" mb={{ base: "10", md: "20" }} />
          <Heading textAlign="center" size="xl" fontWeight="extrabold">
            Introduce los datos de tu cuenta
          </Heading>
          <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
            <Text as="span">¿No tienes una cuenta? </Text>
            <Link to={Routes.USER_REGISTER}>Regístrate</Link>
          </Text>
          <Card>
            <LoginForm />

            <Divider mt="6" mb={4} />
            <Box textAlign="center">
              <Text color="gray.600">
                <Link to={Routes.RECOVER_PASSWORD}>Recupera tu contraseña</Link>
              </Text>
            </Box>
          </Card>
        </Box>

        <Box>
          <Image
            src="/logo-ivace.jpg"
            margin="32px auto 0"
            w="400px"
            alt="Comunidad Valenciana - IVACE - Unión Europea"
          />
        </Box>
      </Box>
    </>
  );
};
