import {
  IsDate,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  MaxLength,
  MinLength,
} from 'class-validator';

export class PaymentCreateDto {
  @MinLength(1)
  @MaxLength(100)
  @IsNotEmpty()
  
  concept: string;

  
  @IsString()
  description?: string;

  @IsNotEmpty()
  @IsNumber()
  amount: number;

  @IsNumber()
  projectId: number;

  
  @IsOptional()
  date?: Date;
}
