import React from "react";
import { Expense } from "../../api";
import { ExpenseAdd } from "./ExpenseAdd";
import { Text } from "@chakra-ui/react";
import { ExpenseListItem } from "./ExpenseListItem";
import { ExpensesListItemSummary } from "./ExpensesListItemSummary";
import { ExpensesListItemHeader } from "./ExpensesListItemHeader";

interface IExpensesWidgetProps {
  parentId: number;
  items: Expense[];
  onCreate: (data: Expense) => void;
  onUpdate: (data: Expense) => void;
  onDelete: (id: number) => void;
}

export const ExpensesWidget: React.FC<IExpensesWidgetProps> = ({
  parentId,
  items,
  onCreate,
  onDelete,
  onUpdate,
}) => {
  return (
    <div>
      {items.length > 0 ? (
        <>
          <ExpensesListItemHeader />
          {items.map((i) => (
            <ExpenseListItem
              key={i.id}
              item={i}
              onDelete={onDelete}
              onUpdate={onUpdate}
            />
          ))}
          <ExpensesListItemSummary items={items} />
        </>
      ) : (
        <Text>No hay gastos asignados</Text>
      )}

      <ExpenseAdd projectId={parentId} onCreate={onCreate} />
    </div>
  );
};
