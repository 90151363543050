import React from "react";
import { Helmet } from "react-helmet";
import { DataFetcher } from "common";
import useAuth from "../context/auth/auth";
import { CompaniesForm } from "../components/Companies";
import { Company } from "../api";
import useSetBreadcrumbs from "../components/BreadCrumbsHeader/useSetBreadcrumbs";

const breadCrumbs = [
  {
    to: "/",
    label: "Home",
  },
  {
    label: "Empresa",
  },
];
export const CompanyPage: React.FC = () => {
  const { user } = useAuth();

  useSetBreadcrumbs(breadCrumbs);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Project15 - Datos de empresa</title>
      </Helmet>

      <DataFetcher
        queryKey={["companies", user?.tenantId]}
        ep={`companies/${user?.tenantId}`}
        render={(d: Company) => <CompaniesForm item={d} />}
      />
    </>
  );
};
