import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";

interface IStopConfirmDialog {
  isOpen: boolean;
  onClose: () => void;
  taskTitle: string;
  startHandler: () => Promise<void>;
}

export const StopConfirmDialog: React.FC<IStopConfirmDialog> = ({
  isOpen,
  onClose,
  taskTitle,
  startHandler,
}) => {
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const stopPropagation = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    // Esto también puede salir dentro del buscador rápido.
    // el buscador rápido captura los keydown para poder navegar / realizar acciones
    // entre los resultados. Enter está reservado, con lo cual, desde el buscador, si este
    // evento se propaga, enter va a tener un preventDefault y por lo tanto, el botón con el
    // teclado no va a funcionar. Con el preventDefault

    e.stopPropagation();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            ¿Detener la tarea actual?
          </AlertDialogHeader>

          <AlertDialogBody>
            <p>
              Ya estás trabajando en otra tarea (<strong>{taskTitle}</strong>).
            </p>
            <p>
              Si inicias en esta tarea, se detendrá el trabajo actual y se
              empezará a contar con el nuevo.
            </p>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              onClick={onClose}
              ref={cancelRef}
              onKeyDown={stopPropagation}
            >
              No, continuar con la actual
            </Button>
            <Button
              colorScheme="green"
              onClick={startHandler}
              ml={3}
              onKeyDown={stopPropagation}
            >
              Iniciar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
