import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { BuscadorProyectoItem } from "./BuscadorProyectoItem";
import {
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlineEnter,
  AiOutlineSearch,
} from "react-icons/ai";
import { BuscadorTaskCreate } from "./BuscadorTaskCreate";
import { BuscadorNewTaskStartStop } from "./BuscadorNewTaskStartStop";
import { Task } from "../../api";
import { ApiClient } from "common";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { SearchByNameResultDto } from "../../dto/search-by-name-result.dto";
import { useDebounce } from "use-debounce";
import { BuscadorClienteItem } from "./BuscadorClienteItem";
import constants from "../../constants";

interface IBuscadorVisibleProps {
  onClose: () => void;
  isOpen: boolean;
}

export interface FlattenedObject {
  id: number;
  type: string;
  name: string;
}

const getData = (query: string): Promise<SearchByNameResultDto[]> => {
  const requestQueryBuilder = RequestQueryBuilder.create();
  requestQueryBuilder.search({ query });
  return ApiClient.post<SearchByNameResultDto[]>(
    "search",
    JSON.stringify({ query })
  );
};

export const BuscadorVisible: React.FC<IBuscadorVisibleProps> = ({
  onClose,
  isOpen,
}) => {
  const [search, setSearch] = useState("");
  // const [searchDebounced, setSearchDebounced] = useState('');
  const [searchDebounced] = useDebounce<string>(search, 500);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentArrowDirection, setCurrentArrowDirection] = useState("");
  // flatten de todos los ids de las tareas y proyectos resultantes (para que navegar entre ellas sea más fácil
  const [objectsIds, setObjectsIds] = useState<FlattenedObject[]>([]);
  const [mouseHidden, setMouseHidden] = useState(false);
  const [isCreatingNewTask, setIsCreatingNewTask] = useState(false);
  const [isTaskJustCreated, setIsTaskJustCreated] = useState(false);
  const [taskJustCreated, setTaskJustCreated] = useState<Task>();
  const toast = useToast();

  const { data } = useQuery<SearchByNameResultDto[]>(
    ["buscador", searchDebounced],
    () => getData(searchDebounced),
    {
      enabled: searchDebounced.length > 0,
      onError: () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  useEffect(() => {
    setIsCreatingNewTask(false);
    let res: FlattenedObject[] = [];
    data?.forEach((cliente) => {
      res.push({
        type: "customer",
        id: cliente.id,
        name: cliente.name,
      });
      return cliente.projects.forEach((proyecto) => {
        res = [
          ...res,
          {
            type: "project",
            id: proyecto.id,
            name: proyecto.name,
          },
          ...proyecto.tasks.map((tarea) => ({
            type: "task",
            id: tarea.id,
            name: tarea.name,
          })),
        ];
      });
    });
    setObjectsIds(res);
    setCurrentIndex(0);
  }, [data]);

  const handleKeyDown = (
    event: React.KeyboardEvent<
      HTMLDivElement | HTMLInputElement | HTMLButtonElement
    >
  ) => {
    if (isCreatingNewTask || isTaskJustCreated) return;

    switch (event.code) {
      case "Enter":
        if (event.currentTarget.tagName === "button") {
          return;
        }
        event.preventDefault();
        const activeWrapper = document.getElementById("mainActionWrapper");
        if (!activeWrapper) return;

        if (
          (event.altKey || event.metaKey) &&
          objectsIds[currentIndex].type === "project"
        ) {
          // primer botón (actualmente proyecto → crear tarea
          activeWrapper.getElementsByTagName("button")[0].click();
          setIsCreatingNewTask(true);
        } else if (
          (event.altKey || event.metaKey) &&
          objectsIds[currentIndex].type === "task"
        ) {
          activeWrapper.getElementsByTagName("button")[0].click();
        } else if (
          !(event.altKey || event.metaKey) &&
          objectsIds[currentIndex].type === "task"
        ) {
          activeWrapper.getElementsByTagName("button")[1].click();
        } else
          activeWrapper
            .getElementsByTagName("button")
            [activeWrapper.getElementsByTagName("button").length - 1].click();
        break;

      case "ArrowDown":
        if (event.currentTarget.tagName.toLowerCase() === "header")
          event.preventDefault();

        if (currentIndex < objectsIds.length - 1) {
          setCurrentIndex(currentIndex + 1);
          setCurrentArrowDirection("down");
          setMouseHidden(true);
        }
        break;

      case "ArrowUp":
        if (event.currentTarget.tagName.toLowerCase() === "header")
          event.preventDefault();
        if (currentIndex > 0) {
          setCurrentIndex(currentIndex - 1);
          setCurrentArrowDirection("up");
          setMouseHidden(true);
        }
        break;
    }
  };

  useEffect(() => {
    if (!isTaskJustCreated && !isCreatingNewTask)
      inputSearchRef.current?.focus();
  }, [isTaskJustCreated, isCreatingNewTask]);

  const setCurrentIndexById = (id: number, type: string) => {
    const index = objectsIds.findIndex(
      (element) => element.id === id && element.type === type
    );
    if (index > -1) setCurrentIndex(index);
  };

  const queryClient = useQueryClient();
  // limpiamos cuando se cierra el buscador
  useEffect(() => {
    if (!isOpen) {
      queryClient.removeQueries(["buscador"]);
      setSearch("");
      setIsCreatingNewTask(false);
    }
  }, [isOpen, queryClient]);

  const handleClose = () => {
    onClose();
    setTaskJustCreated(undefined);
    setIsTaskJustCreated(false);
  };

  const wrapperRef = useRef<HTMLDivElement>(null);
  const modalBodyRef = useRef<HTMLDivElement>(null);
  const inputSearchRef = useRef<HTMLInputElement>(null);

  return (
    <Modal
      onClose={handleClose}
      isOpen={isOpen}
      size="3xl"
      scrollBehavior="inside"
      initialFocusRef={inputSearchRef}
    >
      <ModalOverlay />
      <ModalContent onMouseMove={() => setMouseHidden(false)}>
        <ModalHeader
          onKeyDown={handleKeyDown}
          tabIndex={0}
          style={{ outline: "none" }}
          id="buscameheader"
        >
          <Box flexShrink={0}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <Icon as={AiOutlineSearch} color="gray.600" />
              </InputLeftElement>
              <Input
                id="query"
                name="query"
                placeholder="Introduce nombre de tarea o proyecto"
                value={search}
                autoComplete="off"
                ref={inputSearchRef}
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputGroup>
          </Box>
        </ModalHeader>

        {data && data?.length > 0 && (
          <ModalBody
            ref={modalBodyRef}
            style={{ pointerEvents: mouseHidden ? "none" : "auto" }}
            tabIndex={0}
            onKeyDown={handleKeyDown}
            id="buscamebody"
          >
            {isCreatingNewTask ? (
              <BuscadorTaskCreate
                onCreate={(data: Task) => {
                  // quitar formulario de tarea
                  // meter pregunta tarea
                  setTaskJustCreated(data);
                  setIsCreatingNewTask(false);
                  setIsTaskJustCreated(true);
                }}
                setIsCreatingNewTask={setIsCreatingNewTask}
                currentItem={objectsIds[currentIndex]}
              />
            ) : isTaskJustCreated ? (
              <BuscadorNewTaskStartStop
                item={taskJustCreated as Task}
                cerrarBuscadorHandler={() => {
                  handleClose();
                }}
                volverSinIniciarHandler={async () => {
                  setTaskJustCreated(undefined);
                  setIsTaskJustCreated(false);
                  await queryClient.refetchQueries([
                    "buscador",
                    searchDebounced,
                  ]);
                }}
              />
            ) : (
              <Box flexGrow={1}>
                <div tabIndex={0} style={{ outline: "none" }} ref={wrapperRef}>
                  {data?.map((cliente) => (
                    <>
                      <BuscadorClienteItem
                        item={cliente}
                        isActive={
                          objectsIds[currentIndex] &&
                          objectsIds[currentIndex].type === "customer" &&
                          objectsIds[currentIndex].id === cliente.id
                        }
                        wrapperRef={modalBodyRef}
                        setCurrentIndexById={setCurrentIndexById}
                        currentArrowDirection={currentArrowDirection}
                        onClose={onClose}
                      />
                      {cliente.projects.map((proyecto) => (
                        <BuscadorProyectoItem
                          key={proyecto.id}
                          item={proyecto}
                          cliente={cliente}
                          currentItem={objectsIds[currentIndex]}
                          setCurrentIndexById={setCurrentIndexById}
                          setIsCreatingNewTask={setIsCreatingNewTask}
                          currentArrowDirection={currentArrowDirection}
                          wrapperRef={modalBodyRef}
                          isActive={
                            objectsIds[currentIndex] &&
                            objectsIds[currentIndex].type === "project" &&
                            objectsIds[currentIndex].id === proyecto.id
                          }
                          onClose={onClose}
                        />
                      ))}
                      <Box border="1px solid" borderColor="gray.200" />
                    </>
                  ))}
                </div>
              </Box>
            )}
          </ModalBody>
        )}

        {data && data?.length === 0 && (
          <Text align="center">Sin resultados</Text>
        )}

        <ModalFooter
          justifyContent="flex-start"
          opacity={0.6}
          backgroundColor="gray.100"
          tabIndex={0}
          onKeyDown={handleKeyDown}
          style={{ outline: "none" }}
        >
          <HStack gap={2}>
            <Flex>
              <AiOutlineArrowUp />
              <AiOutlineArrowDown />
              <Text ml={1} fontSize="xs">
                Use las flechas para navegar entre los resultados
              </Text>
            </Flex>

            {!isCreatingNewTask &&
              objectsIds[currentIndex] &&
              objectsIds[currentIndex].type === "customer" && (
                <Flex>
                  <AiOutlineEnter />
                  <Text ml={1} fontSize="xs">
                    Enter para ver el cliente
                  </Text>
                </Flex>
              )}

            {!isCreatingNewTask &&
              objectsIds[currentIndex] &&
              objectsIds[currentIndex].type === "task" && (
                <Flex gap={4}>
                  <Flex>
                    <Text fontSize={"xs"}>Ctrl/CMD + </Text>
                    <AiOutlineEnter />
                    <Text ml={1} fontSize="xs">
                      Ir
                    </Text>
                  </Flex>

                  <Flex>
                    <AiOutlineEnter />
                    <Text ml={1} fontSize="xs">
                      para iniciar / detener una tarea
                    </Text>
                  </Flex>
                </Flex>
              )}

            {!isCreatingNewTask &&
              objectsIds[currentIndex] &&
              objectsIds[currentIndex].type === "project" && (
                <Flex gap={4}>
                  <Flex>
                    <AiOutlineEnter />
                    <Text ml={1} fontSize="xs">
                      Ir
                    </Text>
                  </Flex>

                  <Flex>
                    <Text fontSize={"xs"}>Ctrl/CMD + </Text>
                    <AiOutlineEnter />
                    <Text ml={1} fontSize="xs">
                      Crear tarea
                    </Text>
                  </Flex>
                </Flex>
              )}

            {isCreatingNewTask && <p>@todo descripción crear tarea</p>}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
