import React, { useEffect, useState } from "react";
import { Customer } from "../../../api";
import { Box, Heading, Text } from "@chakra-ui/react";
import ContentBox from "../../Layout/ContentBox";
import { ReportProjectListItem } from "./ReportProjectListItem";
import { formatSeconds } from "common/dist/util";

interface IReportCustomerListItemProps {
  item: Customer;
}

export const ReportCustomerListItem: React.FC<IReportCustomerListItemProps> = ({
  item,
}) => {
  const [totalTime, setTotalTime] = useState<number>(0);

  useEffect(() => {
    let cont = 0;
    item.projects?.forEach((project) => {
      project.tasks.forEach((task) => {
        task.times.forEach((time) => {
          cont += time.total_time_segs;
        });
      });
    });

    setTotalTime(cont);
  }, [item.projects]);

  return (
    <Box mb={20}>
      <ContentBox>
        <Box>
          <Heading size="xl" marginBottom={10}>
            {item.name}
          </Heading>

          {item.projects?.map((p) => {
            return <ReportProjectListItem item={p} key={`project-${p.id}`} />;
          })}

          <Box p={4} backgroundColor={"gray.600"} mt={4}>
            <Text fontWeight="bold" textColor="gray.50">
              Tiempo total trabajado en cliente {formatSeconds(totalTime)}
            </Text>
          </Box>
        </Box>
      </ContentBox>
    </Box>
  );
};
