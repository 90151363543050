import React from "react";
import { Button, HStack } from "@chakra-ui/react";

type ILoadMoreButtonProps = {
  loadMoreRef: React.MutableRefObject<null>;
  onClick: () => void;
  disabled: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean | undefined;
};
const LoadMoreButton: React.FC<ILoadMoreButtonProps> = ({
  loadMoreRef,
  onClick,
  disabled,
  isFetchingNextPage,
  hasNextPage,
}) => {
  const clicableHoverStyles = {
    cursor: "pointer",
    background: "gray.200",
  };

  const canClick = !disabled && !isFetchingNextPage && !disabled;

  return (
    <HStack justifyContent="center">
      <Button
        textAlign="center"
        py={2}
        mt={4}
        borderRadius="md"
        _hover={canClick ? clicableHoverStyles : {}}
        onClick={() => onClick()}
        ref={loadMoreRef}
        variant="outline"
        fontWeight="normal"
        isLoading={isFetchingNextPage}
        loadingText="Cargando..."
        disabled={disabled}
      >
        {hasNextPage ? "Cagar más datos" : "No hay más datos que cargar"}
      </Button>
    </HStack>
  );
};

export default LoadMoreButton;
