import React from "react";
import { createValidator } from "class-validator-formik";
import { Form, Formik } from "formik";
import { Button, Grid, GridItem, Stack } from "@chakra-ui/react";
import { CustomSelect, CustomTextInput } from "../Forms";
import { UsersCreateWithRepeatPasswordDto } from "./CreateUserWithPasswordRepeat.dto";
import { CreateUserDto } from "../../dto/create-user.dto";
import { ApiClient } from "common";
import { RolUser } from "../../dto/types/rol.type";
import { enunToValuesTranslatedToSelect } from "../../i18n";

interface ProjectFormProps {
  initialValues: CreateUserDto;
  isNewRecord: boolean;
  dto: any;
  onSubmit: (values: UsersCreateWithRepeatPasswordDto) => void;
  mutationLoading: boolean;
}

interface ValidateEmailResponse {
  emailValid: boolean;
}

export const UsersCreateForm: React.FC<ProjectFormProps> = ({
  initialValues,
  dto,
  onSubmit,
  mutationLoading,
  isNewRecord,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, formikHelpers) => {
        const { email } = values;
        try {
          const res = await ApiClient.post<ValidateEmailResponse>(
            "users/validate-email",
            JSON.stringify({ email })
          );

          if (!res.emailValid) {
            formikHelpers.setFieldError(
              "email",
              "El email introducido no puede ser usado. Si el email es correcto, posiblemente exista un usuario con este email."
            );
            return;
          }

          onSubmit(values as unknown as typeof dto);
        } catch (error) {
          // @todo notificar al usuario y ver que hacer.
          console.error(error);
        }
      }}
      validate={createValidator(UsersCreateWithRepeatPasswordDto)}
    >
      {({ isValid }) => (
        <Form>
          <Stack spacing="6">
            <Grid templateColumns="1fr 1fr" gap={2}>
              <GridItem>
                <CustomTextInput
                  label="Nombre"
                  placeholder="Nombre del usuario"
                  name="name"
                  autoComplete="off"
                />
              </GridItem>

              <GridItem>
                <CustomTextInput
                  label="Email del usuario"
                  placeholder="direccion@email.com"
                  name="email"
                  autoComplete="off"
                />
              </GridItem>
            </Grid>

            <Grid templateColumns="1fr 1fr" gap={2}>
              <GridItem>
                <CustomSelect
                  options={enunToValuesTranslatedToSelect(RolUser, "es")}
                  label="Rol"
                  name="rol"
                />
              </GridItem>

              <GridItem>
                <CustomTextInput
                  label="Dni"
                  placeholder="Dni del usuario"
                  name="document"
                  autoComplete="off"
                />
              </GridItem>
            </Grid>

            {isNewRecord && (
              <Grid templateColumns="1fr 1fr" gap={2}>
                <GridItem>
                  <CustomTextInput
                    label="Contraseña"
                    placeholder="********"
                    type="password"
                    name="password"
                    autoComplete="off"
                  />
                </GridItem>

                <GridItem>
                  <CustomTextInput
                    label="Contraseña (repítala de nuevo)"
                    placeholder="********"
                    type="password"
                    name="password_repeat"
                    autoComplete="off"
                  />
                </GridItem>
              </Grid>
            )}

            <Button
              colorScheme="blue"
              size="lg"
              fontSize="md"
              type="submit"
              disabled={!isValid || mutationLoading}
            >
              {mutationLoading ? "Espere" : "Crear"}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
