import { IsNotEmpty, IsString, MaxLength, MinLength } from 'class-validator';

export class TagUpdateDto {
  @MaxLength(45)
  @IsNotEmpty()
  
  title: string;

  @IsString()
  @MaxLength(45)
  @MinLength(3)
  @IsNotEmpty()
  
  background_color: string;

  @IsString()
  @MaxLength(45)
  @MinLength(3)
  @IsNotEmpty()
  
  text_color: string;
}
