import { useCallback, useEffect } from "react";

const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;

/**
 *
 * @param {string} key
 * @param {function} callback
 * @param {boolean} preventDefault
 */
export const useShortcut = (
  key: string,
  callback: () => void,
  preventDefault: boolean = false
) => {
  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    if ((isMac && event.metaKey) || (!isMac && event.altKey)) {
      if (preventDefault) event.preventDefault();
      if (event.key === key) {
        callback();
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, []);
};
