import { Button, Stack, useToast } from "@chakra-ui/react";
import React from "react";
import { Form, Formik } from "formik";
import { createValidator } from "class-validator-formik";
import { CustomTextInput } from ".";
import useAuth from "../../context/auth/auth";
import {IsNotEmpty, MinLength} from "class-validator";
import { Match } from "../../decorators/match";
import { ChangePasswordDto } from "../../dto/change-password.dto";
import { useNavigate } from "react-router-dom";
import constants from "../../constants";

class ChangePasswordWithRepeatPasswordDto extends ChangePasswordDto {
  @IsNotEmpty()
  @Match("password", { message: "Las contraseñas no coinciden" })
  password_repeat: string;
}

/*
 @todo esto está dando error por que CheckTokenDto no existe en el backend y por lo tanto cuando se genera el cliente
 no existe. Lo pongo aquí por que estoy mirando otra cosa.
 De todas formas, un DTO por props no tiene sentido
 */
interface CheckTokenDto {
  id: number;
  token: string;
}

export const ChangePasswordForm = (props: CheckTokenDto) => {
  const auth = useAuth();
  const toast = useToast();
  const navigate = useNavigate();

  const initialValues: ChangePasswordWithRepeatPasswordDto = {
    id: props.id,
    token: props.token,
    password: "",
    password_repeat: "",
  };

  const submitForm = async (values: ChangePasswordWithRepeatPasswordDto) => {
    await auth
      .changePassword(values.id, values.token, values.password)
      .then((r) => {
        if (r) {
          toast({
            title: "Contraseña modificada",
            description: "Su contraseña se ha modficado correctamente.",
            status: "success",
            duration: constants.toastDuration,
            isClosable: true,
          });
          navigate("/", { replace: true });
        }
      })
      .catch((e) => {
        toast({
          title: "Error",
          description:
            "Ha habido un error al recuperar la contraseña. Por favor, vuelva a pinchar en el enlace. Si no funciona, repita el proceso de recuperación de contraseña",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
        console.error(e);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validate={createValidator(ChangePasswordWithRepeatPasswordDto)}
    >
      {(props) => (
        <Form>
          <Stack spacing="6">
            <CustomTextInput
              helperText="Introduzca su contraseña"
              label="Contraseña"
              type="password"
              placeholder=""
              name="password"
              autoComplete="off"
            />

            <CustomTextInput
              helperText="Repita su contraseña"
              label="Contraseña"
              type="password"
              placeholder=""
              name="password_repeat"
              autoComplete="off"
            />

            <Button
              type="submit"
              colorScheme="blue"
              size="lg"
              fontSize="md"
              disabled={
                !(
                  props.isValid &&
                  (props.dirty || Object.keys(props.touched).length)
                ) || props.isSubmitting
              }
            >
              {props.isSubmitting ? "Espere" : "Cambiar contraseña"}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
