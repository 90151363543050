import React from "react";
import { useParams } from "react-router-dom";
import { Project } from "../api";
import { DataFetcher } from "common";
import { ProjectDetail } from "../components/Projects";

export const ProjectsDetailPage: React.FC = (props) => {
  const { id } = useParams();

  return (
    <DataFetcher
      queryKey={["projects", id as unknown as string]}
      ep={`projects/${id}`}
      render={(d: Project) => (
        <>
          <ProjectDetail item={d} />
        </>
      )}
    />
  );
};
