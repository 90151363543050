import React from "react";
import {
  Box,
  Flex,
  HStack,
  IconButton,
  SimpleGrid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Time } from "../../api";
import useAuth from "../../context/auth/auth";
import { formatSeconds } from "common/dist/util";
import { DateTime } from "luxon";
import { FaEdit, FaTrash } from "react-icons/fa";
import { TimesAlertDialogQuickUpdate } from "./TimesAlertDialogQuickUpdate";
import { TimesAlertDialogDelete } from "./TimesAlertDialogDelete";

interface ITimesItemProps {
  item: Time;
  onUpdate: (data: Time) => void;
  onDelete: (timeId: number) => void;
}

export const TimesItem: React.FC<ITimesItemProps> = ({
  item,
  onUpdate,
  onDelete,
}) => {
  const { user } = useAuth();
  const isMine = item.userId === user?.sub;
  const {
    isOpen: isOpenQuickUpdate,
    onOpen: onOpenQuickUpdate,
    onClose: onCloseQuickUpdate,
  } = useDisclosure();
  const {
    isOpen: isOpenQuickDelete,
    onOpen: onOpenQuickDelete,
    onClose: onCloseQuickDelete,
  } = useDisclosure();

  return (
    <Flex alignItems="center" mb={4}>
      <SimpleGrid columns={4} flexGrow={1}>
        <Box>
          <Text fontSize="sm">
            {DateTime.fromSeconds(item.start_time).toFormat(
              "dd-MM-yyyy HH:mm:ss"
            )}
          </Text>
        </Box>
        <Box>
          <Text fontSize="sm">
            {item.end_time &&
              DateTime.fromSeconds(item.end_time).toFormat(
                "dd-MM-yyyy HH:mm:ss"
              )}
          </Text>
        </Box>
        <Box>
          <Text fontSize="sm">{item.user.email}</Text>
        </Box>
        <Box>
          <Text fontSize="sm">
            {item.total_time_segs && formatSeconds(item.total_time_segs)}
          </Text>
        </Box>
      </SimpleGrid>
      <HStack w="74px" h="auto">
        {isMine && item.end_time && (
          <>
            <IconButton
              size="sm"
              aria-label="Eliminar"
              icon={<FaTrash />}
              onClick={onOpenQuickDelete}
            />

            <IconButton
              size="sm"
              aria-label="Modificar"
              icon={<FaEdit />}
              onClick={onOpenQuickUpdate}
            />
          </>
        )}
      </HStack>

      <TimesAlertDialogQuickUpdate
        isOpen={isOpenQuickUpdate}
        onClose={onCloseQuickUpdate}
        onUpdate={onUpdate}
        item={item}
      />

      <TimesAlertDialogDelete
        isOpen={isOpenQuickDelete}
        onClose={onCloseQuickDelete}
        onDelete={onDelete}
        item={item}
      />
    </Flex>
  );
};
