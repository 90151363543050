import React from "react";
import { Expense } from "../../api";
import { Box, Flex, Text } from "@chakra-ui/react";
import {priceToHtml} from '../../util';

interface IExpensesListItemSummaryProps {
  items: Expense[];
}

export const ExpensesListItemSummary: React.FC<
  IExpensesListItemSummaryProps
> = ({ items }) => {
  const color = "gray.500";
  return (
    <Flex>
      <Box width="50%">
        <Text textColor={color}>Total</Text>
      </Box>
      <Box>
        <Text textColor={color}>
          {priceToHtml(items.reduce((prev, next) => prev + next.amount, 0))}
        </Text>
      </Box>
    </Flex>
  );
};
