import {
  IsEmail,
  IsNotEmpty,
  IsString,
  MaxLength,
  MinLength,
} from 'class-validator';

export class SignUpDto {
  @IsString()
  @IsNotEmpty()
  @MinLength(5)
  @MaxLength(255)
  company_name: string;

  @IsNotEmpty()
  @MaxLength(255, { message: 'nombre: longitud máxima de 255' })
  name: string;

  @IsNotEmpty()
  @IsEmail()
  email: string;

  // ToDo :: Validar algun formato de contraseña? x numeros, mayúsculas, mínusuclas, símbolos y números...
  @IsNotEmpty()
  @MinLength(6)
  password: string;
}
