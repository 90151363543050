import React from "react";
import { Time } from "../../api";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { ApiClient } from "common";
import constants from "../../constants";
import { DateTime } from "luxon";

interface ITimesAlertDialogDeleteProps {
  isOpen: boolean;
  onClose: () => void;
  item: Time;
  onDelete: (timeId: number) => void;
}

export const TimesAlertDialogDelete: React.FC<ITimesAlertDialogDeleteProps> = ({
  isOpen,
  onClose,
  item,
  onDelete,
}) => {
  const cancelRef = React.useRef(null);
  const toast = useToast();

  const deleteMutation = useMutation(
    () => ApiClient.delete(`times/${item.id}`),
    {
      onSuccess: () => {
        toast({
          title: "Correcto",
          description: "El tiempo ha sido eliminado correctamente.",
          status: "success",
          duration: constants.toastDuration,
          isClosable: true,
        });
        onDelete(item.id);
        onClose();
      },
    }
  );

  const initialTime = DateTime.fromSeconds(item.start_time).toFormat(
    "dd-MM-yyyy HH:mm:ss"
  );
  const endTime =
    item.end_time &&
    " a " + DateTime.fromSeconds(item.end_time).toFormat("dd-MM-yyyy HH:mm:ss");

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Eliminar tiempo
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>
              ¿Deseas eliminar el tiempo de {initialTime} a {endTime}?
            </Text>
            <Text>Esta acción no se podrá deshacer</Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                deleteMutation.mutate();
              }}
              ml={3}
            >
              Eliminar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
