import React from "react";
import { Flex, Text } from "@chakra-ui/react";

interface IDevelopmentWarningProps {}

export const DevelopmentWarning: React.FC<IDevelopmentWarningProps> = () => {
  if (process.env.NODE_ENV !== "development") return null;

  return (
    <Flex
      position="fixed"
      width="100px"
      height="50px"
      left="50%"
      ml="-50px"
      top="10px"
      backgroundColor="tomato"
      justifyContent="center"
      alignItems="center"
    >
      <Text color="white">Desarrollo</Text>
    </Flex>
  );
};
