import React from "react";
import { Flex, useColorModeValue } from "@chakra-ui/react";

/**
 * Componente genérico para hacer de wrapper en los listados de las tablas, a modo fila.
 * Es el que debería de usarse en todos los listados que originalmente eran tablas
 *
 * @param children
 * @constructor
 */
const ListItemWrapper: React.FC = ({ children }) => {
  return (
    <Flex
      alignItems="center"
      borderLeft="1px"
      borderRight="1px"
      borderBottom="1px"
      borderColor={useColorModeValue("gray.200", "gray.600")}
      borderRadius="4px"
      p={2}
      _hover={{
        backgroundColor: useColorModeValue("gray.200", "gray.600"),
      }}
    >
      {children}
    </Flex>
  );
};

export default ListItemWrapper;
