import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Routes} from "../../routes";
import {HStack, MenuItem, Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure,} from "@chakra-ui/react";
import {Project, Task, UserEntity} from "../../api";
import {ApiClient, DataFetcher} from "common";
import {Modal, ModalBody, ModalHeader} from "../Modal";
import {RequestQueryBuilder} from "@nestjsx/crud-request";
import {UsersUpdateNameForm} from "./UsersUpdateNameForm";
import {ProjectQuickList} from "../Projects/ProjectQuickList";
import {RiFolderForbidLine} from "react-icons/ri";
import {useMutation} from "@tanstack/react-query";
import {ProjectUnassignUserDto} from "../../dto/project-unassign_user.dto";
import {TasksQuickList} from "../Tasks";
import {Helmet} from "react-helmet";
import {ModalColumn} from "../Modal/ModalColumn";
import {UserAlertDialogUnassignProject} from './UserAlertDialogUnassignProject';
import {UserAlertDialogUnassignTask} from './UserAlertDialogUnassignTask';

export const UsersUpdate = () => {
  const backTo = Routes.USERS + "?sr=false";
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    isOpen: isOpenUnassignProject,
    onOpen: onOpenUnassignProject,
    onClose: onCloseUnassignProject,
  } = useDisclosure();

  const {
    isOpen: isOpenUnassignTask,
    onOpen: onOpenUnassignTask,
    onClose: onCloseUnassignTask,
  } = useDisclosure();



  const requestQueryBuilderProyectos = new RequestQueryBuilder();
  const requestQueryBuilderTareas = new RequestQueryBuilder();
  requestQueryBuilderProyectos.search({
    "assigned_users.id": id,
    closed: false,
  });

  requestQueryBuilderTareas.search({
    "assigned_users.id": id,
    closed: false,
  });

  const unassignProjectMutationData: ProjectUnassignUserDto = {
    userId: id as unknown as number,
  };

  const unassignProjectMutation = useMutation<Project, unknown, number>(
    (projectId) =>
      ApiClient.post<Project>(
        `projects/unassign-user/${projectId}`,
        JSON.stringify(unassignProjectMutationData)
      )
  );

  const unnasignTaskMutation = useMutation<Task, unknown, number>((projectId) =>
    ApiClient.post<Task>(
      `tasks/unassign-user/${projectId}`,
      JSON.stringify(unassignProjectMutationData)
    )
  );

  const unassignProject = (id: number) => {
    unassignProjectMutation.mutate(id);
  }

  const unassignTask = (id: number) => {
    unnasignTaskMutation.mutate(id);
  }

  return (
    <Modal>
      <DataFetcher
        queryKey={["users", id]}
        ep={`users/${id}`}
        render={(d: UserEntity) => (
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Project15 - Usuarios - {d.name}</title>
            </Helmet>

            <ModalHeader onClose={() => navigate(backTo)}>
              <HStack spacing={2}>
                <span>Modificar Usuario {d.name}</span>
              </HStack>
            </ModalHeader>
            <ModalBody columns={2}>
              <ModalColumn borderRightWidth="1px">
                <UsersUpdateNameForm item={d} />
              </ModalColumn>

              <ModalColumn>
                <Tabs>
                  <TabList>
                    <Tab className="noOutline">Proyectos</Tab>
                    <Tab className="noOutline">Tareas</Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel>
                      <ProjectQuickList
                        requestQueryBuilder={requestQueryBuilderProyectos}
                        extraButtons={(item) => (
                          <>
                            <MenuItem
                              icon={<RiFolderForbidLine />}
                              onClick={onOpenUnassignProject}
                            >
                              Desasignar
                            </MenuItem>

                            <UserAlertDialogUnassignProject
                              isOpen={isOpenUnassignProject}
                              onClose={onCloseUnassignProject}
                              onUnassign={unassignProject}
                              project={item}
                              user={d}
                            />

                          </>
                        )}
                      />
                    </TabPanel>

                    <TabPanel>
                      <TasksQuickList
                        requestQueryBuilder={requestQueryBuilderTareas}
                        extraButtons={(item: Task) => (
                          <>
                            <MenuItem
                              icon={<RiFolderForbidLine />}
                              onClick={onOpenUnassignTask}
                            >
                              Desasignar
                            </MenuItem>

                            <UserAlertDialogUnassignTask
                              isOpen={isOpenUnassignTask}
                              onClose={onCloseUnassignTask}
                              onUnassign={unassignTask}
                              task={item}
                              user={d}
                            />
                          </>
                        )}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </ModalColumn>
            </ModalBody>
          </>
        )}
      />
    </Modal>
  );
};
