import React from "react";
import { Task } from "../../api";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Text,
  useDisclosure, useToast,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FiMoreHorizontal } from "react-icons/fi";
import { FaTrash } from "react-icons/fa";
import { IoMdCheckmark } from "react-icons/io";
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {TaskUpdateDto} from '../../dto/task-update.dto';
import {ApiClient} from 'common';
import constants from '../../constants';

interface SubTasksListItemProps {
  item: Task;
  onDelete: (item: Task) => void;
}

export const SubTasksListItem: React.FC<SubTasksListItemProps> = ({
  item,
  onDelete,
}) => {
  const cancelRef = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const queryClient = useQueryClient();

  const {
    isOpen: isOpenClose,
    onOpen: onOpenClose,
    onClose: onCloseClose
  } = useDisclosure();


  const mutation = useMutation<Task, unknown, TaskUpdateDto>(
    (d) => ApiClient.patch<Task>(`tasks/${item.id}`, JSON.stringify(d)),
    {
      onError: () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onSuccess: async (data) => {
        await queryClient.refetchQueries([
          `infsc_tasks_project_${item.projectId}_parent_${item.parentId}`,
        ]);

        toast({
          title: data.closed ? "Tarea cerrada" : "Tarea abierta",
          description: data.closed
            ? `La tarea ${data.title} se ha cerrado correctamente`
            : `La tarea ${data.title} se ha vuelto a abrir correctamente`,
          status: "success",
          duration: constants.toastDuration,
          isClosable: true,
        });
      }
    }
  );

  const closeSubTask = () => {
    const task: TaskUpdateDto = { ...item, closed: true };
    mutation.mutate(task);
  };

  const reopenSubTask = () => {
    const task: TaskUpdateDto = { ...item, closed: false };
    mutation.mutate(task);
  };

  return (
    <Flex alignItems="center">
      <SimpleGrid columns={2} flexGrow={1} alignItems="center">
        <Box>
          <Text>
            <Link to={item.id.toString()}>{item.title}</Link>
          </Text>
        </Box>
        <Box>
          <Text isTruncated={true}>{item.description}</Text>
        </Box>
      </SimpleGrid>

      <Box width={6} flexShrink={0}>
        <Menu placement="bottom-end">
          <MenuButton
            as={IconButton}
            aria-label="Ordenar"
            size="xs"
            variant="ghost"
            icon={<FiMoreHorizontal />}
          />
          <MenuList alignItems="flex-end">
            <MenuItem icon={<IoMdCheckmark />} onClick={onOpenClose}>
              {item.closed ? 'Abrir' : 'Cerrar'}
            </MenuItem>
            <MenuItem icon={<FaTrash />} onClick={onOpen}>
              Borrar
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>



      <AlertDialog
        isOpen={isOpenClose}
        leastDestructiveRef={cancelRef}
        onClose={onCloseClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {item.closed ? 'Abrir Subtarea' : 'Cerrar Subtarea'}
            </AlertDialogHeader>

            <AlertDialogBody>
              {item.closed ? `¿Deseas abrir la subtarea ${item.title}?` : `¿Deseas cerrar la subtarea ${item.title}?`}

            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseClose}>
                Cancelar
              </Button>
              <Button
                colorScheme={item.closed ? "green" : "red" }
                onClick={() => {
                  item.closed ? reopenSubTask() : closeSubTask();
                  onCloseClose();
                }}
                ml={3}
              >
                {item.closed ? `Si, abrir` : `Si, cerrar`}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>


      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Borrar Tarea
            </AlertDialogHeader>

            <AlertDialogBody>
              ¿Deseas eliminar la tarea {item.title}? Esta acción no se puede deshacer.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onDelete(item);
                  onClose();
                }}
                ml={3}
              >
                Si, borrar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};
