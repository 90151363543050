import React from "react";
import { GridItem } from "@chakra-ui/react";

interface IModalColumnProps {
  borderRightWidth?: string;
}

export const ModalColumn: React.FC<IModalColumnProps> = ({
  children,
  borderRightWidth,
}) => (
  <GridItem
    w="100%"
    maxHeight="100%"
    overflowY="auto"
    py={4}
    px={4}
    borderRightWidth={borderRightWidth ? borderRightWidth : "0px"}
    borderBottomColor="gray.300"
  >
    {children}
  </GridItem>
);

//todo borrar cuando termine el diseño por si falta alguna clase
// <GridItem w='100%' maxHeight="100%" overflowY="scroll" overflowX="hidden" className="hasScroll">
