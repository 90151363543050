import { IsEnum, IsNotEmpty } from 'class-validator';
import { CommentParentEnum } from './types/comment.parent.type';

export class CommentCreateDto {
  @IsNotEmpty()
  
  text: string;

  @IsNotEmpty()
  
  @IsEnum(CommentParentEnum)
  parent_type: CommentParentEnum;

  @IsNotEmpty()
  
  parent_id: number;
}
