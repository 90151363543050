import { mode } from "@chakra-ui/theme-tools";

export const globalStyles = {
  colors: {
    gray: {
      700: "#1f2733",
    },
  },
  styles: {
    header: {
      height: 50,
      display: "flex",
      borderBottom: "1px",
    },
    sidebar: {
      borderRight: "1px",
    },
    mainWrapper: {
      height: "100%",
      display: "flex",
    },
    mainContent: {
      display: "flex",
      flexGrow: 1,
      overflow: "hidden",
    },
    main: {
      background: "#F2F3F7",
      padding: "20px 20px 40px",
    },

    global: (props) => ({
      body: {
        bg: mode("#FAFBFC", "gray.900")(props),
        fontFamily: "Helvetica, sans-serif",
      },
      html: {
        fontFamily: "Helvetica, sans-serif",
      },
      mainContent: {
        bg: mode("red", "tomato")(props),
      },
    }),
  },
};
