import {
  IsEmail,
  IsNotEmpty,
  IsOptional,
  IsString,
  MaxLength,
} from 'class-validator';
import { RolUser } from './types/rol.type';

export class UsersUpdateDto {
  @IsString()
  @MaxLength(100)
  @IsNotEmpty()
  
  name: string;

  @IsNotEmpty()
  @IsEmail()
  
  email: string;

  @IsString()
  @IsOptional()
  @MaxLength(9)
  
  document: string;

  
  @IsNotEmpty()
  rol: RolUser;
}
