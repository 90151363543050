import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Project } from '../../api';
import { ApiClient } from 'common';
import { Box, Input, useToast } from '@chakra-ui/react';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import Select from 'react-select';
import constants from '../../constants';
import { useDebounce } from 'use-debounce';

interface IProjectsSelectableDropDown {
  onChange: (item: selectItem) => void;
  value: number;
}

interface selectItem {
  value: number;
  label: string;
}

/**
 * @todo implementar Select Value
 * @constructor
 */

export const ProjectsSelectableDropDown: React.FC<
  IProjectsSelectableDropDown
> = ({ onChange, value }) => {
  const request = RequestQueryBuilder.create();
  request.sortBy({
    field: 'name',
    order: 'ASC',
  });

  const toast = useToast();

  const [search, setSearch] = useState('');
  const [searchDebounced] = useDebounce(search, 500);

  const [parsedData, setParsedData] = useState<selectItem[]>();

  const { data, isLoading } = useQuery<Project[]>(
    ['projects', searchDebounced],
    () => {
      request.search({
        name: {
          $cont: search,
        },
      });
      return ApiClient.get<Project[]>('projects', request);
    },
    {
      onError: () => {
        toast({
          title: 'Error al recuperar los datos',
          description:
            'Ha habido un error al recuperar los datos de los clientes. Refresca la página en unos minutos y vuelve a intentarlo.',
          status: 'error',
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      enabled: search.length > 0,
    },
  );

  useEffect(() => {
    if (!data) return;
    setParsedData(
      data?.map((project) => ({
        value: project.id,
        label: project.name,
      })) || [],
    );
  }, [data]);

  // if (isLoading) return <span>Cargando...</span>;

  return (
    <Box maxWidth="300px">
      <Select
        onInputChange={(value, action) => {
          if (action.action === 'input-change') setSearch(value);
        }}
        onChange={(a) => {
          onChange(a as unknown as selectItem);
        }}
        options={parsedData}
        value={parsedData?.filter((item) => item.value === value)}
        isClearable={false}
        placeholder="Escriba proyecto"
      />
    </Box>
  );
};
