import React from "react";
import {
  Box,
  Divider,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Logo } from "../components/Logo";
import { Link } from "react-router-dom";
import { Card } from "../components/Card";
import { Routes } from "../routes";
import { Helmet } from "react-helmet";
import { RecoverPasswordForm } from "../components/Forms/RecoverPasswordForm";

export const RecoverPasswordPage: React.FC = () => {
  const boxBG = useColorModeValue("gray.50", "inherit");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Project15 - Recuperar contraseña</title>
      </Helmet>

      <Box bg={boxBG} minH="100vh" py="12" px={{ base: "4", lg: "8" }}>
        <Box maxW="md" mx="auto">
          <Logo mx="auto" h="16" mb={{ base: "10", md: "20" }} />
          <Heading textAlign="center" size="xl" fontWeight="extrabold">
            Recupera tu contraseña
          </Heading>
          <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
            <Text as="span">¿No tienes una cuenta? </Text>
            <Link to={Routes.USER_REGISTER}>Regístrate</Link>
          </Text>
          <Card>
            <RecoverPasswordForm />

            <Divider mt="6" mb={4} />
            <Box textAlign="center">
              <Text color="gray.600">
                <Link to={Routes.USER_LOGIN}>Volver</Link>
              </Text>
            </Box>
          </Card>
        </Box>
      </Box>
    </>
  );
};
