import React from "react";
import { Payment } from "../../api";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { useMutation } from "@tanstack/react-query";
import { ApiClient } from "common";
import { createValidator } from "class-validator-formik";
import { Form, Formik } from "formik";
import { CustomTextInput } from "../Forms";
import { PaymentUpdateDto } from "../../dto/payment-update.dto";
import constants from "../../constants";
import { MdOutlineDescription } from "react-icons/md";
import {priceToHtml} from '../../util';

interface IPaymentListItemProps {
  item: Payment;
  onDelete: (id: number) => void;
  onUpdate: (payment: Payment) => void;
}

export const PaymentListItem: React.FC<IPaymentListItemProps> = ({
  item,
  onDelete,
  onUpdate,
}) => {
  const toast = useToast();
  const cancelDeleteRef = React.useRef(null);
  const cancelUpdateRef = React.useRef(null);

  const {
    isOpen: isOpenDeleted,
    onOpen: onOpenDeleted,
    onClose: onCloseDeleted,
  } = useDisclosure();
  const {
    isOpen: isOpenUpdated,
    onOpen: onOpenUpdated,
    onClose: onCloseUpdated,
  } = useDisclosure();

  const deleteMutation = useMutation(
    (id: number) => ApiClient.delete(`payments/${id}`),
    {
      onSuccess: async (data, variables) => {
        onCloseDeleted();
        onDelete(variables as unknown as number);
      },
      onError: () => {
        toast({
          title: "Error accediendo al servidor",
          description:
            "Ha habido un error accediendo al servidor y los datos no se han guardado. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  const updateMutation = useMutation<Payment, unknown, PaymentUpdateDto>(
    (d) => ApiClient.patch<Payment>(`payments/${item.id}`, JSON.stringify(d)),
    {
      onError: () => {
        toast({
          title: "Error accediendo al servidor",
          description:
            "Ha habido un error accediendo al servidor y los datos no se han guardado. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onSuccess: async (data) => {
        onUpdate(data);
        onCloseUpdated();
      },
    }
  );

  return (
    <Flex mb={1} borderBottomWidth="1px" borderBottomColor="gray.200">
      <Box width="50%">
        {item.description ? (
          <Tooltip label={item.description}>
            <Flex alignItems={"center"}>
              {item.concept}
              <Icon as={MdOutlineDescription} ml={1} color={"gray.500"} />
            </Flex>
          </Tooltip>
        ) : (
          <Text>{item.concept}</Text>
        )}
      </Box>
      <Box>
        <Text>{priceToHtml(item.amount)}</Text>
      </Box>
      <Box ml="auto">
        <IconButton
          variant="ghost"
          aria-label="Eliminar Cobro"
          icon={<AiFillEdit />}
          onClick={onOpenUpdated}
        />

        <IconButton
          variant="ghost"
          aria-label="Eliminar Cobro"
          icon={<AiFillDelete />}
          onClick={onOpenDeleted}
        />
      </Box>

      <AlertDialog
        isOpen={isOpenDeleted}
        leastDestructiveRef={cancelDeleteRef}
        onClose={onCloseDeleted}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Borrar Cobro
            </AlertDialogHeader>

            <AlertDialogBody>
              ¿Deseas eliminar el cobro {item.concept} de {item.amount}€? Esta
              acción no se puede deshacer.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelDeleteRef} onClick={onCloseDeleted}>
                Cancelar
              </Button>
              <Button
                isLoading={deleteMutation.isLoading}
                colorScheme="red"
                onClick={() => deleteMutation.mutate(item.id)}
                ml={3}
              >
                Si, borrar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isOpenUpdated}
        leastDestructiveRef={cancelUpdateRef}
        onClose={onCloseUpdated}
      >
        <AlertDialogOverlay>
          <Formik
            initialValues={{
              concept: item.concept,
              description: item.description,
              amount: item.amount,
              projectId: 21,
            }}
            onSubmit={(values) => {
              updateMutation.mutate(values);
            }}
            validate={createValidator(PaymentUpdateDto)}
          >
            {(props) => (
              <Form>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Modificar Cobro
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    <CustomTextInput
                      label="Concepto"
                      placeholder=""
                      name="concept"
                      autoComplete="off"
                      marginBottom={2}
                    />

                    <CustomTextInput
                      label="Descripción"
                      placeholder=""
                      name="description"
                      autoComplete="off"
                      marginBottom={2}
                    />

                    <CustomTextInput
                      label="Total"
                      type="number"
                      placeholder=""
                      name="amount"
                      autoComplete="off"
                    />
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button ref={cancelUpdateRef} onClick={onCloseUpdated}>
                      Cancelar
                    </Button>
                    <Button
                      isLoading={deleteMutation.isLoading}
                      colorScheme="green"
                      type="submit"
                      ml={3}
                      disabled={
                        !(
                          props.isValid &&
                          (props.dirty || Object.keys(props.touched).length)
                        ) || updateMutation.isLoading
                      }
                    >
                      Guardar
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </Form>
            )}
          </Formik>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};
