import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import {Project, UserEntity} from "../../api";

interface IUserAlertDialogUnassignProjectProps {
  isOpen: boolean;
  onClose: () => void;
  onUnassign: ( id: number ) => void;
  project: Project;
  user: UserEntity;
}

export const UserAlertDialogUnassignProject: React.FC<IUserAlertDialogUnassignProjectProps> = ({
   isOpen,
   onClose,
   onUnassign,
   project,
   user
 }) => {
  const closeRef = React.useRef(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={closeRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Desasignar un proyecto
          </AlertDialogHeader>

          <AlertDialogBody>
            ¿Deseas desasignar el proyecto {project.name} al usuario {user.name}?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={closeRef} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                onUnassign(project.id);
                onClose();
              }}
              ml={3}
            >
              Si, desasignar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
