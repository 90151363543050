import React from 'react';
import { Expense, Payment, Project, Tag, UserEntity } from '../../api';
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { TasksList } from '../Tasks';

import { MultipleFiles } from '../Uploader';
import { UploadTypeEnum } from '../../dto/types/UploadType.type';
import { CommentParentEnum } from '../../dto/types/comment.parent.type';
import { Comments } from '../Comments';
import { UsersAvatars } from '../Users';
import { ApiClient } from 'common';
import { Can } from '../../context/can';
import { ProductsWidget } from '../Products';
import { ProductLineParentEnum } from '../../dto/types/product-line.parent.type';
import useSetBreadcrumbs from '../BreadCrumbsHeader/useSetBreadcrumbs';
import { Routes } from '../../routes';
import { Helmet } from 'react-helmet';
import { ExpensesWidget } from '../Expenses';
import { useQueryClient } from '@tanstack/react-query';
import { TagsSelector } from '../Tags/TagsSelector';
import { PaymentsWidget } from '../Payments';
import ContentBox from '../Layout/ContentBox';
import { formatSeconds } from 'common/dist/util';
import { ButtonDownload } from '../ButtonDownload';
import { ProjectTimeChart } from './ProjectTimeChart';
import { LogWidget } from '../Log';
import { LogEntityType } from '../../dto/types/log.type';
import { useNavigate } from 'react-router-dom';

interface IProjectDetailProps {
  item: Project;
}

export const ProjectDetail: React.FC<IProjectDetailProps> = ({ item }) => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const updateUrl = `/projects/update/${item.id}`;

  const onUsersSelected = (users: UserEntity[]) => {
    ApiClient.post(
      `projects/assign-users/${item.id}`,
      JSON.stringify({ assigned_users: users.map((user) => user.id) }),
    );
  };

  const onTagsSelected = (tags: Tag[]) => {
    ApiClient.post(
      `projects/assign-tags/${item.id}`,
      JSON.stringify({ assigned_tags: tags.map((tag) => tag.id) }),
    );
  };

  useSetBreadcrumbs([
    {
      to: '/',
      label: 'Home',
    },
    {
      to: Routes.PROJECTS,
      label: 'Proyectos',
    },
    {
      label: item.name,
    },
  ]);

  const onExpenseAdd = (expense: Expense) => {
    const newData = { ...item };
    newData.expenses = [...newData.expenses, expense];
    queryClient.setQueryData(['projects', item.id.toString()], newData);
  };

  const onExpenseDeleted = (id: number) => {
    const newData = { ...item };
    newData.expenses = newData.expenses.filter((expense) => expense.id !== id);
    queryClient.setQueryData(['projects', item.id.toString()], newData);
  };

  const onExpenseUpdated = (expense: Expense) => {
    const newData = { ...item };
    newData.expenses = newData.expenses.map((ex) => {
      if (expense.id === ex.id) return expense;
      return ex;
    });
    queryClient.setQueryData(['projects', item.id.toString()], newData);
  };

  const onPaymentAdd = (payment: Payment) => {
    const newData = { ...item };
    newData.payments = [...newData.payments, payment];
    queryClient.setQueryData(['projects', item.id.toString()], newData);
  };

  const onPaymentDeleted = (id: number) => {
    const newData = { ...item };
    newData.payments = newData.payments.filter((payment) => payment.id !== id);
    queryClient.setQueryData(['projects', item.id.toString()], newData);
  };

  const onPaymentUpdated = (payment: Payment) => {
    const newData = { ...item };
    newData.payments = newData.payments.map((ex) => {
      if (payment.id === ex.id) return payment;
      return ex;
    });
    queryClient.setQueryData(['projects', item.id.toString()], newData);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Project15 - Proyectos - {item.name}</title>
      </Helmet>

      <ContentBox>
        <Flex>
          <HStack flexShrink={0} alignItems={'flex-end'}>
            <Heading flexShrink={0}>{item.name}</Heading>
            <Heading size="md">{item.customer.name}</Heading>
            <ButtonDownload
              download={() => ApiClient.get(`projects/${item.id}/pdf`)}
              dstFilename={`${item.name}.pdf`}
            >
              Descargar
            </ButtonDownload>
            <Button onClick={() => navigate(updateUrl)}>Editar</Button>
          </HStack>

          <Box ml="auto">
            <HStack justifyContent="space-between" mb={4}>
              <Can I="create" a="ProjectUsers">
                <UsersAvatars
                  selectable={true}
                  currentUsers={item.assigned_users}
                  onBlur={onUsersSelected}
                />
              </Can>
            </HStack>

            <HStack justifyContent="end">
              <TagsSelector
                selectable={true}
                currentTags={item.assigned_tags}
                onBlur={onTagsSelected}
              />
            </HStack>
            {item.timeTotal > 0 && (
              <Box textAlign="right" mt={4}>
                <Text fontSize="sm">
                  {formatSeconds(item.timeTotal)} horas totales trabajadas
                </Text>
              </Box>
            )}
          </Box>
        </Flex>
      </ContentBox>

      <ContentBox>
        <Grid templateColumns="repeat(2, 1fr)" gap={4} height="100%">
          <GridItem
            w="100%"
            maxHeight="100%"
            py={4}
            px={4}
            borderRightWidth="1px"
            borderBottomColor="gray.300"
          >
            <TasksList projectId={item.id} />
          </GridItem>

          <GridItem w="100%" maxHeight="100%" px={4}>
            <Tabs>
              <TabList>
                <Tab className="noOutline">Comentarios</Tab>
                <Tab className="noOutline">Gastos</Tab>
                <Tab className="noOutline">Productos</Tab>
                <Tab className="noOutline">Ficheros</Tab>
                <Tab className="noOutline">Cobros</Tab>
                <Tab className="noOutline">Registro</Tab>
                <Tab className="noOutline">Gráficos</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Comments
                    type={CommentParentEnum.project}
                    parentId={item.id}
                  />
                </TabPanel>

                <TabPanel>
                  <ExpensesWidget
                    parentId={item.id}
                    items={item.expenses}
                    onCreate={onExpenseAdd}
                    onDelete={onExpenseDeleted}
                    onUpdate={onExpenseUpdated}
                  />
                </TabPanel>

                <TabPanel>
                  <ProductsWidget
                    parentId={item.id}
                    type={ProductLineParentEnum.project}
                    products={item.products}
                  />
                </TabPanel>

                <TabPanel>
                  <MultipleFiles id={item.id} type={UploadTypeEnum.PROJECT} />
                </TabPanel>

                <TabPanel>
                  <PaymentsWidget
                    parentId={item.id}
                    items={item.payments}
                    onCreate={onPaymentAdd}
                    onDelete={onPaymentDeleted}
                    onUpdate={onPaymentUpdated}
                  />
                </TabPanel>
                <TabPanel>
                  <LogWidget
                    entity={LogEntityType.Project}
                    entityId={item.id}
                  />
                </TabPanel>
                <TabPanel>
                  <ProjectTimeChart projectId={item.id} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </GridItem>
        </Grid>
      </ContentBox>
    </>
  );
};
