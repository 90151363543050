import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ApiClient } from "common";
import { DateTime } from "luxon";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Checkbox, VStack } from "@chakra-ui/react";
import { ProjectTimesResult } from "../../dto/types/projects.type";

interface IProjectTimeChartProps {
  projectId: number;
}

interface GroupedDataByDate {
  [key: string]: ProjectTimesResult[];
}

interface ParsedData {
  date: string;
  time: string; // es un string por que queremos mostrar los redondeos.
}

export const ProjectTimeChart: React.FC<IProjectTimeChartProps> = ({
  projectId,
}) => {
  const { data, isLoading } = useQuery(["project_times", projectId], () =>
    ApiClient.get<ProjectTimesResult[]>(`projects/${projectId}/times`)
  );
  const [parsedData, setParsedData] = useState<ParsedData[]>([]);
  const [mostrarFechasSinTiempos, setMostrarFechasSinTiempos] = useState(true);

  useEffect(() => {
    console.log("useEffect");
    // @todo esta lógica estaría mejor en el backend antes de parsearlo en el cliente.
    if (isLoading) return;

    const groupedData: GroupedDataByDate = data!.reduce((groups, item) => {
      const date = DateTime.fromSeconds(item.start_time).toFormat("dd/MM/yyyy");
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(item);
      return groups;
    }, {} as GroupedDataByDate);

    const minDate = DateTime.fromSeconds(
      Math.min(...data!.map((item) => item.start_time))
    ).setZone("America/New_York");

    const maxDate = DateTime.fromSeconds(
      Math.max(...data!.map((item) => item.start_time))
    ).setZone("America/New_York");

    const allDates = [];
    let currentDate = minDate;
    while (currentDate <= maxDate) {
      allDates.push(currentDate.toFormat("dd/MM/yyyy"));
      currentDate = currentDate.plus({ days: 1 });
    }

    const chartData = Object.entries(groupedData).map(([date, items]) => {
      const totalDifference = items.reduce(
        (sum, item) => sum + (item.end_time - item.start_time),
        0
      );
      return { date, time: (totalDifference / 3600).toFixed(2) };
    });

    if (mostrarFechasSinTiempos) {
      const chartDataWithAllDates = allDates.map((date) => {
        const val = chartData.find((d) => {
          return d.date === date;
        });

        return val
          ? val
          : {
              date,
              time: "0",
            };
      });
      setParsedData(chartDataWithAllDates);
    } else setParsedData(chartData);
  }, [data, mostrarFechasSinTiempos, isLoading]);

  if (isLoading) return <p>Cargando...</p>;

  return (
    <VStack>
      <LineChart width={500} height={250} data={parsedData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" textAnchor="end" style={{ fontSize: 12 }} />
        <YAxis />
        <Tooltip />
        <Line type="monotone" dataKey="time" stroke="#8884d8" dot={false} />
      </LineChart>
      <Checkbox
        onChange={(e) => setMostrarFechasSinTiempos(e.target.checked)}
        isChecked={mostrarFechasSinTiempos}
      >
        Mostrar fechas sin tiempos
      </Checkbox>
    </VStack>
  );
};
