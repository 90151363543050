import React from "react";
import { Box } from "@chakra-ui/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleSocialLogin } from "./GoogleSocialLogin";
import { FacebookSocialLogin } from "./FacebookSocialLogin";

interface ISocialLoginsProps {}
export const SocialLogins: React.FC<ISocialLoginsProps> = () => {
  return (
    <GoogleOAuthProvider clientId="578057562176-e8suudr01gaam80sn4d9n9k2av4u4kbi.apps.googleusercontent.com">
      <Box>
        <GoogleSocialLogin />
        <FacebookSocialLogin />
      </Box>
    </GoogleOAuthProvider>
  );
};
