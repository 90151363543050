import React from "react";
import { Flex } from "@chakra-ui/react";

export const Modal: React.FC = ({ children }) => {
  return (
    <Flex
      id="modalBackdrop"
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      backgroundColor={"rgba(0,0,0,.6)"}
      padding="40px 60px"
      flexDirection="column"
      zIndex={100}
    >
      <Flex
        id="modalContent"
        backgroundColor="white"
        overflow="hidden"
        boxShadow="2xl"
        height="100%"
        flexDirection="column"
        borderRadius="md"
      >
        {children}
      </Flex>
    </Flex>
  );
};
