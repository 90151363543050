import {IsEmail, IsNotEmpty, IsString, MaxLength, MinLength} from 'class-validator';
import { RolUser } from './types/rol.type';

export class CreateUserDto {
  @IsString()
  @MaxLength(100)
  @IsNotEmpty()
  
  name: string;

  @IsNotEmpty()
  @IsEmail()
  
  email: string;

  
  @IsNotEmpty({ message: 'la contraseña del usuario no puede estar vacía' })
  @MinLength(6)
  password: string;

  @IsString()
  @MaxLength(9)
  
  document: string;

  
  @IsNotEmpty()
  rol: RolUser;
}
