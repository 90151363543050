import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import constants from "../../constants";
import useAuth from "../../context/auth/auth";
import { Avatar, Box, Icon, Spinner, useToast } from "@chakra-ui/react";
import { FiCamera, FiCameraOff } from "react-icons/fi";
import useImageJwt from "../ImageJWT/useImageJwt";

const upload = (
  modelId: number,
  file: any,
  accessToken: string,
  type: string
) => {
  const formData = new FormData();

  formData.append("file", file);
  // Object.keys(data).forEach((key) => formData.append(key, data[key]));

  return fetch(`${constants.apiUrl}${type}/avatar/${modelId}`, {
    method: "POST",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: formData,
  });
};

interface IAvatarUploaderProps {
  modelId: number;
  defaultText: string;
  image?: string | null;
  onImageUploadSuccess: (image: string) => void;
  type: "customers" | "users" | "companies";
}

export const AvatarUploader: React.FC<IAvatarUploaderProps> = ({
  defaultText,
  image,
  onImageUploadSuccess,
  modelId,
  type,
}) => {
  const [uploading, setUploading] = useState(false);
  const { access_token } = useAuth();

  const { image: imageBlob } = useImageJwt(image as string);
  const toast = useToast();

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { "image/*": [] },
      multiple: false,
      onDrop: (files: File[]) => {
        if (files.length === 0) return;

        setUploading(true);
        // @todo el nombre
        upload(modelId, files[0], access_token, type)
          .then((d) => {
            if (d.status === 201) {
              return d.json();
            }

            throw new Error(d.status.toString());
          })
          .then((d) => {
            setUploading(false);
            onImageUploadSuccess(d.url);
            console.log(d);
          })
          .catch((error) => {
            toast({
              title: "Error subiendo la imagen",
              description:
                "Ha habido un error subiendo la imagen. Por favor, inténtelo de nuevo en unos minutos.",
              status: "error",
              duration: constants.toastDuration,
              isClosable: true,
            });
            console.error(error);
          });
      },
    });

  return (
    <Box
      position="relative"
      display="inline-block"
      _hover={{
        cursor: "copy",
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Avatar name={defaultText} src={imageBlob} size="xl" />

      {(isFocused || isDragAccept || isDragReject || uploading) && (
        <Box
          position="absolute"
          backgroundColor={"blackAlpha.700"}
          width="100%"
          height="100%"
          top="0"
          left="0"
          borderRadius="9999px"
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          {(isFocused || isDragAccept) && !uploading && (
            <Icon as={FiCamera} color="whiteAlpha.900" w={12} h={12} />
          )}
          {isDragReject && (
            <Icon as={FiCameraOff} color="whiteAlpha.900" w={12} h={12} />
          )}
          {uploading && (
            <Spinner
              color="whiteAlpha.900"
              speed=".85s"
              thickness="4px"
              size="xl"
            />
          )}
        </Box>
      )}
    </Box>
  );
};
