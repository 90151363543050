import React from "react";
import { Helmet } from "react-helmet";
import { RolUser } from "../dto/types/rol.type";
import { IsRol } from "common/dist/security/IsRol";
import useAuth from "../context/auth/auth";
import IUser from "../types/user.interface";
import { ApiClient } from "common";
import { Button, VStack } from "@chakra-ui/react";
import { me, refreshToken } from "../services/user.service";
import { AssignedTasks } from "../components/Widgets";
import ContentBox from "../components/Layout/ContentBox";
import { WidgetsContainer } from "../components/Widgets/ui";
import useSetBreadcrumbs from "../components/BreadCrumbsHeader/useSetBreadcrumbs";

const promesa = () => ApiClient.get("prueba");

function formatSeconds(s: number) {
  const date = new Date(s * 1000);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export const HomePage: React.FC = (props) => {
  const { user, access_token, setUser, setAccessToken, logout } = useAuth();

  useSetBreadcrumbs([
    {
      to: "/",
      label: "Home",
    },
  ]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Project15 - Inicio</title>
      </Helmet>

      <ContentBox>
        <VStack>
          <WidgetsContainer>
            {user?.sub && <AssignedTasks userId={user?.sub} />}
          </WidgetsContainer>

          <IsRol
            user={user as unknown as IUser}
            rol={[RolUser.admin, RolUser.superadmin]}
          >
            Soy un admin o un super admin
          </IsRol>

          <p>Página de prueba</p>

          <pre>{JSON.stringify(user, null, 2)}</pre>
          <pre>
            {JSON.stringify(
              {
                iat: formatSeconds(user?.iat as unknown as number),
                exp: formatSeconds(user?.exp as unknown as number),
                access_token: access_token.substring(access_token.length - 30),
              },
              null,
              2
            )}
          </pre>
          <Button
            onClick={async () => {
              // mock de lo que debería de estar haciendo la autenticación
              const res = await refreshToken();
              if (res) {
                setAccessToken(res.access_token);
                me(res.access_token).then((u) => {
                  setUser(u);
                });
              } else logout();
            }}
          >
            Refresh token
          </Button>
        </VStack>
      </ContentBox>
    </>
  );
};
