"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsRol = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const permissions_1 = require("./permissions");
const IsRol = ({ children, user, rol }) => {
    if (!(0, permissions_1.isRol)(rol, user))
        return null;
    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: children }, void 0);
};
exports.IsRol = IsRol;
