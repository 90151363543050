import React from "react";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiClient } from "common";
import { Project } from "../../api";
import { ProjectsListItem } from "./ProjectsListItem";
import { Text, useToast } from "@chakra-ui/react";
import constants from "../../constants";

interface IProjectListProps {
  requestQueryBuilder: RequestQueryBuilder;
  unassignFromUserButton?: boolean;
  extraButtons?: (id: Project) => JSX.Element;
}

/**
 * Listado sin paginar de proyectos.
 * Usado para mostrar la previa de proyectos en elementos relacionados
 * De momento, usado en clientes.
 * @constructor
 */
export const ProjectQuickList: React.FC<IProjectListProps> = ({
  requestQueryBuilder,
  extraButtons,
}) => {
  const ep = `projects`;
  const queryClient = useQueryClient();
  const toast = useToast();

  const { data } = useQuery<Project[]>(
    ["projects_list", requestQueryBuilder],
    () => ApiClient.get<Project[]>(ep, requestQueryBuilder),
    {
      onError: () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  const onDelete = (item: Project) => {
    queryClient.setQueryData<Project[]>(
      ["projects_list", requestQueryBuilder],
      (oldData) => oldData?.filter((oldItem) => item.id !== oldItem.id) || []
    );
  };

  const deleteMutation = useMutation(
    (id: number) => ApiClient.delete(`projects/${id}`),
    {
      onError: () => {
        toast({
          title: "Error accediendo al servidor",
          description:
            "Ha habido un error accediendo al servidor y los datos no se han guardado. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  const handleDeleteItem = async (item: Project) => {
    onDelete(item);
    deleteMutation.mutate(item.id);
  };

  return (
    <div>
      {data?.map((item) => (
        <ProjectsListItem
          extraButtons={extraButtons}
          key={item.id}
          item={item}
          onDelete={handleDeleteItem}
          queryKey="projects_list"
        />
      ))}

      {(!data || data.length === 0) && <Text>No hay proyectos.</Text>}
    </div>
  );
};
