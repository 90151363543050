import React, { useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Text,
} from '@chakra-ui/react';
import { Task } from '../../api';
import { ProjectsSelectableDropDown } from '../Projects';

interface selectItem {
  value: number;
  label: string;
}

interface ITaskAlertDialogDeleteProps {
  isOpen: boolean;
  onClose: () => void;
  onChange: (projectId: number) => void;
  item: Task;
}

export const TaskDialogUpdateProject: React.FC<ITaskAlertDialogDeleteProps> = ({
  isOpen,
  onClose,
  onChange,
  item,
}) => {
  const cancelRef = React.useRef(null);

  const onChangeProject = (item: selectItem) => {
    setCurrentProjectId(item.value);
  };

  const [currentProjectId, setCurrentProjectId] = useState(item.projectId);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Cambiar proyecto
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>
              Seleccione el proyecto al que quiere mover la tarea{' '}
              <strong>{item.title}</strong>
            </Text>
            <Box mt={4}>
              <ProjectsSelectableDropDown
                onChange={onChangeProject}
                value={currentProjectId}
              />
            </Box>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              colorScheme="green"
              onClick={() => {
                onChange(currentProjectId);
                onClose();
              }}
              ml={3}
              isDisabled={currentProjectId === item.projectId}
            >
              Guardar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
