import React from "react";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { FacebookLoginClient } from "@greatsumini/react-facebook-login";
import constants from "../../constants";

import { Button } from "@chakra-ui/react";
import useAuth from "../../context/auth/auth";
import { FacebookRequestBodyType } from "../../dto/types/facebook.request.body.type";

interface IFacebookSocialLoginProps {}
export const FacebookSocialLogin: React.FC<IFacebookSocialLoginProps> = () => {
  const auth = useAuth();

  return (
    <FacebookLogin
      appId={constants.facebookAppId}
      onSuccess={async (response) => {
        if (!response.accessToken || response.accessToken.length === 0) {
          console.error("No facebook access token");
          return;
        }

        const body: FacebookRequestBodyType = {
          accessToken: response.accessToken,
        };

        const r = await fetch(constants.apiUrl + "auth/login-facebook", {
          method: "POST",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });
        const parsedResponse = await r.json();
        if (parsedResponse.statusCode === 404) {
          // @todo
          alert("Usuario de facebook no registrado, crear cuenta?");
        }
        if (
          parsedResponse.access_token &&
          parsedResponse.access_token.length > 0
        ) {
          FacebookLoginClient.logout(() => {
            console.log("facebook logout");
          });
          auth.setAccessToken(parsedResponse.access_token);
        }

        console.log("Login Success!", response);
      }}
      onFail={(error) => {
        console.error("Login Failed!", error);
        alert("facebook login error");
      }}
      render={({ onClick, logout }) => (
        <Button onClick={onClick}>Facebook</Button>
      )}
    />
  );
};
