const constants = {
  apiUrl: process.env.REACT_APP_API_URL || "",
  localStorageCurrentUser:
    process.env.REACT_APP_LOCAL_STORAGE_CURRENT_USER || "",
  localStorageDataIndex: process.env.REACT_APP_LOCAL_STORAGE_DATA_INDEX || "",
  toastDuration: Number(process.env.REACT_APP_TOAST_DURATION) || 4000,
  facebookAppId: "1351704482097215",
};

export default constants;
