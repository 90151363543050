import React from "react";
import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

interface IListItemHeaderProps {
  columns: string[];
}

const ListItemHeader: React.FC<IListItemHeaderProps> = ({ columns }) => {
  return (
    <Flex
      p={2}
      borderBottom="1px"
      borderColor={useColorModeValue("gray.200", "gray.600")}
    >
      <Box minWidth={10}></Box>
      <SimpleGrid columns={columns.length} flexGrow={1} alignItems="center">
        {columns.map((c, i) => (
          <Box key={i}>
            <Text fontSize="md" fontWeight="bold" color="gray.500">
              {c}
            </Text>
          </Box>
        ))}
      </SimpleGrid>

      <Box width={14}></Box>
    </Flex>
  );
};

export default ListItemHeader;
