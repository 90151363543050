import React, { createContext, useState } from "react";
import { BreadCrumbLink } from "../components/BreadCrumbsHeader";

const AppContext = createContext<IAppContext>({
  setSideViewContent(v: React.ReactElement): void {},
  sideViewContent: <></>,
  setSideViewVisible(v: boolean): void {},
  sideViewVisible: false,
  setSidebarCollapsed(v: boolean): void {},
  sidebarCollapsed: true,

  breadCrumbLinks: [{ to: "/", label: "Home" }],
  setBreadCrumbLinks(v: BreadCrumbLink[]): void {},
});

AppContext.displayName = "AppContext";

export interface IAppContext {
  sideViewVisible: boolean;
  setSideViewVisible: (v: boolean) => void;
  sideViewContent: React.ReactElement;
  setSideViewContent: (v: React.ReactElement) => void;
  breadCrumbLinks: BreadCrumbLink[];
  setBreadCrumbLinks: (v: BreadCrumbLink[]) => void;
  sidebarCollapsed: boolean;
  setSidebarCollapsed: (v: boolean) => void;
}

export const useAppContext = () => React.useContext<IAppContext>(AppContext);

export const AppContextProvider: React.FC = ({ children }) => {
  const [sideViewVisible, setSideViewVisible] = useState(false);
  const [sideViewContent, setSideViewContent] = useState(<></>);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [breadCrumbLinks, setBreadCrumbLinks] = useState<BreadCrumbLink[]>([]);

  return (
    <AppContext.Provider
      value={{
        sideViewVisible,
        setSideViewVisible,
        sideViewContent,
        setSideViewContent,
        sidebarCollapsed,
        setSidebarCollapsed,
        breadCrumbLinks,
        setBreadCrumbLinks,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
