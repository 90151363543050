import React from "react";
import { createValidator } from "class-validator-formik";
import { Form, Formik } from "formik";
import { Button, Grid, GridItem, Stack, useToast } from "@chakra-ui/react";
import {
  CustomCheckBox,
  CustomSelect,
  CustomTextArea,
  CustomTextInput,
} from "../Forms";
import { ProjectCreateDto } from "../../dto/project-create.dto";
import { ProjectUpdateDto } from "../../dto/project-update.dto";
import { useQuery } from "@tanstack/react-query";
import { ApiClient } from "common";
import { Customer } from "../../api";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import constants from "../../constants";

interface ProjectFormProps {
  initialValues: Partial<ProjectCreateDto | ProjectUpdateDto>;
  isNewRecord: boolean;
  dto: any;
  onSubmit: (values: any) => void;
  mutationLoading: boolean;
}

export const ProjectsForm: React.FC<ProjectFormProps> = ({
  initialValues,
  isNewRecord,
  dto,
  onSubmit,
  mutationLoading,
}) => {
  if (!isNewRecord)
    initialValues.amount =
      initialValues.amount &&
      parseFloat(initialValues.amount as unknown as string);

  if (!initialValues.description) initialValues.description = "";

  const request = RequestQueryBuilder.create();
  request.sortBy({
    field: "name",
    order: "ASC",
  });
  const toast = useToast();

  const { data: customers } = useQuery<Customer[]>(
    ["clients", request],
    () => ApiClient.get<Customer[]>("customers", request),
    {
      onError: () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => onSubmit(values as unknown as typeof dto)}
      validate={createValidator(dto)}
    >
      {({ isValid }) => (
        <Form>
          <Stack spacing="6">
            <Grid templateColumns="2fr 1fr" gap={2}>
              <GridItem>
                <CustomTextInput
                  label="Nombre"
                  placeholder="Nombre del proyecto"
                  name="name"
                  autoComplete="off"
                />
              </GridItem>

              <GridItem>
                <CustomTextInput
                  type="number"
                  label="Importe"
                  placeholder="Importe del proyecto"
                  name="amount"
                  autoComplete="off"
                  step="any"
                />
              </GridItem>
            </Grid>

            <CustomTextArea
              label="Descripción"
              placeholder="Descripción del proyecto"
              name="description"
              autoComplete="off"
            />

            {!(isNewRecord && initialValues.customerId) && (
              <CustomSelect
                options={
                  customers?.map((c) => ({
                    value: c.id,
                    label: c.name,
                  })) || []
                }
                label="Cliente"
                name="customerId"
              />
            )}

            {!isNewRecord && <CustomCheckBox label="Cerrado" name="closed" />}

            <Button
              colorScheme="blue"
              size="lg"
              fontSize="md"
              type="submit"
              disabled={!isValid || mutationLoading}
            >
              {mutationLoading ? "Espere" : isNewRecord ? "Crear" : "Guardar"}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
