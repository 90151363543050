import React, { PropsWithChildren } from "react";
import { Box, Heading, useColorModeValue } from "@chakra-ui/react";

interface IWidgetWrapperProps {
  title: string | React.ReactElement;
}

/**
 * Wrapper de un widget
 * @param title
 * @param children
 * @constructor
 */
export const WidgetWrapper: React.FC<
  PropsWithChildren<IWidgetWrapperProps>
> = ({ title, children }) => {
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const bgColorHover = useColorModeValue("white", "gray.900");
  return (
    <Box
      border={1}
      px={2}
      py={4}
      boxShadow="lg"
      transition="all .2s"
      _hover={{ boxShadow: "xl", bg: bgColorHover }}
      bg={bgColor}
    >
      <div>
        <Heading as="h3" size="md" mb={6}>
          {title}
        </Heading>
      </div>
      <div>{children}</div>
    </Box>
  );
};
