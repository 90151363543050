import React, { useEffect, useRef } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiClient } from "common";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { Comment } from "../../api";
import { CommentsCreate } from "./CommentsCreate";
import { CommentsItem } from "./CommentsItem";
import { Box, useToast, VStack } from "@chakra-ui/react";
import { CommentParentEnum } from "../../dto/types/comment.parent.type";
import constants from "../../constants";

interface ICommentsProps {
  type: CommentParentEnum;
  parentId: number;
}

export const Comments: React.FC<ICommentsProps> = ({ type, parentId }) => {
  const ep = "comments";
  const query = RequestQueryBuilder.create();
  const queryClient = useQueryClient();
  const toast = useToast();

  query
    .setFilter([
      {
        field: "parent_type",
        value: type,
        operator: "$eq",
      },
      {
        field: "parent_id",
        value: parentId,
        operator: "$eq",
      },
    ])
    .setLimit(65000)
    .sortBy({
      field: "created_at",
      order: "ASC",
    })
    .setIncludeDeleted(1);

  const onCreate = (item: Comment) => {
    console.log([ep, query]);
    console.log("bucame error e undefined");
    queryClient.setQueriesData([ep, type, parentId], (old: any) => {
      console.log(old);
      return [...old, item];
    });
  };

  const onUpdate = (item: Comment) => {
    queryClient.setQueriesData([ep, type, parentId], (old: any) => {
      return old.map((comment: any) => {
        if (comment.id === item.id) return item;

        return comment;
      });
    });
  };

  const { data, isLoading } = useQuery<Comment[], Error>(
    [ep, type, parentId],
    () => ApiClient.get<Comment[]>(ep, query),
    {
      refetchInterval: 15000,
      onError: () => {
        toast({
          title: "Error al recuperar los comentarios",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  const commentsEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!commentsEndRef.current) return;

    commentsEndRef.current?.scrollIntoView();
  }, [data]);

  if (isLoading) return <p>Cargando</p>;
  // @todo height
  return (
    <VStack alignItems="stretch">
      <Box flexGrow={1} maxHeight="500px" overflowY="scroll">
        {data?.map((item, index) => (
          <CommentsItem onUpdate={onUpdate} key={index} item={item} />
        ))}
        <div ref={commentsEndRef} />
      </Box>

      <Box flexShrink={0}>
        <CommentsCreate
          onCreate={onCreate}
          parentType={type}
          parentId={parentId}
        />
      </Box>
    </VStack>
  );
};
