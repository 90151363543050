import React from "react";
import ContentBox from "../components/Layout/ContentBox";
import useSetBreadcrumbs from "../components/BreadCrumbsHeader/useSetBreadcrumbs";
import useAuth from "../context/auth/auth";
import { DataFetcher } from "common";
import { UserEntity } from "../api";
import { UsersUpdateForm } from "../components/Users/UsersUpdateForm";

const breadCrumbs = [
  {
    to: "/",
    label: "Home",
  },
  {
    label: "Perfil",
  },
];
export const ProfilePage: React.FC = () => {
  useSetBreadcrumbs(breadCrumbs);

  const auth = useAuth();
  const userUrl = `users/${auth.user?.sub}`;

  return (
    <ContentBox>
      <DataFetcher
        queryKey={["users", auth.user?.sub]}
        ep={userUrl}
        render={(u: UserEntity) => (
          <>
            <UsersUpdateForm user={u} />
          </>
        )}
      />
    </ContentBox>
  );
};
