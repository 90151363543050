import React from "react";
import { Upload } from "../../api";
import { MultipleListItem } from "./MultipleListItem";

interface IMultipleListProps {
  data: Upload[] | undefined;
  onFileDeleted: (id: number) => void;
  onFileUpdated: (data: Upload) => void;
}

export const MultipleList: React.FC<IMultipleListProps> = ({
  data,
  onFileDeleted,
  onFileUpdated,
}) => {
  return (
    <div>
      {data?.map((item) => (
        <MultipleListItem
          key={item.id}
          onFileDeleted={onFileDeleted}
          onFileUpdated={onFileUpdated}
          item={item}
        />
      ))}
    </div>
  );
};
