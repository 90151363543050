import { Flex } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

interface IWidgetsContainerProps {}

export const WidgetsContainer: React.FC<
  PropsWithChildren<IWidgetsContainerProps>
> = ({ children }) => {
  return (
    <Flex
      id="buscame"
      flexWrap="wrap"
      gap={4}
      alignItems="flex-start"
      flexDirection="row"
      width="100%"
    >
      {children}
    </Flex>
  );
};
