import {
  IsBoolean,
  IsNotEmpty,
  IsOptional,
  MaxLength,
  MinLength,
} from 'class-validator';

export class TaskUpdateDto {
  @MinLength(3)
  @MaxLength(255)
  @IsNotEmpty()
  
  title?: string;

  
  description?: string;

  @IsBoolean()
  
  closed?: boolean;

  @IsNotEmpty()
  
  @IsOptional()
  projectId?: number;
}
