export const Routes = {
  USER_LOGIN: "/login",
  USER_REGISTER: "/register",
  REACT_QUERY: "/reactquery",
  OUTLET_CREATE: "create",
  OUTLET_UPDATE: "update/:id",
  OUTLET_VIEW: ":id",
  COMPANIES: "/companies",
  COMPANY: "/company",

  REACT_TABLE: "/reacttable",
  DESIGN: "/design",
  PROTECTED: "/protected",

  DETAIL: "/detail",
  HOME: "/home",
  TEST: "/test",
  USERS: "/users",
  USERS_UPDATE_PASSWORD: "password-update/:id",
  HOME_MODAL_TWO_COLUMNS: "modal-two-columns",
  WIDGET_ASSIGNED_TASKS: "assigned-tasks",

  CUSTOMERS: "/customers",
  PROJECTS: "/projects",
  TASKS: "/tasks",
  PRODUCTS: "/products",
  TAGS: "/tags",

  REPORTS: "/reports",
  REPORT_HOURS_BY_CUSTOMER_DATE: "/hours-by-customer-date",

  PROFILE: "/perfil",
  RECOVER_PASSWORD: "/recover-password",
  RESET_PASSWORD: "/reset-password",
};
