import React from "react";
import { createValidator } from "class-validator-formik";
import { Form, Formik } from "formik";
import { Button, Stack } from "@chakra-ui/react";
import { CustomTextInput } from "../Forms";
import { TaskCreateDto } from "../../dto/task-create.dto";

interface TaskFormProps {
  initialValues: TaskCreateDto;
  isNewRecord: boolean;
  dto: any;
  onSubmit: (values: TaskCreateDto) => void;
  mutationLoading: boolean;
  showDescription?: boolean;
  showLabel?: boolean;
}

export const TasksForm: React.FC<TaskFormProps> = ({
  initialValues,
  isNewRecord,
  dto,
  onSubmit,
  mutationLoading,
  showDescription = true,
  showLabel = true,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
    validate={createValidator(dto)}
  >
    {({ isValid }) => (
      <Form>
        <Stack spacing="6">
          <CustomTextInput
            label={showLabel && "Título"}
            placeholder="Titulo de la tarea"
            name="title"
            autoComplete="off"
            autoFocus={true}
          />
          {showDescription && (
            <CustomTextInput
              label="Descripción"
              placeholder="Descripción de la tarea"
              name="description"
              autoComplete="off"
            />
          )}

          <Button
            colorScheme="blue"
            size="lg"
            fontSize="md"
            type="submit"
            disabled={!isValid || mutationLoading}
          >
            {mutationLoading ? "Espere" : isNewRecord ? "Crear" : "Guardar"}
          </Button>
        </Stack>
      </Form>
    )}
  </Formik>
);
