export enum LogEventType {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

/**
 * El tipo de entidad posible.
 *
 * El valor se mapea con un event.entity.name en el subscriber con lo cual
 * hay que respetar los nombres.
 */
export enum LogEntityType {
  Project = 'Project',
  Task = 'Task',
  Comment = 'Comment',
  Time = 'Time',
  Tag = 'Tag',
  Customer = 'Customer',
  Expense = 'Expense',
  Payment = 'Payment',
  Product = 'Product',
  ProductLine = 'ProductLine',
  Upload = 'Upload',
  User = 'UserEntity',
}

export enum LogResponseTimeChildrenEvent {
  Start = 'Start',
  Stop = 'Stop',
  Update = 'Update',
  Deleted = 'Deleted',
}
export interface LogResponseTimeChildren {
  event: LogResponseTimeChildrenEvent;
  date: Date;
  oldValues: Record<string, unknown>;
  newValues: Record<string, unknown>;
}
export interface LogResponse {
  entity: LogEntityType;
  entityId: number;
  event: LogEventType;
  oldValues?: Record<string, unknown>;
  newValues?: Record<string, unknown>;
  userId: number;
  userName: string;
  userEmail: string;
  date: Date;
  parentEntity?: LogEntityType;
  parentId?: number;
  parentName?: string;
  children?: LogResponseTimeChildren[];
  totalTime?: number;
}
