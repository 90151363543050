import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

export const ExpensesListItemHeader: React.FC = () => {
  const color = "gray.500";
  return (
    <Flex mb={1} borderBottomWidth="1px" borderBottomColor="gray.200">
      <Box textColor={color} width="50%">
        <Text fontSize="sm">Concepto</Text>
      </Box>
      <Box textColor={color} width="50%">
        <Text fontSize="sm">Precio</Text>
      </Box>
    </Flex>
  );
};
