import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Heading,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { Logo } from "../components/Logo";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Card } from "../components/Card";
import { Routes } from "../routes";
import { Helmet } from "react-helmet";
import useAuth from "../context/auth/auth";
import { ChangePasswordForm } from "../components/Forms/ChangePasswordForm";
import constants from "../constants";

export const ResetPasswordPage: React.FC = () => {
  const boxBG = useColorModeValue("gray.50", "inherit");
  const [searchParams] = useSearchParams();
  const auth = useAuth();
  const navigate = useNavigate();
  const id = searchParams.get("id");
  const token = searchParams.get("token");
  const [userExists, setUserExists] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (id === null || token === null) {
      toast({
        title: "Error",
        description: "En enlace no parece ser correcto o ya ha caducado",
        status: "error",
        duration: constants.toastDuration,
        isClosable: true,
      });
      return;
    }

    auth
      .checkUserByToken(parseInt(id), token)
      .then((r) => {
        if (r) setUserExists(true);
      })
      .catch((e) => {
        toast({
          title: "Error",
          description: "En enlace no parece ser correcto o ya ha caducado",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
        console.error(e);
      });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Project15 - Cambiar contraseña</title>
      </Helmet>

      <Box bg={boxBG} minH="100vh" py="12" px={{ base: "4", lg: "8" }}>
        <Box maxW="md" mx="auto">
          <Logo mx="auto" h="16" mb={{ base: "10", md: "20" }} />
          <Heading textAlign="center" size="xl" fontWeight="extrabold">
            Cambia tu contraseña
          </Heading>
          <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
            <Text as="span">¿No tienes una cuenta? </Text>
            <Link to={Routes.USER_REGISTER}>Regístrate</Link>
          </Text>
          <Card>
            {/*todo que hacemos cuando da error ¿?*/}
            {/*todo revisar con jesus el mandar estos parámetros por props, no creo que sea lo más correcto*/}
            {userExists ? (
              <ChangePasswordForm
                id={parseInt(id as unknown as string)}
                token={token as unknown as string}
              />
            ) : (
              <Box>
                <Heading>Error</Heading>
                <Text>En enlace no es correcto.</Text>
                <Button colorScheme="blue" mt={4} onClick={() => navigate("/")}>
                  Volver a la página de inicio
                </Button>
              </Box>
            )}
          </Card>
        </Box>
      </Box>
    </>
  );
};
