import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { ProductLine } from "../../api";
import {priceToHtml} from '../../util';

interface IProductsWidgetSummaryProps {
  products: ProductLine[];
}

export const ProductsWidgetSummary: React.FC<IProductsWidgetSummaryProps> = ({
  products,
}) => {
  const color = "gray.500";
  return (
    <Flex>
      <Box textColor={color} width="65%" textAlign="right">
        <Text fontSize="sm" pr={4}>
          Total
        </Text>
      </Box>
      <Box textColor={color} width="15%">
        <Text fontSize="sm">
          { priceToHtml(products.reduce((acc, current) => acc + current.price * current.quantity, 0)) }
        </Text>
      </Box>
    </Flex>
  );
};
