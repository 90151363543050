import { chakra, Grid, useColorModeValue } from "@chakra-ui/react";
import React from "react";

interface IModalBodyProps {
  columns: number;
}

export const ModalBody: React.FC<IModalBodyProps> = ({ children, columns }) => (
  <chakra.div
    height="100%"
    overflowY="hidden"
    backgroundColor={useColorModeValue("white", "gray.800")}
  >
    <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={2} height="100%">
      {children}
    </Grid>
  </chakra.div>
);
