import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Select from "react-select";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Product } from "../../api";
import { ApiClient } from "common";
import { CustomOptionDataItem } from "./types";
import { ProductsWidgetCustomOption } from "./ProductsWidgetCustomOption";
import { ProductLineParentEnum } from "../../dto/types/product-line.parent.type";
import { createValidator } from "class-validator-formik";
import { Form, Formik } from "formik";
import { CustomTextInput } from "../Forms";
import { ProductAssignDto } from "../../dto/product-assign.dto";
import constants from "../../constants";

interface IProductsWidgetAddFormProps {
  type: ProductLineParentEnum;
  parentId: number;
}

export const ProductsWidgetAdd: React.FC<IProductsWidgetAddFormProps> = ({
  type,
  parentId,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [productsData, setProductsData] = useState<CustomOptionDataItem[]>([]);
  const [currentProduct, setCurrentProduct] = useState<Product>();
  const queryClient = useQueryClient();
  const toast = useToast();

  const initialValues: ProductAssignDto = {
    description: "",
    name: "",
    price: 0,
    parentId,
    productId: 0,
    parent_type: type,
    quantity: 1,
  };

  const { data } = useQuery<Product[]>(
    ["products"],
    () => ApiClient.get<Product[]>("products"),
    {
      onError: () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  useEffect(() => {
    if (!data) return;

    setProductsData(
      data.map((item) => ({
        label: item.name,
        value: item.id,
      }))
    );
  }, [data]);

  const openModal = () => {
    setCurrentProduct(undefined);
    onOpen();
  };

  return (
    <div>
      <Button mt={4} variant="outline" colorScheme="blue" onClick={openModal}>
        Agregar producto
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={true}>
        <ModalOverlay />

        <Formik
          isInitialValid={true}
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            ApiClient.post("products/assign", JSON.stringify(values))
              .then(() => {
                // Refetch de los datos del proyecto para que se vea el producto (projects/id, ya que se usa con el datafetcher
                let queryClientCacheKey;
                if (type === ProductLineParentEnum.project)
                  queryClientCacheKey = ["projects", parentId.toString()];

                if (type === ProductLineParentEnum.task)
                  // @todo revisar este cuando se implemente en la tarea
                  queryClientCacheKey = ["tasks", parentId.toString()];

                queryClient.refetchQueries(queryClientCacheKey);

                onClose();
                toast({
                  title: "Correcto",
                  description: "El producto ha sido agregado correctamente.",
                  status: "success",
                  duration: constants.toastDuration,
                  isClosable: true,
                });
              })
              .catch(() =>
                toast({
                  title: "Error al asignar el producto",
                  description:
                    "Ha habido un error asignando el producto. Refresca la página y vuelve a intentarlo.",
                  status: "error",
                  duration: constants.toastDuration,
                  isClosable: true,
                })
              )
              .finally(() => setSubmitting(false));
          }}
          validate={createValidator(ProductAssignDto)}
        >
          {(props) => (
            <Form>
              <ModalContent>
                <ModalHeader>Seleccione artículo</ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                  <>
                    <Select
                      onChange={(value) => {
                        const product = data?.find(
                          (item) => item.id === value?.value
                        );
                        props.setFieldValue("name", product?.name);
                        props.setFieldValue("productId", product?.id);
                        props.setFieldValue("price", product?.price);
                        props.setFieldValue(
                          "description",
                          product?.description
                        );
                        setCurrentProduct(product);

                        // @todo
                        setTimeout(() => {
                          props.validateForm();
                        }, 1);
                      }}
                      options={productsData}
                      placeholder="Seleccione..."
                      isMulti={false}
                      autoFocus={true}
                      minMenuHeight={500}
                      components={{
                        Option: ProductsWidgetCustomOption,
                        MultiValueRemove: () => <></>,
                        NoOptionsMessage: () => (
                          <Text textAlign="center" textColor="gray.600">
                            No hay productos
                          </Text>
                        ),
                      }}
                    />

                    {currentProduct && (
                      <Box mt={4}>
                        <CustomTextInput
                          label="Nombre"
                          placeholder="Nombre del artículo"
                          name="name"
                          autoComplete="off"
                          marginBottom={2}
                        />

                        <CustomTextInput
                          label="Cantidad"
                          placeholder="Cantidad a agregar"
                          name="quantity"
                          type="number"
                          step="any"
                          autoComplete="off"
                          marginBottom={2}
                        />

                        <CustomTextInput
                          label="Descripción"
                          placeholder="Descripción"
                          name="description"
                          autoComplete="off"
                          marginBottom={2}
                        />

                        <CustomTextInput
                          label="Precio"
                          placeholder="Precio del producto"
                          name="price"
                          type="number"
                          step="any"
                          autoComplete="off"
                          marginBottom={4}
                        />

                        <Button
                          type="submit"
                          tabIndex={2}
                          colorScheme="blue"
                          disabled={!props.isValid}
                          isLoading={props.isSubmitting}
                        >
                          Asignar Producto
                        </Button>
                      </Box>
                    )}
                  </>
                </ModalBody>
                <ModalFooter></ModalFooter>
              </ModalContent>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};
