import React from "react";

const STORAGE_PREFFIX = "p15_RUSP_";

/**
 * Igual que useState pero persiste el valor en local storage
 *
 * https://www.robinwieruch.de/local-storage-react/
 * @param storageKey
 * @param fallbackState
 */
export const useStatePersist = (storageKey: string, fallbackState: any) => {
  const LOCAL_STORAGE_KEY = `${STORAGE_PREFFIX}${storageKey}`;
  const [value, setValue] = React.useState(
    // @ts-ignore
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) ?? fallbackState
  );

  React.useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(value));
  }, [value, storageKey]);

  return [value, setValue];
};
// export const useStatePersist = (storageKey:string, fallbackState: any) => {
//
//     const LOCAL_STORAGE_KEY = `${STORAGE_PREFFIX}${storageKey}`;
//
//     const [value, setValue] = React.useState(() => {
//         return (typeof fallbackState !== 'string' ?
//                 // @ts-ignore
//                 JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) :
//                 localStorage.getItem(LOCAL_STORAGE_KEY)
//         ) ?? fallbackState
//     });
//
//     React.useEffect(() => {
//         let v = value;
//
//         if (typeof value !== 'string')
//             v = JSON.stringify(value)
//
//         localStorage.setItem(LOCAL_STORAGE_KEY, v);
//     }, [value, storageKey]);
//
//     return [value, setValue];
// };

export const clearStatePersist = (storageKey: string) => {
  const LOCAL_STORAGE_KEY = `${STORAGE_PREFFIX}${storageKey}`;
  window.localStorage.removeItem(LOCAL_STORAGE_KEY);
};

/**
 * Implementar si hace falta
 * @param storageKey
 */
export const clearAllStatePersist = () => {
  Object.keys(localStorage)
    .filter((name) => name.startsWith(STORAGE_PREFFIX))
    .forEach((name) => {
      localStorage.removeItem(name);
    });
};
