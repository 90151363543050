import React, { useEffect, useState } from "react";
import { Comment } from "../../api";
import {
  Box,
  Icon,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import useAuth from "../../context/auth/auth";
import { Field, Form, Formik } from "formik";
import { createValidator } from "class-validator-formik";
import { CommentUpdateDto } from "../../dto/comment-update.dto";
import { useMutation } from "@tanstack/react-query";
import { ApiClient } from "common";
import { DateTime } from "luxon";
import { FaEdit } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import constants from "../../constants";

interface ICommentsItemProps {
  onUpdate: (data: Comment) => void;
  item: Comment;
}

export const CommentsItem: React.FC<ICommentsItemProps> = ({
  onUpdate,
  item,
}) => {
  const auth = useAuth();
  const isMine = item.user?.id === auth.user?.sub;
  const updateFieldRef = React.useRef<HTMLTextAreaElement>(null);

  const darkBGColor = useColorModeValue("gray.200", "gray.600");
  const darkTextColor = useColorModeValue("gray.800", "gray.50");

  const bgColor = isMine ? "blue.500" : darkBGColor;
  const textColor = isMine ? "gray.50" : darkTextColor;
  const textAlign = isMine ? "right" : "left";
  const [editing, setEditing] = useState(false);
  const toast = useToast();

  const mutation = useMutation<Comment, unknown, CommentUpdateDto>(
    (updateComment) =>
      ApiClient.patch(`comments/${item.id}`, JSON.stringify(updateComment)),
    {
      onSuccess: async (data) => {
        onUpdate(data);
        setEditing(false);
      },
      onError: async () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  const initialValues: CommentUpdateDto = {
    text: item.text,
  };

  const handleEdit = () => {
    setEditing(true);
  };

  useEffect(() => {
    if (editing) updateFieldRef.current?.focus();
  }, [editing]);

  const submitForm = async (values: CommentUpdateDto) => {
    initialValues.text !== values.text
      ? mutation.mutate(values)
      : setEditing(false);
  };

  return (
    <VStack
      mb={4}
      alignItems={isMine ? "flex-end" : "flex-start"}
      textAlign={textAlign}
    >
      {!isMine && (
        <Box>
          <Tooltip label={item.user.name} placement="top">
            <Text fontSize="sm">{item.user.email}</Text>
          </Tooltip>
        </Box>
      )}

      <Box bg={bgColor} p={2} borderRadius={12}>
        {editing ? (
          <Box>
            <Formik
              initialValues={initialValues}
              onSubmit={submitForm}
              validate={createValidator(CommentUpdateDto)}
            >
              {(props) => (
                <Form>
                  <Stack spacing="6" width={400}>
                    <Field
                      innerRef={updateFieldRef}
                      name="text"
                      as="textarea"
                      onBlur={props.submitForm}
                      style={{
                        background: "transparent",
                        color: "#fff",
                        outline: "none",
                        boxShadow: "none",
                        padding: 2,
                      }}
                    />
                  </Stack>
                </Form>
              )}
            </Formik>
          </Box>
        ) : (
          <VStack alignItems="flex-end">
            <Box color={textColor}>
              <ReactMarkdown>{item.text}</ReactMarkdown>
            </Box>
            {isMine && (
              <Icon
                as={FaEdit}
                onClick={handleEdit}
                _hover={{ cursor: "pointer" }}
                color={textColor}
              />
            )}
          </VStack>
        )}
      </Box>

      <Box>
        <Text fontSize="xs">
          {DateTime.fromISO(item.created_at).toFormat("dd-MM-yyyy HH:mm")}
        </Text>
      </Box>
    </VStack>
  );
};
