import React from "react";
import { Helmet } from "react-helmet";
import ContentBox from "../components/Layout/ContentBox";
import useSetBreadcrumbs from "../components/BreadCrumbsHeader/useSetBreadcrumbs";
import { ReportHoursByCustomerDateForm } from "../components/Reports";
import { HorasPorClienteYFechaDto } from "../dto/horas-por-cliente-y-fecha.dto";
import { Outlet, useNavigate } from "react-router-dom";
import { Routes as R } from "../routes";

interface IReportHoursByCustomerDateProps {}

const breadCrumbs = [
  {
    to: "/",
    label: "Home",
  },
  {
    label: "Informe de horas por cliente y fecha",
  },
];
export const ReportHoursByCustomerDate: React.FC<
  IReportHoursByCustomerDateProps
> = () => {
  useSetBreadcrumbs(breadCrumbs);

  const navigate = useNavigate();

  const handleSubmit = async (values: HorasPorClienteYFechaDto) => {
    const valuesCopy = { ...values };
    valuesCopy.startDate += " 00:00:00";
    valuesCopy.endDate += " 23:59:59";

    let urlParams = new URLSearchParams();
    urlParams.append("startDate", valuesCopy.startDate);
    urlParams.append("endDate", valuesCopy.endDate);
    valuesCopy.projectTagsIgnore?.forEach((i) => {
      urlParams.append("projectTagsIgnore", i.toString());
    });

    valuesCopy.projectTagsInclude?.forEach((i) => {
      urlParams.append("projectTagsInclude", i.toString());
    });

    valuesCopy.customers?.forEach((i) => {
      urlParams.append("customers", i.toString());
    });

    navigate(R.REPORT_HOURS_BY_CUSTOMER_DATE + `/${urlParams.toString()} `, {
      replace: true,
    });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Project15 - Informes</title>
      </Helmet>

      <ContentBox>
        <ReportHoursByCustomerDateForm
          onSubmit={handleSubmit}
          dto={HorasPorClienteYFechaDto}
        />
      </ContentBox>

      <Outlet />
    </>
  );
};
