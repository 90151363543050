import React from "react";
import { TasksForm } from "../Tasks";
import { TaskCreateDto } from "../../dto/task-create.dto";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Task } from "../../api";
import { ApiClient } from "common";
import { Button, Divider, HStack, Text, useToast } from "@chakra-ui/react";
import { FlattenedObject } from "./BuscadorVisible";
import constants from "../../constants";

interface IBuscadorTaskCreateProps {
  setIsCreatingNewTask: (v: boolean) => void;
  currentItem: FlattenedObject;
  onCreate: (data: Task) => void;
}

export const BuscadorTaskCreate: React.FC<IBuscadorTaskCreateProps> = ({
  setIsCreatingNewTask,
  currentItem,
  onCreate,
}) => {
  const toast = useToast();

  const mutation = useMutation<Task, unknown, TaskCreateDto>(
    (d) => ApiClient.post("tasks", JSON.stringify(d)),
    {
      onError: () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onSuccess: async (data) => {
        console.log("task created");
        console.log(data);
        clearCacheData();
        onCreate(data);
      },
    }
  );

  const cacheDataKey = `infsc_tasks_project_${currentItem.id}`;
  const queryClient = useQueryClient();

  const clearCacheData = () => {
    queryClient.refetchQueries([cacheDataKey]);
  };

  const onSubmit = (v: TaskCreateDto) => {
    mutation.mutate(v);
  };

  return (
    <div>
      <Text>
        Vas a crear una tarea para en el proyecto{" "}
        <strong>{currentItem.name}</strong>
      </Text>
      <TasksForm
        initialValues={{
          title: "",
          description: "",
          projectId: currentItem.id,
        }}
        isNewRecord={true}
        dto={TaskCreateDto}
        onSubmit={onSubmit}
        mutationLoading={mutation.isLoading}
        showDescription={false}
        showLabel={false}
      />

      <Divider orientation="horizontal" my={4} />

      <HStack alignItems="center" justifyContent="center">
        <Button
          variant="link"
          onClick={() => {
            setIsCreatingNewTask(false);
          }}
        >
          o volver sin crear una tarea
        </Button>
      </HStack>
    </div>
  );
};
