import React from "react";
import { createValidator } from "class-validator-formik";
import { Form, Formik } from "formik";
import { Button, Grid, GridItem, Stack } from "@chakra-ui/react";
import { CustomTextInput } from "../Forms";
import { TagCreateDto } from "../../dto/tag-create.dto";
import { TagUpdateDto } from "../../dto/tag-update.dto";
import { CustomColorInput } from "../Forms/CustomColorInput";

interface TagFormProps {
  initialValues: TagCreateDto | TagUpdateDto;
  isNewRecord: boolean;
  dto: any;
  onSubmit: (values: TagCreateDto | TagUpdateDto) => void;
  mutationLoading: boolean;
}

const TagsForm: React.FC<TagFormProps> = ({
  initialValues,
  isNewRecord,
  dto,
  onSubmit,
  mutationLoading,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
    validate={createValidator(dto)}
  >
    {({ isValid }) => (
      <Form>
        <Stack spacing="6">
          <Grid templateColumns="2fr 1fr 1fr" gap={2}>
            <GridItem>
              <CustomTextInput
                label="Título"
                placeholder="tag de prueba"
                name="title"
                autoComplete="off"
              />
            </GridItem>

            <GridItem>
              <CustomColorInput
                label="Color de fondo"
                placeholder="Rojo"
                name="background_color"
              />
            </GridItem>

            <GridItem>
              <CustomColorInput
                label="Color del texto"
                placeholder="Rojo"
                name="text_color"
              />
            </GridItem>
          </Grid>

          <Button
            colorScheme="blue"
            size="lg"
            fontSize="md"
            type="submit"
            disabled={!isValid || mutationLoading}
          >
            {mutationLoading ? "Espere" : isNewRecord ? "Crear" : "Guardar"}
          </Button>
        </Stack>
      </Form>
    )}
  </Formik>
);

export default TagsForm;
