import React from "react";
import { Heading, HStack, useToast } from "@chakra-ui/react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Routes } from "../../routes";
import { ProjectCreateDto } from "../../dto/project-create.dto";
import { useMutation } from "@tanstack/react-query";
import { UserEntity } from "../../api";
import { ApiClient } from "common";
import { OutletContext } from "../../types/outlet.context";
import { Modal, ModalBody, ModalHeader } from "../Modal";
import { UsersCreateForm } from "./UsersCreateForm";
import { UsersCreateWithRepeatPasswordDto } from "./CreateUserWithPasswordRepeat.dto";
import { RolUser } from "../../dto/types/rol.type";
import { CreateUserDto } from "../../dto/create-user.dto";
import { ModalColumn } from "../Modal/ModalColumn";
import constants from "../../constants";

const initialValues: CreateUserDto = {
  name: "",
  email: "",
  password: "",
  document: "",
  rol: RolUser.user,
};

export const UsersCreate = () => {
  const navigate = useNavigate();
  const backTo = Routes.USERS;
  const toast = useToast();

  const { onCreate } = useOutletContext<OutletContext<UserEntity>>();

  const mutation = useMutation<UserEntity, unknown, CreateUserDto>(
    (d) => ApiClient.post("users", JSON.stringify(d)),
    {
      onError: () => {
        toast({
          title: "Error accediendo al servidor",
          description:
            "Ha habido un error accediendo al servidor y los datos no se han guardado. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onSuccess: async (data) => {
        toast({
          title: "Correcto",
          description: "El usuario ha sido creado correctamente.",
          status: "success",
          duration: constants.toastDuration,
          isClosable: true,
        });

        onCreate(data);
        navigate(backTo);
      },
    }
  );

  const handleSubmit = (values: UsersCreateWithRepeatPasswordDto) => {
    // password_repeat no es necesario mandarlo, pero si el resto
    const { password_repeat, ...rest } = values;
    mutation.mutate(rest as unknown as CreateUserDto);
  };

  return (
    <Modal>
      <ModalHeader onClose={() => navigate(backTo)}>
        <HStack spacing={2}>
          <h3>Crear Usuario</h3>
        </HStack>
      </ModalHeader>

      <ModalBody columns={1}>
        <ModalColumn>
          <Heading>Crear Usuario</Heading>
          <UsersCreateForm
            initialValues={initialValues}
            isNewRecord={true}
            dto={ProjectCreateDto}
            onSubmit={handleSubmit}
            mutationLoading={mutation.isLoading}
          />
        </ModalColumn>
      </ModalBody>
    </Modal>
  );
};
