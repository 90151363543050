"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiClient = void 0;
class ApiClientClass {
    constructor() {
        this.access_token = '';
        this.url = '';
    }
    call({ method, ep, query, body }) {
        return __awaiter(this, void 0, void 0, function* () {
            // console.log('apiclient.call');
            // console.log({at: this.access_token});
            let init = {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.access_token}`
                },
                body
            };
            if (query)
                ep += '?' + query.query();
            // @TODO hardcodeado
            console.log('jose');
            console.log(this.url + ep);
            const response = yield fetch(this.url + ep, init);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            if (method !== 'DELETE') {
                const contentType = response.headers.get('content-type');
                if (contentType && contentType.indexOf("application/json") !== -1)
                    return response.json();
            }
            return response;
        });
    }
    post(ep, body) {
        return this.call({
            method: 'POST',
            ep,
            body
        });
    }
    get(ep, query) {
        return this.call({
            method: 'GET',
            ep,
            query
        });
    }
    put(ep, body) {
        return this.call({
            method: 'PUT',
            ep,
            body
        });
    }
    delete(ep) {
        return this.call({
            method: 'DELETE',
            ep
        });
    }
    patch(ep, body) {
        return this.call({
            method: 'PATCH',
            ep,
            body
        });
    }
}
exports.ApiClient = new ApiClientClass();
