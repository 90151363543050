import React from 'react';
import { Task } from '../../../api';
import {
  Box,
  Grid,
  GridItem,
  HStack,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { TooltipTagAvatar } from '../../Tags/TagsSelector';
import { DateTime } from 'luxon';
import { formatSeconds } from 'common/dist/util';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { TagList } from '../../Tags';

interface ITaskListProps {
  item: Task;
  index: number;
}

export const ReportTaskListItem: React.FC<ITaskListProps> = ({
  item,
  index,
}) => {
  const startDate = DateTime.fromSeconds(item.times[0].start_time).toFormat(
    'dd-MM-yyyy HH:mm:ss',
  );
  const endDate = DateTime.fromSeconds(
    item.times[item.times.length - 1].end_time,
  ).toFormat('dd-MM-yyyy HH:mm:ss');

  const timeTotalSeconds = item.times.reduce((cont: number, time) => {
    return cont + time.total_time_segs;
  }, 0);

  const zebraStyle = index % 2 === 0 ? {} : { backgroundColor: 'gray.100' };

  const hoverRowBG = useColorModeValue('gray.200', 'gray.200');

  const totalTime = formatSeconds(timeTotalSeconds);

  return (
    <Grid
      templateColumns="repeat(6, 1fr)"
      gap={3}
      pt={1}
      pb={1}
      _hover={{ backgroundColor: hoverRowBG }}
      sx={zebraStyle}
      onClick={() => {
        console.log({ timeTotalSeconds });
      }}
    >
      <GridItem
        colSpan={3}
        borderBottom="1px solid"
        borderBottomColor="gray.200"
      >
        <Link
          to={`/projects/${item.projectId}/task/${item.id}`}
          target="_blank"
        >
          <Text>{item.title}</Text>
        </Link>
        <TagList items={item.assigned_tags} />
      </GridItem>
      <GridItem>
        <Text fontSize="sm">{startDate}</Text>
      </GridItem>
      <GridItem>
        <Text fontSize="sm">{endDate}</Text>
      </GridItem>
      <GridItem>
        <HStack>
          <Box>{totalTime}</Box>
          {item.timeTotal !== timeTotalSeconds && (
            <Tooltip
              label={`Tiempo trabajado en el intervalo ${totalTime}. Tiempo total trabajado en la tarea ${formatSeconds(
                item.timeTotal,
              )}`}
              placement="top"
            >
              <div>
                <AiOutlineInfoCircle />
              </div>
            </Tooltip>
          )}
        </HStack>
      </GridItem>
    </Grid>
  );
};
