import React from 'react';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Time } from '../../api';
import { ApiClient } from 'common';
import { Text, useToast } from '@chakra-ui/react';
import { TimesItem } from './TimesItem';
import { TimesWidgetHeader } from './TimesWidgetHeader';
import { TimesSummary } from './TimesSummary';
import constants from '../../constants';

interface ITimesListProps {
  taskId: number;
  projectId: number;
}

export const TimesList: React.FC<ITimesListProps> = ({ taskId, projectId }) => {
  const ep = 'times';
  const query = RequestQueryBuilder.create();
  const toast = useToast();
  const queryClient = useQueryClient();

  query
    .setFilter([
      {
        field: 'taskId',
        value: taskId,
        operator: CondOperator.EQUALS,
      },
      {
        field: 'deleted',
        operator: CondOperator.IS_NULL,
      },
    ])
    .setLimit(65000)
    .sortBy({
      field: 'start_time',
      order: 'DESC',
    })
    .setIncludeDeleted(1);

  const { data, isLoading } = useQuery<Time[], Error>(
    [ep, query],
    () => ApiClient.get<Time[]>(ep, query),
    {
      refetchInterval: 15000,
      onError: () => {
        toast({
          title: 'Error al recuperar los datos',
          description:
            'Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.',
          status: 'error',
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    },
  );

  const onUpdateTime = (time: Time) => {
    const newData = [...(data || [])].map((oldItem) => {
      if (oldItem.id === time.id) return { ...time };
      return oldItem;
    });
    console.log(time, 'PRUEBA');
    queryClient.refetchQueries(['infsc_tasks_project_' + projectId]);
    // @todo fix this
    const { queryString, ...rest } = query;
    queryClient.setQueryData([ep, rest], newData);
  };
  const onDelete = (timeId: number) => {
    const newData = [...(data || [])].filter(
      (time: Time) => time.id !== timeId,
    );
    const { queryString, ...rest } = query;
    queryClient.setQueryData([ep, rest], newData);
  };

  return (
    <div>
      {isLoading ? (
        <Text>Cargando</Text>
      ) : data && data.length > 0 ? (
        <>
          <TimesWidgetHeader />
          {data?.map((time) => (
            <TimesItem
              onDelete={onDelete}
              item={time}
              key={`time-${time.id}`}
              onUpdate={onUpdateTime}
            />
          ))}
          <TimesSummary tasks={data} />
        </>
      ) : (
        <Text>No hay tiempos</Text>
      )}
    </div>
  );
};
