import React from "react";
import { HStack, Text } from "@chakra-ui/react";
import { ICustomOptionProps } from "./types";

export const ProductsWidgetCustomOption: React.FC<ICustomOptionProps> = ({
  innerRef,
  innerProps,
  isDisabled,
  data,
  ...rest
}) => {
  if (isDisabled) return null;

  // @ts-ignore
  const { isSelected } = rest;
  // @ts-ignore
  const { isFocused } = rest;

  return (
    <div ref={innerRef} {...innerProps}>
      <HStack
        p={1}
        pl={4}
        backgroundColor={
          isFocused ? "blue.50" : isSelected ? "gray.100" : "white"
        }
        _hover={{ backgroundColor: "gray.100", cursor: "pointer" }}
      >
        <Text>{data.label}</Text>
      </HStack>
    </div>
  );
};
