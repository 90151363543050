import React from "react";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiClient } from "common";
import { Task } from "../../api";

import { Text, useToast } from "@chakra-ui/react";
import { TasksQuickListItem } from "./TasksQuickListItem";
import constants from "../../constants";

interface IProjectListProps {
  requestQueryBuilder: RequestQueryBuilder;
  extraButtons?: (item: Task) => JSX.Element;
}

/**
 * Listado sin paginar de proyectos.
 * Usado para mostrar la previa de proyectos en elementos relacionados
 * De momento, usado en clientes.
 * @constructor
 */
export const TasksQuickList: React.FC<IProjectListProps> = ({
  requestQueryBuilder,
  extraButtons,
}) => {
  const ep = `tasks`;
  const queryClient = useQueryClient();
  const toast = useToast();

  const { data } = useQuery<Task[]>(
    ["tasks_list", requestQueryBuilder],
    () => ApiClient.get<Task[]>(ep, requestQueryBuilder),
    {
      onError: () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  const onDelete = (item: Task) => {
    queryClient.setQueryData<Task[]>(
      ["tasks_list", requestQueryBuilder],
      (oldData) => oldData?.filter((oldItem) => item.id !== oldItem.id) || []
    );
  };

  const deleteMutation = useMutation(
    (id: number) => ApiClient.delete(`tasks/${id}`),
    {
      // onSuccess: async (data, variables, context) => {
      //     onDelete(variables as unknown as number);
      // },
    }
  );

  const handleDeleteItem = async (item: Task) => {
    onDelete(item);
    deleteMutation.mutate(item.id);
  };

  return (
    <div>
      {data?.map((item) => (
        <TasksQuickListItem
          extraButtons={extraButtons}
          key={item.id}
          item={item}
          onDelete={handleDeleteItem}
          requestQueryBuilder={requestQueryBuilder}
        />
      ))}

      {(!data || data.length === 0) && <Text>No hay tareas.</Text>}
    </div>
  );
};
