import {
  IsNotEmpty,
  IsNumber,
  IsOptional,
  MaxLength,
  MinLength,
} from 'class-validator';

export class TaskCreateDto {
  @MinLength(3)
  @MaxLength(255)
  @IsNotEmpty()
  
  title: string;

  
  description?: string;

  @IsNotEmpty()
  
  projectId: number;

  @IsNumber()
  
  @IsOptional()
  parentId?: number;
}
