import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import {Task} from "../../api";

interface ITaskAlertDialogCloseProps {
  isOpen: boolean;
  onClose: () => void;
  closeTask: () => void;
  item: Task;
}

export const TaskAlertDialogClose: React.FC<ITaskAlertDialogCloseProps> = ({
   isOpen,
   onClose,
   closeTask,
   item,
 }) => {
  const closeRef = React.useRef(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={closeRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Cerrar una tarea
          </AlertDialogHeader>

          <AlertDialogBody>
            ¿Deseas cerrar la tarea {item.title}? Si cierras la tarea, todas sus subtareas se cerrarán automáticamente.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={closeRef} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                closeTask();
                onClose();
              }}
              ml={3}
            >
              Si, cerrar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
