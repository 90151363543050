import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";

export interface BreadCrumbLink {
  to?: string;
  label: string;
}

export const BreadcrumbsHeader: React.FC = () => {
  const { breadCrumbLinks } = useAppContext();

  return (
    <Breadcrumb mr="auto">
      {breadCrumbLinks.map((item, key) => (
        <BreadcrumbItem key={key}>
          {item.to?.length ? (
            <BreadcrumbLink as={Link} to={item.to}>
              {item.label}
            </BreadcrumbLink>
          ) : (
            <Text key={key}>{item.label}</Text>
          )}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};
