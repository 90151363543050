import React, { useRef, useState } from "react";
import { EditableField } from "../EditableField";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent, AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay, Button,
  HStack,
  IconButton,
  Spinner, useDisclosure,
  useToast
} from "@chakra-ui/react";
import useAuth from "../../context/auth/auth";
import { useMutation } from "@tanstack/react-query";
import { ApiClient } from "common";
import { Upload } from "../../api";
import { AiFillDelete, AiOutlineDownload } from "react-icons/ai";
import constants from "../../constants";

interface IMultipleListItemProps {
  onFileDeleted: (id: number) => void;
  onFileUpdated: (data: Upload) => void;
  item: Upload;
}

export const MultipleListItem: React.FC<IMultipleListItemProps> = ({
  onFileUpdated,
  onFileDeleted,
  item,
}) => {
  const { user, access_token } = useAuth();
  const toast = useToast();
  const cancelDeleteRef = React.useRef(null);

  const {
    isOpen: isOpenDeleted,
    onOpen: onOpenDeleted,
    onClose: onCloseDeleted,
  } = useDisclosure();

  const deleteMutation = useMutation(
    (id: number) => ApiClient.delete(`uploads/${id}`),
    {
      onSuccess: async (data, variables) => {
        onFileDeleted(variables);
      },
      onMutate: () => console.log("on mutate"),
      onError: () => {
        toast({
          title: "Error accediendo al servidor",
          description:
            "Ha habido un error accediendo al servidor y los datos no se han guardado. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  const updateMutation = useMutation<
    Upload,
    unknown,
    { id: number; name: string }
  >(
    (vars) =>
      ApiClient.patch<Upload>(
        `uploads/${vars.id}`,
        JSON.stringify({ name: vars.name })
      ),
    {
      onSuccess: async () => {
        // optimistic update down here
        // onFileUpdated(data);
      },
      onMutate: (data) => {
        const newItem = { ...item, name: data.name };
        onFileUpdated(newItem);
      },
      onError: () => console.log("on error"),
    }
  );

  const onChangeName = (id: number) => {
    const currentItem = item;
    // si no ha cambiado el nombre, no ejecuto la mutación
    if (currentItem && currentItem.name !== name) {
      updateMutation.mutate({ id, name });
    }
  };

  const ref = useRef<HTMLInputElement>(null);
  const [name, setName] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);

  return (
    <HStack justifyContent="space-between">
      <EditableField childRef={ref} visibleValue={item.name}>
        <input
          type="text"
          name="name"
          ref={ref}
          value={name}
          placeholder="Agregar nombre del fichero"
          onFocus={() => setName(item.name)}
          onChange={(e) => {
            setName(e.target.value);
          }}
          onBlur={() => onChangeName(item.id)}
          style={{ width: "100%", paddingLeft: "5px" }}
        />
      </EditableField>

      <div>
        <IconButton
          variant="ghost"
          aria-label="Eliminar Gasto"
          icon={isDownloading ? <Spinner size="sm" /> : <AiOutlineDownload />}
          onClick={async () => {
            setIsDownloading(true);
            const ep = `${constants.apiUrl}uploads/${user?.tenantId}/${item.type}/${item.filename}`;
            let init: RequestInit = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${access_token}`,
              },
            };

            // https://stackoverflow.com/a/63946003
            const res = await fetch(ep, init);

            const blob = await res.blob();
            const newBlob = new Blob([blob]);

            const blobUrl = window.URL.createObjectURL(newBlob);

            const link = document.createElement("a");
            link.href = blobUrl;
            link.setAttribute("download", item.filename);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);

            window.URL.revokeObjectURL(blobUrl);
            setIsDownloading(false);
          }}
        />

        <IconButton
          variant="ghost"
          aria-label="Eliminar Fichero"
          icon={<AiFillDelete />}
          onClick={onOpenDeleted}
        />
      </div>

      <AlertDialog
        isOpen={isOpenDeleted}
        leastDestructiveRef={cancelDeleteRef}
        onClose={onCloseDeleted}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Borrar producto asignado
            </AlertDialogHeader>

            <AlertDialogBody>
              ¿Deseas eliminar el fichero {item.name}? Esta acción no se puede deshacer.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelDeleteRef} onClick={onCloseDeleted}>
                Cancelar
              </Button>
              <Button
                isLoading={deleteMutation.isLoading}
                colorScheme="red"
                onClick={() => deleteMutation.mutate(item.id)}
                ml={3}
              >
                Si, borrar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </HStack>
  );
};
