import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import {Task} from "../../api";

interface ITaskAlertDialogReopenProps {
  isOpen: boolean;
  onClose: () => void;
  reopenTask: () => void;
  item: Task;
}

export const TaskAlertDialogReopen: React.FC<ITaskAlertDialogReopenProps> = ({
   isOpen,
   onClose,
   reopenTask,
   item,
 }) => {
  const closeRef = React.useRef(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={closeRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Volver a abrir una tarea
          </AlertDialogHeader>

          <AlertDialogBody>
            ¿Deseas volver a abrir la tarea {item.title}?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={closeRef} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              colorScheme="green"
              onClick={() => {
                reopenTask();
                onClose();
              }}
              ml={3}
            >
              Si, abrir
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
