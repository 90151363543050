import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

export const ProductsWidgetHeader: React.FC = () => {
  const color = "gray.500";
  return (
    <Flex mb={1} borderBottomWidth="1px" borderBottomColor="gray.200">
      <Box textColor={color} width="35%">
        <Text fontSize="sm">Producto</Text>
      </Box>
      <Box textColor={color} width="15%">
        <Text fontSize="sm">Precio</Text>
      </Box>
      <Box textColor={color} width="15%">
        <Text fontSize="sm">Cantidad</Text>
      </Box>
      <Box textColor={color} width="15%">
        <Text fontSize="sm">Total</Text>
      </Box>
    </Flex>
  );
};
