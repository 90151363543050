import { useWorkingQuery } from "../../hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Task, Time } from "../../api";
import { TimeStartDto } from "../../dto/time-start.dto";
import { ApiClient } from "common";
import { useToast } from "@chakra-ui/react";
import constants from "../../constants";

// type IUseStartStopHook (
//     taskId: number,
//     workingOnAnotherTaskCallback: () => void
// ) => [
//     startStop: (recalled?: boolean) => void,
//     startMutation: UseMutationResult<Time, unknown, TimeStartDto, unknown>
// ]
/**
 *
 * @param taskId
 * @param workingOnAnotherTaskCallback
 * @param stopCurrentTimeAndStartNewOneCallback
 * @param onStartStopFinished
 */
export const useStartStopHook = (
  taskId: number,
  workingOnAnotherTaskCallback: () => void,
  stopCurrentTimeAndStartNewOneCallback: () => void,
  onStartStopFinished?: () => void
) => {
  const workingQuery = useWorkingQuery();
  const time = workingQuery.data?.time as Time;
  const isCurrentTask = time && taskId === time.taskId;
  const toast = useToast();
  const queryClient = useQueryClient();

  const startMutation = useMutation<Time, unknown, TimeStartDto>(
    (values) => ApiClient.post("times/start", JSON.stringify(values)),
    {
      onSuccess: async () => {
        await workingQuery.refetch();
        await queryClient.invalidateQueries(["times"]);
        toast({
          title: "Tiempo iniciado",
          description: `Has iniciado la tarea correctamente`,
          status: "success",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  const stopMutation = useMutation<Time>(
    () => ApiClient.post("times/stop", JSON.stringify({})),
    {
      onSuccess: async () => {
        await workingQuery.refetch();
        await queryClient.invalidateQueries(["times"]);
        toast({
          title: "Tiempo detenido",
          description: `Has detenido la tarea ${
            (workingQuery.data?.task as unknown as Task).title
          } correctamente`,
          status: "success",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  const startStop = (recalled = false): void => {
    /*
            A ver, si tenemos el refetch activo (que lo queremos), si estamos con la ventana sin foco
            y pinchamos sobre el botón, va a lanzar el foco, que lanza el refetch, y cuando el clic ejecute
            este handler, estará como refetching. Por eso hay un
             */
    if (workingQuery.isRefetching) {
      console.log(
        "workingQuery.isRefetching, no hago nada (TODO ver como dar feedback al usuario"
      );
      if (recalled) {
        console.log(
          "workingQuery.isRefetching RECALLED, no hago nada (TODO ver como dar feedback al usuario"
        );
        return;
      }
      setTimeout(() => startStop(true), 150);
    }

    if (!workingQuery.data)
      // @TODO
      console.error("No working data. TODO define what to do");
    //
    if (workingQuery.data?.working === true && !isCurrentTask) {
      workingOnAnotherTaskCallback();
      return;
    }

    // No niego porque si workingQuery. Data es undefined, tenemos lío
    if (workingQuery.data?.working === false) {
      console.log("start");
      startMutation.mutate(
        {
          taskId,
        },
        {
          onSuccess: onStartStopFinished,
        }
      );
    } else {
      stopMutation.mutate(undefined, { onSuccess: onStartStopFinished });
      console.log("stop");
    }
  };

  const stopCurrentTimeAndStartNewOne = async () => {
    stopMutation.mutate(undefined, {
      onSuccess: () =>
        startMutation.mutate(
          { taskId },
          {
            onSuccess: () => {
              stopCurrentTimeAndStartNewOneCallback();
              onStartStopFinished && onStartStopFinished();
            },
          }
        ),
    });
  };

  return {
    startStop,
    startMutation,
    stopMutation,
    isCurrentTask,
    workingQuery,
    stopCurrentTimeAndStartNewOne,
  };
};
