import React from "react";
import {Task} from "../../api";
import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {Link, useNavigate} from "react-router-dom";
import {FiMoreHorizontal} from "react-icons/fi";
import {FaEdit, FaTrash} from "react-icons/fa";
import {CgNotes} from "react-icons/cg";
import {IoMdCheckmark} from "react-icons/io";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {TaskUpdateDto} from "../../dto/task-update.dto";
import {ApiClient} from "common";
import constants from "../../constants";
import {RequestQueryBuilder} from "@nestjsx/crud-request";
import {TaskAlertDialogReopen} from './TaskAlertDialogReopen';
import {TaskAlertDialogClose} from './TaskAlertDialogClose';
import {TaskAlertDialogDelete} from './TaskAlertDialogDelete';

interface TasksQuickListItemProps {
  item: Task;
  onDelete: (item: Task) => void;
  extraButtons?: (item: Task) => JSX.Element;
  requestQueryBuilder: RequestQueryBuilder;
}

export const TasksQuickListItem: React.FC<TasksQuickListItemProps> = ({
  item,
  onDelete,
  extraButtons,
  requestQueryBuilder,
}) => {
  const navigate = useNavigate();

  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();

  const {
    isOpen: isOpenClose,
    onOpen: onOpenClose,
    onClose: onCloseClose,
  } = useDisclosure();

  const {
    isOpen: isOpenReopen,
    onOpen: onOpenReopen,
    onClose: onCloseReopen,
  } = useDisclosure();

  const toast = useToast();
  const queryClient = useQueryClient();

  const detailUrl = `/projects/${item.projectId}/task/${item.id}`;

  const mutation = useMutation<Task, unknown, TaskUpdateDto>(
    (d) => ApiClient.patch<Task>(`tasks/${item.id}`, JSON.stringify(d)),
    {
      onSuccess: async (data) => {
        console.log("refetch query");
        console.log(`infsc_tasks_project_${item.projectId}`);
        await queryClient.refetchQueries(["tasks_list", requestQueryBuilder]);

        toast({
          title: data.closed ? "Tarea cerrada" : "Tarea abierta",
          description: data.closed
            ? `La tarea ${data.title} se ha cerrado correctamente`
            : `La tarea ${data.title} se ha vuelto a abrir correctamente`,
          status: "success",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: "Error al recuperar los datos",
          description:
            "Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    }
  );

  const closeTask = () => {
    const task: TaskUpdateDto = { ...item, closed: true };
    mutation.mutate(task);
  };

  const reopenTask = () => {
    const task: TaskUpdateDto = { ...item, closed: false };
    mutation.mutate(task);
  };

  return (
    <Flex
      alignItems="center"
      borderLeft="1px"
      borderRight="1px"
      borderBottom="1px"
      borderColor="gray.300"
      p={1}
      _hover={{
        backgroundColor: "gray.50",
      }}
    >
      <Flex flexGrow={1} alignItems={"center"}>
        <Link to={detailUrl}>{item.title}</Link>

        {item.description && item.description.length > 0 && (
          <Box ml={2} position="relative">
            <Tooltip label={item.description}>
              <Flex>
                <CgNotes color="gray" />
              </Flex>
            </Tooltip>
          </Box>
        )}
      </Flex>

      <Box width={14}>
        <Menu placement="bottom-end">
          <MenuButton
            as={IconButton}
            aria-label="Ordenar"
            size="xs"
            variant="ghost"
            icon={<FiMoreHorizontal />}
          />
          <MenuList alignItems="flex-end">
            <MenuItem
              icon={<IoMdCheckmark />}
              onClick={() => (item.closed ? onOpenReopen() : onOpenClose())}
            >
              {item.closed ? "Volver a Abrir" : "Cerrar"}
            </MenuItem>
            <MenuItem icon={<FaEdit />} onClick={() => navigate(detailUrl)}>
              Modificar
            </MenuItem>
            <MenuItem icon={<FaTrash />} onClick={onOpenDelete}>
              Borrar
            </MenuItem>
            {extraButtons && extraButtons(item)}
          </MenuList>
        </Menu>
      </Box>

      <TaskAlertDialogReopen
        isOpen={isOpenReopen}
        onClose={onCloseReopen}
        reopenTask={reopenTask}
        item={item}
      />

      <TaskAlertDialogClose
        isOpen={isOpenClose}
        onClose={onCloseClose}
        closeTask={closeTask}
        item={item}
      />

      <TaskAlertDialogDelete
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        onDelete={onDelete}
        item={item}
      />
    </Flex>
  );
};
