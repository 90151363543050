import { matchPath, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Routes as R } from "../../routes";

/**
 * Este componente se usa para restarurar el scroll cuando se cambian las rutas.
 *
 * Hay casos, como listado y detalle en modal que no queremos que esto pase.
 * Es necesario añadir las rutas que tienen que ignorar el restaurado de scroll.
 * Cuando se cierra un modal, en el enlace hacia el listado se debe poner src=false en la url
 * @param children
 * @constructor
 */
const ScrollToTop: React.FC = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    let restoreScroll = true;
    if (matchPath("projects/:id/task/:taskId", location.pathname)) {
      console.log("no scroll restore projects/:id/task/:taskId");
      restoreScroll = false;
    }

    if (
      restoreScroll &&
      matchPath(`${R.USERS}/${R.OUTLET_VIEW}`, location.pathname)
    ) {
      console.log(`no scroll restore en ${R.USERS}/${R.OUTLET_VIEW}`);
      restoreScroll = false;
    }

    if (
      restoreScroll &&
      matchPath(`${R.CUSTOMERS}/${R.OUTLET_VIEW}`, location.pathname)
    ) {
      console.log(`no scroll restore en ${R.CUSTOMERS}/${R.OUTLET_VIEW}`);
      restoreScroll = false;
    }

    if (
      restoreScroll &&
      matchPath(`${R.PRODUCTS}/${R.OUTLET_VIEW}`, location.pathname)
    ) {
      console.log(`no scroll restore en ${R.PRODUCTS}/${R.OUTLET_VIEW}`);
      restoreScroll = false;
    }

    if (
      restoreScroll &&
      matchPath(`${R.TAGS}/${R.OUTLET_VIEW}`, location.pathname)
    ) {
      console.log(`no scroll restore en ${R.TAGS}/${R.OUTLET_VIEW}`);
      restoreScroll = false;
    }

    if (restoreScroll && !location.search.includes("sr=false")) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return <>{children}</>;
};

export default ScrollToTop;
