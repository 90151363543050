import React, { useEffect, useRef, useState } from 'react';
import { QuerySortOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { ApiClient } from 'common';
import { PaginatedResponse } from '../../services/interfaces/ApiResponse';
import { Task } from '../../api';
import { TasksListItem } from './TasksListItem';
import { SubTasksListItem } from './SubTasksListItem';
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { InputFilterDebounced } from '../Interface/InputFilterDebounced';
import { BiFilter } from 'react-icons/bi';
import LoadMoreButton from '../LoadMoreButton/LoadMoreButton';
import { Outlet, useNavigate } from 'react-router-dom';
import { SubTasksCreate } from './SubTasksCreate';
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SortableItem } from '../../pages/SortableItem';
import { MdOutlineDragHandle } from 'react-icons/md';
import { TaskOrderDto } from '../../dto/task-order.dto';
import { useStatePersist } from '../../hooks';
import constants from '../../constants';
import { TaskListActions } from './TaskListActions';
import { formatSeconds } from 'common/dist/util';
import { Item } from 'framer-motion/types/components/Reorder/Item';

interface TasksListProps {
  projectId: number;
  taskParent?: number;
}

export const TasksList: React.FC<TasksListProps> = ({
  projectId,
  taskParent,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const loadMoreRef = useRef(null);
  const [search, setSearch] = useStatePersist('TaskListSearch', '');
  const [order, setOrder] = useState<QuerySortOperator>('ASC');
  const [orderBy, setOrderBy] = useState('order');
  const [mostrarCerrados, setMostrarCerrados] = useState(false);
  const toast = useToast();

  const isSubtask = taskParent !== undefined;
  const ep = `tasks`;

  const queryKey = `infsc_tasks_project_${projectId}${
    isSubtask ? '_parent_' + taskParent : ''
  }`;
  const requestQueryBuilder = new RequestQueryBuilder();

  let additionalFilter: any = {};
  if (isSubtask) additionalFilter.parentId = taskParent;
  else additionalFilter.parentId = { $isnull: true };

  requestQueryBuilder
    .setLimit(isSubtask ? 65000 : 20)
    .setPage(1)
    .search({
      $and: [
        {
          projectId,
          closed: mostrarCerrados,
          ...additionalFilter,
          $or: [
            {
              title: {
                $cont: search,
              },
            },
            {
              description: {
                $cont: search,
              },
            },
          ],
        },
      ],
    });

  if (orderBy.length) {
    requestQueryBuilder.sortBy({
      field: orderBy,
      order,
    });
  }

  useEffect(() => {
    if (orderBy === 'order' && order === 'DESC') setOrder('ASC');
  }, [order, orderBy]);

  const {
    data,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<PaginatedResponse<Task[]>, Error>(
    [queryKey, search, order, orderBy, mostrarCerrados],
    async ({ pageParam = 1 }) => {
      requestQueryBuilder.setPage(pageParam);
      return await ApiClient.get<PaginatedResponse<Task[]>>(
        ep,
        requestQueryBuilder,
      );
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage.page < lastPage.pageCount
          ? lastPage.page + 1
          : undefined;
      },
      onError: () => {
        toast({
          title: 'Error al recuperar los datos',
          description:
            'Ha habido un error al recuperar los datos. Refresca la página en unos minutos y vuelve a intentarlo.',
          status: 'error',
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    },
  );

  const onCreate = (newItem: Task, bottom: boolean = false) => {
    if (isSubtask) {
      queryClient.setQueryData(
        ['tasks', newItem.parentId.toString()],
        (oldData) => {
          const newData = { ...(oldData as unknown as Task) };
          newData.subtasks = [...newData.subtasks, newData];
          return newData;
        },
      );
    }

    queryClient.setQueriesData(
      [queryKey, search, order, orderBy],
      (oldData: any) => {
        const newData = { ...oldData };

        newData.pages[0].data = bottom
          ? (data?.pages[0].data || []).concat(newItem)
          : [newItem].concat(data?.pages[0].data || []);
        return newData;
      },
    );
  };

  const onUpdate = (id: number, data: Task) => {
    queryClient.setQueriesData([queryKey], (oldData: any) => {
      const newPages = oldData.pages.map((group: any) => {
        return {
          ...group,
          data: group.data.map((item: any) => {
            if (item.id === id) return data;
            return item;
          }),
        };
      });

      return {
        pages: newPages,
        pageParams: oldData.pageParams,
      };
    });
  };

  const onDelete = (id: number) => {
    queryClient.setQueriesData(
      [queryKey, search, order, orderBy],
      (oldData: any) => {
        const newPages = oldData.pages.map((group: any) => {
          return {
            ...group,
            data: group.data.filter((item: Task) => item.id !== id),
          };
        });

        return {
          pages: newPages,
          pageParams: oldData.pageParams,
        };
      },
    );
  };

  const deleteMutation = useMutation(
    (id: number) => ApiClient.delete(`tasks/${id}`),
    {
      onSuccess: async (data, variables) => {
        onDelete(variables);
        // onDelete(variables as unknown as number);

        // onDelete(variables);
      },
      onError: () => {
        toast({
          title: 'Error accediendo al servidor',
          description:
            'Ha habido un error accediendo al servidor y los datos no se han guardado. Refresca la página en unos minutos y vuelve a intentarlo.',
          status: 'error',
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
    },
  );

  const handleDeleteItem = async (item: Task) => {
    deleteMutation.mutate(item.id);
  };

  const getItemFromCache = (id: number): Task | undefined => {
    const data = queryClient.getQueryData<
      InfiniteData<PaginatedResponse<Task[]>>
    >([queryKey, search, order, orderBy]);
    return data?.pages[0].data.find((item) => item.id === id);
  };

  function handleDragStart(event: any) {
    setCurrentDraggedItem(getItemFromCache(Number(event.active.id)));
  }

  const orderMutation = useMutation<unknown, unknown, TaskOrderDto>(
    (d) => ApiClient.post<unknown>(`tasks/order`, JSON.stringify(d)),
    {
      onError: (error, variables, context) => {
        console.log({ error, variables, context });
      },
      onSuccess: async (data) => {
        console.log(data);
      },
    },
  );

  function handleDragEnd(event: any) {
    const { active, over } = event;
    setCurrentDraggedItem(undefined);

    queryClient.setQueriesData([queryKey], (oldData: any) => {
      const items = oldData.pages[0].data;

      if (active.id !== over.id) {
        const oldIndex = items.findIndex(
          (item: Task) => item.id.toString() === active.id,
        );
        const newIndex = items.findIndex(
          (item: Task) => item.id.toString() === over.id,
        );

        return {
          pages: [
            { ...oldData.pages[0], data: arrayMove(items, oldIndex, newIndex) },
          ],
          pageParams: oldData.pageParams,
        };
      }
      return {
        pages: [{ ...oldData.pages[0], data: items }],
        pageParams: oldData.pageParams,
      };
    });

    orderMutation.mutate({
      sourceId: active.id,
      targetId: over.id,
    });
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const [currentDraggedItem, setCurrentDraggedItem] = useState<Task>();

  return (
    <div>
      {!isSubtask && (
        <HStack justifyContent="flex-end">
          <Button
            size="sm"
            variant="ghost"
            onClick={() => navigate('task-create')}
          >
            Crear tarea
          </Button>
        </HStack>
      )}

      <HStack>
        <InputFilterDebounced onChangeDebounced={(d) => setSearch(d)} />

        <Menu closeOnSelect={false}>
          <MenuButton
            as={IconButton}
            aria-label="Ordenar"
            variant="ghost"
            icon={<BiFilter />}
          />
          <MenuList minWidth="240px">
            <MenuOptionGroup
              onChange={(v) => setOrderBy(v.toString())}
              value={orderBy}
              title="Ordenar por"
              type="radio"
            >
              <MenuItemOption value="title">Título</MenuItemOption>
              {/*<MenuItemOption value='description'>Descripción</MenuItemOption>*/}
              {isSubtask && (
                <MenuItemOption value="order">Personalizado</MenuItemOption>
              )}
            </MenuOptionGroup>
            <MenuDivider />
            <MenuOptionGroup
              onChange={(v) => setOrder(v.toString() as QuerySortOperator)}
              value={order}
              title="Orden"
              type="radio"
            >
              <MenuItemOption
                isDisabled={orderBy === 'order'}
                command="sa"
                value="ASC"
              >
                Ascendente
              </MenuItemOption>
              <MenuItemOption
                isDisabled={orderBy === 'order'}
                command="sd"
                value="DESC"
              >
                Descendente
              </MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Menu>

        <Text>Mostrar: </Text>
        <CheckboxGroup colorScheme="green">
          <Stack spacing={[1, 5]} direction={['column', 'row']}>
            <Checkbox
              onChange={(e) => setMostrarCerrados(e.target.checked)}
              isChecked={mostrarCerrados}
            >
              Cerrados
            </Checkbox>
          </Stack>
        </CheckboxGroup>
      </HStack>

      {isError ? (
        <p>Ha habido un error cargando los datos</p>
      ) : isLoading ? (
        <p>cargando</p>
      ) : data?.pages[0].data.length === 0 ? (
        <Text marginTop={2} marginBottom={5}>
          No hay resultados.
        </Text>
      ) : (
        <>
          {isSubtask ? (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              onDragStart={handleDragStart}
              modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
            >
              <SortableContext
                items={
                  data?.pages[0].data.map((item) => ({
                    ...item,
                    id: item.id.toString(),
                  })) as unknown as { id: string }[]
                }
                strategy={verticalListSortingStrategy}
              >
                {data?.pages[0].data.map((item) => (
                  <SortableItem
                    key={item.id.toString()}
                    id={item.id.toString()}
                    isDragged={item.id === currentDraggedItem?.id}
                    canDrag={orderBy === 'order'}
                  >
                    <SubTasksListItem item={item} onDelete={handleDeleteItem} />
                  </SortableItem>
                ))}
              </SortableContext>

              <DragOverlay>
                {currentDraggedItem ? (
                  <Flex
                    _hover={{
                      backgroundColor: 'gray.50',
                      cursor: 'grab',
                    }}
                    borderLeft="1px"
                    borderRight="1px"
                    borderBottom="1px"
                    borderColor="gray.300"
                    p={1}
                    alignItems="center"
                    mb={1}
                  >
                    <Box mr={1}>
                      <MdOutlineDragHandle />
                    </Box>
                    <Box flexGrow={1}>
                      <SubTasksListItem
                        item={currentDraggedItem}
                        onDelete={handleDeleteItem}
                      />
                    </Box>
                  </Flex>
                ) : null}
              </DragOverlay>
            </DndContext>
          ) : (
            <>
              {data?.pages.map((group, i) => (
                <React.Fragment key={i}>
                  {group.data.map((item) => (
                    <TasksListItem
                      key={`item_${item.id}`}
                      item={item}
                      onDelete={handleDeleteItem}
                    />
                  ))}
                </React.Fragment>
              ))}

              <TaskListActions
                projectId={projectId}
                csvData={
                  data?.pages
                    .map((group) =>
                      group.data.map((item) => ({
                        titulo: item.title,
                        tiempo: formatSeconds(item.timeTotal),
                      })),
                    )
                    .flat() ?? []
                }
              />
            </>
          )}
        </>
      )}

      {!isSubtask && (
        <LoadMoreButton
          loadMoreRef={loadMoreRef}
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
        />
      )}

      {isSubtask && (
        <SubTasksCreate
          projectId={projectId}
          taskParent={taskParent}
          onCreate={onCreate}
        />
      )}

      <Outlet
        context={{
          onUpdate,
          onCreate,
        }}
      />
    </div>
  );
};
