import React from "react";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";

interface ITimesWidgetHeaderProps {}

export const TimesWidgetHeader: React.FC<ITimesWidgetHeaderProps> = () => {
  const color = "gray.500";
  return (
    <SimpleGrid
      columns={4}
      mb={1}
      borderBottomWidth="1px"
      borderBottomColor="gray.200"
      pr="74px"
    >
      <Box textColor={color}>
        <Text fontSize="sm">Inicio</Text>
      </Box>
      <Box textColor={color}>
        <Text fontSize="sm">Fin</Text>
      </Box>
      <Box textColor={color}>
        <Text fontSize="sm">Usuario</Text>
      </Box>
      <Box textColor={color}>
        <Text fontSize="sm">Total</Text>
      </Box>
    </SimpleGrid>
  );
};
