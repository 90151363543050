import React from "react";
import { createValidator } from "class-validator-formik";
import { CustomerCreateDto } from "../../dto/customer-create.dto";
import { Form, Formik } from "formik";
import { Button, Grid, GridItem, Stack } from "@chakra-ui/react";
import { CustomTextInput } from "../Forms";
import { CustomerUpdateDto } from "../../dto/customer-update.dto";

interface CustomerFormProps {
  initialValues: CustomerCreateDto | CustomerUpdateDto;
  isNewRecord: boolean;
  dto: any;
  onSubmit: (values: CustomerCreateDto | CustomerUpdateDto) => void;
  mutationLoading: boolean;
}

const CustomersForm: React.FC<CustomerFormProps> = ({
  initialValues,
  isNewRecord,
  dto,
  onSubmit,
  mutationLoading,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
    validate={createValidator(dto)}
  >
    {({ isValid }) => (
      <Form>
        <Stack spacing="6">
          <Grid templateColumns="1fr 1fr" gap={2}>
            <GridItem>
              <CustomTextInput
                label="Nombre"
                placeholder="John doe"
                name="name"
                autoComplete="off"
              />
            </GridItem>

            <GridItem>
              <CustomTextInput
                label="Dni/Nif/Cif"
                placeholder="Dni/Nif/Cif"
                name="document"
                autoComplete="off"
              />
            </GridItem>
          </Grid>

          <Grid templateColumns="2fr 1fr" gap={2}>
            <GridItem>
              <CustomTextInput
                label="Dirección"
                placeholder="su calle 23"
                name="address"
                autoComplete="off"
              />
            </GridItem>

            <GridItem>
              <CustomTextInput
                label="Código postal"
                placeholder="01234"
                name="postal_code"
                autoComplete="off"
              />
            </GridItem>
          </Grid>

          <Grid templateColumns="1fr 1fr 1fr" gap={2}>
            <GridItem>
              <CustomTextInput
                label="Localidad"
                placeholder="Localidad"
                name="city"
                autoComplete="off"
              />
            </GridItem>

            <GridItem>
              <CustomTextInput
                label="Provincia"
                placeholder="Provincia"
                name="state"
                autoComplete="off"
              />
            </GridItem>

            <GridItem>
              <CustomTextInput
                label="País"
                placeholder="País"
                name="country"
                autoComplete="off"
              />
            </GridItem>
          </Grid>

          <Grid templateColumns="1fr 1fr" gap={2}>
            <GridItem>
              <CustomTextInput
                label="Teléfono 1"
                placeholder="666 777 888"
                name="phone1"
                autoComplete="off"
                type="tel"
              />
            </GridItem>

            <GridItem>
              <CustomTextInput
                label="Teléfono 2"
                placeholder="666 777 888"
                name="phone2"
                autoComplete="off"
                type="tel"
              />
            </GridItem>
          </Grid>

          <Button
            colorScheme="blue"
            size="lg"
            fontSize="md"
            type="submit"
            mt={4}
            disabled={!isValid || mutationLoading}
          >
            {mutationLoading ? "Espere" : isNewRecord ? "Crear" : "Guardar"}
          </Button>
        </Stack>
      </Form>
    )}
  </Formik>
);

export default CustomersForm;
