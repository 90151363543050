import React from "react";
import { ApiClient } from "common";
import { UserEntity } from "../../api";
import { createValidator } from "class-validator-formik";
import { UsersUpdateDto } from "../../dto/users-update.dto";
import { Form, Formik } from "formik";
import { Button, Stack, useToast } from "@chakra-ui/react";
import { CustomSelect, CustomTextInput } from "../Forms";
import { enunToValuesTranslatedToSelect } from "../../i18n";
import { RolUser } from "../../dto/types/rol.type";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import constants from "../../constants";

interface IUsersUpdateFormProps {
  user: UserEntity;
}

export const UsersUpdateForm: React.FC<IUsersUpdateFormProps> = ({ user }) => {
  const updateUrl = `users/${user.id}`;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const toast = useToast();

  const mutation = useMutation<UserEntity, unknown, UsersUpdateDto>(
    (d) => ApiClient.patch<UserEntity>(updateUrl, JSON.stringify(d)),
    {
      onError: () => {
        toast({
          title: "Error accediendo al servidor",
          description:
            "Ha habido un error accediendo al servidor y los datos no se han guardado. Refresca la página en unos minutos y vuelve a intentarlo.",
          status: "error",
          duration: constants.toastDuration,
          isClosable: true,
        });
      },
      onSuccess: async (data) => {
        toast({
          title: "Correcto",
          description: "El usuario ha sido modificado correctamente.",
          status: "success",
          duration: constants.toastDuration,
          isClosable: true,
        });

        queryClient.refetchQueries(["infsc_users"]);
        navigate("/users");
        queryClient.invalidateQueries(["users", data.id]);
      },
    }
  );

  return (
    <Formik
      initialValues={user}
      onSubmit={async (values) => {
        try {
          console.log(values);
          mutation.mutate(values);

          // onSubmit(values as unknown as typeof dto)
        } catch (error) {
          // @todo notificar al usuario y ver que hacer.
          console.error(error);
        }
      }}
      validate={createValidator(UsersUpdateDto)}
    >
      {({ isValid, dirty, touched }) => (
        <Form>
          <Stack spacing="6">
            <CustomTextInput
              label="Nombre"
              placeholder="Nombre del usuario"
              name="name"
              autoComplete="off"
            />

            <CustomTextInput
              label="DNI"
              placeholder="Dni del usuario"
              name="document"
              autoComplete="off"
            />

            <CustomTextInput
              label="Email del usuario"
              placeholder="direccion@email.com"
              name="email"
              autoComplete="off"
            />

            <CustomSelect
              options={enunToValuesTranslatedToSelect(RolUser, "es")}
              label="Rol"
              name="rol"
            />

            <Button
              colorScheme="blue"
              size="lg"
              fontSize="md"
              type="submit"
              disabled={
                !(isValid && (dirty || Object.keys(touched).length)) ||
                mutation.isLoading
              }
            >
              {mutation.isLoading ? "Espere" : "Modificar"}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
